<div class="modal-body">
  <form
    name="addPostTimeForm"
    (ngSubmit)="addPostTimeForm.form.valid && selectPostTime()"
    #addPostTimeForm="ngForm"
  >
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label for="datepicker">Date</label>
          <ngb-datepicker
            id="datepicker"
            [(ngModel)]="modalinputdata.date"
            [startDate]="{year: currentYear, month: currentMonth}"
          ></ngb-datepicker>

          <div *ngIf="addPostTimeForm.submitted">
            <div
              *ngIf="addPostTimeForm.controls?.['date']?.['errors']?.['required']"
              class="text text-danger mt-1"
            >
              Please select a date
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="form-group">
          <!-- <label for="timepicker">Time</label> -->
          <div class="row">

            <div class="col-sm-4">
              <label for="hours">Hours</label>
              <select
                id="hours"
                class="form-control"
                (change)="onHourChange($event)"
                name="selectedHour"
                required
              >
                <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
              </select>
            </div>                     

            <div class="col-sm-4">
              <label for="minutes">Minutes</label>
              <select
                id="minutes"
                class="form-control"
                (change)="onMinuteChange($event)"
                name="selectedMinute"
                required
              > 
                <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
              </select>
            </div>
            
            <div class="col-sm-4">
              <label for="minutes">AM/PM</label>
              <select
              id="ampm"
              class="form-control"
              (change)="onRangeChange($event)"
              name="selectedrange"
              required
            >
            
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>

          </div>

          <div *ngIf="addPostTimeForm.submitted">
            <div
              *ngIf="addPostTimeForm.controls?.['time']?.['errors']?.['required']"
              class="text text-danger mt-1"
            >
              Please select a time
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: 50px;">
      <div class="col-sm-12">
        <button type="submit" class="btn btn-primary">Save</button>
        &nbsp;&nbsp;&nbsp;
        <button
        type="button"
        class="btn btn-outline-dark"
        (click)="activeModal.dismiss('Close click')"
        >
        Close
        </button>
      </div>

    </div>

    <app-spinner></app-spinner>
  </form>
</div>
