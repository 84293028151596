import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';
import { HttpService } from 'src/app/services/http.service';
import { Subscription } from 'rxjs';
import { Whitelabel } from 'src/app/interfaces/Whitelabel';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  subscriptions: any = [];
  supportEmail: string = "";
  whiteLabel = {} as Whitelabel;
  displayFooter: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document, private commonService: CommonService, private router: Router,private httpService: HttpService,private spinnerService: SpinnerService,) {

    this.subscriptions.push(this.commonService.subject.sessionData.subscribe(data => {
      this.whiteLabel = data
      console.log(data);
    }))
  }
  
  environment = environment;
  search: string = "";
  config = { itemsPerPage: 5, currentPage: 1, totalItems: 0 };
  domains: any = {};

  ngOnInit(): void {
    const docLocation = this.document.location;
    const { pathname } = docLocation;
    // if (!pathname.includes("auth/popup")) {
    //   this.displayFooter = true;
    // }
    if(this.router.url === '/auth/popup'){
    } else {
      this.displayFooter = true;
    }
  }

  isLoginPage(): boolean {
    return this.router.url === '/auth/login';
  }

  // ngOnDestroy(): void {
  //   this.subscriptions.map((subscription: Subscription) => subscription.unsubscribe())
  // }

  // destroy() {
  //   this.destroy()
  // }

  checkDomain(event: MouseEvent): void {
    event.preventDefault(); // Prevent the default anchor behavior
    this.spinnerService.showSpinner();
    const request = {
      page: this.config.currentPage,
      limit: this.config.itemsPerPage,
      search: this.search
    };

    this.httpService.getRequestWithParameters("domain/domains", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status === 200) {
          this.domains = response.data.data;
          this.config.totalItems = response.data.total;

          // Check if domains array is empty and navigate accordingly
          if (this.domains.length === 0) {
            this.router.navigate(["/app/domain/buy-blog-domain"]);
          } else {
            window.open(this.router.serializeUrl(this.router.createUrlTree(["/blog"])), '_blank');
          }
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      });
  }

}
