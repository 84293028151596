import { Component, OnInit } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { HttpService } from 'src/app/services/http.service';
import { CommonService } from 'src/app/services/common.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  notification: any = null;
  localData: any = {};

  constructor(private httpService: HttpService, private commonService: CommonService, private spinnerService: SpinnerService) { }

  showSubmenu: boolean = false;
  notificationPopup = { isVisible: false };

  ngOnInit(): void { }

  ngAfterContentInit(): void { // A callback method that is invoked immediately after Angular has completed initialization of all of the directive's content.

    this.localData = this.getLocalData();

    this.commonService.userInfo.subscribe((data: any) => {
      this.localData = data.localData
      this.commonService.tab.emit({
        activeTab: "dashboard", subActiveTab: ""
      })
    })
  }

  getLocalData = () => {

    let userData = localStorage.getItem("user");
    if (userData != undefined && userData != null) {
      var localData = JSON.parse(userData).data

      if (localData) {
        this.notification = localData.notification
      }
    }

    return localData;
  }

 toggleSubmenu(event: Event): void {
    event.stopPropagation(); // Prevent event propagation to parent elements
    this.showSubmenu = !this.showSubmenu;
  }

  subscribeToEmitter = (componentRef: any) => {
    if (componentRef instanceof LoginComponent) {
      const childComp: LoginComponent = componentRef;
      childComp.notification.subscribe((data: any) => {
        this.notification = data.notification
      })
    }
  }

  routerLink = (type: string) => {

    let routeArr = [
      {
        key: "profile",
        values: {
          2: "/app/user/profile",
          3: "/app/company/profile",
        },
      },
      {
        key: "credits",
        values: {
          2: "/app/user/credits",
          3: "/app/company/credits",
        },
      },
      {
        key: "domains",
        values: "/app/domain/domains",
      },
      {
        key: "numbers",
        values: "/app/number/numbers",
      },
      {
        key: "billing",
        values: "/app/user/billing",
      },
      {
        key: "plans",
        values: "/app/admin/plans",
      },
      {
        key: "all-users",
        values: "/app/admin/all-users",
      },
    ];

    let route: any = routeArr.find((route) => {
      return route.key == type
    });

    this.commonService.tab.emit({
      activeTab: type,
      subActiveTab: type
    })
    return (typeof route.values == "string") ? route.values : route.values[this.localData.role]
  }

  hideNavBar(event: any) {
    if (event.target.className !== "fa") {
      this.showSubmenu = false;
    }
    if (event.target.className !== "ng-star-inserted") {
      this.notificationPopup.isVisible = false;
    }
  }

  /**
   * signout admin from user account
   */
  signOutAdmin = () => {
    this.spinnerService.showSpinner();
    const request = { admin_id: this.localData.admin_id };
    this.httpService.postRequest(request, "user/signoutuser").subscribe((res) => {
      if (res.status == 200) {
        this.localData = res.data;
        localStorage.removeItem("user");
        let user_data = JSON.stringify({
          data: res.data,
        });
        localStorage.setItem("user", user_data);
        this.commonService.tab.emit({
          activeTab: "dashboard", subActiveTab: ""
        })
        this.commonService.navigateTo("dashboard");
      }
    }, (error) => {
      this.spinnerService.hideSpinner();
      this.spinnerService.errorSwal(error.error);
    })
  }

}
