import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { HttpService } from 'src/app/services/http.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {

  constructor(private commonService: CommonService,private spinnerService: SpinnerService,private httpService: HttpService) { }

  public showClients: boolean = false;
  public showBusiness: boolean = false;
  public showTemplate: boolean = false;
  public showPosts: boolean = false;
  public showMedia: boolean = false;
  public showContentEngine: boolean = false;
  public showBlog: boolean = false;
  public showMypackages: boolean = false;
  public showPackagePost: boolean = false;
  public showSubMenu: boolean = false;
  public activeTab: string = "dashboard";
  public subActiveTab: string = ""
  public localData: any = {};

  search: string = "";
  config = { itemsPerPage: 5, currentPage: 1, totalItems: 0 };
  domains: any = {}

  ngOnInit(): void {

    const { active, subActive } = JSON.parse(localStorage.getItem("tab") || '{"tab":{"active":"dashboard","subActive":""}}')
    this.activeTab = active
    this.subActiveTab = subActive
    this.setActiveTab('dashboard');
    this.getDomains()
  }

  ngAfterViewInit(): void {
    this.commonService.tab.subscribe((data: any) => {
      const { activeTab, subActiveTab } = data
      this.setActiveTab(activeTab, subActiveTab)
      this.localData = this.getLocalData();
    })
  }

  ngAfterContentInit(): void {
    this.localData = this.getLocalData();
  }

  getLocalData = () => {
    let userData = localStorage.getItem("user");
    if (userData != undefined && userData != null) {
      var localData = JSON.parse(userData).data
    }
    return localData;
  }

  setActiveTab = (tabActive: string, subActiveTab: string = "") => {

    localStorage.setItem("tab", JSON.stringify({ active: tabActive, subActive: subActiveTab }))

    this.activeTab = tabActive
    this.subActiveTab = subActiveTab
    this.showSubMenu = true
  }

  toggleMenu(menu: string): void {
    if (this.activeTab === menu) {
      // Close the menu if it's already open
      this.activeTab = '';
      // Close all submenus
      this.closeAllSubmenus();
    } else {
      // Open the clicked menu and close others
      this.activeTab = menu;
      this.showBusiness = menu === 'business';
      this.showPosts = menu === 'post';
      this.showContentEngine = menu === 'contentengine';
      this.showMypackages = menu === 'showMypackages';
      this.showMedia = menu === 'media';
      this.showBlog = menu === 'blog';
      this.showTemplate = menu === 'templates';
      // Close all other submenus
      this.closeAllSubmenusExcept(menu);
    }
  }
  
  closeAllSubmenusExcept(menu: string): void {
    if (menu !== 'business') {
      this.showBusiness = false;
    }
    if (menu !== 'post') {
      this.showPosts = false;
    }
    if (menu !== 'package') {
      this.showContentEngine = false;
    }
    if (menu !== 'mypackage') {
      this.showMypackages = false;
    }
    if (menu !== 'media') {
      this.showMedia = false;
    }
    if (menu !== 'blog') {
      this.showBlog = false;
    }
    if (menu !== 'templates') {
      this.showTemplate = false;
    }
  }
  
  closeAllSubmenus(): void {
    this.showBusiness = false;
    this.showPosts = false;
    this.showMedia = false;
    this.showBlog = false;
    this.showContentEngine = false
    this.showTemplate = false;
    this.showMypackages = false;
    this.showPackagePost = false;
  }
  

  routerLink = (type: string) => {
    let routeArr = [
      {
        key: "dashboard",
        values: {
          1: "/app/admin/dashboard",
          2: "/app/user/dashboard",
          3: "/app/company/dashboard",
        },
      },
      {
        key: "companies",
        values: {
          1: "/app/user/companies",
        },
      },
      {
        key: "post",
        values: {
          1: "/app/post/posts",
          2: "/app/post/posts"
        },
      },
      {
        key: "add-post",
        values: {
          1: "/app/post/add-post",
          2: "/app/post/add-post"
        },
      },
      {
        key: "add-multipost",
        values: {
          1: "/app/post/add-multipost",
          2: "/app/post/add-multipost"
        },
      },
      {
        key: "library",
        values: {
          1: "/app/media/library",
          2: "/app/media/library",
        },
      },
      {
        key: "add-media",
        values: {
          1: "/app/media/add-media",
          2: "/app/media/add-media",
        },
      },
      {
        key: "post-packages",
        values: {
          1: "/app/contentengine/post-packages",
          2: "/app/contentengine/post-packages",
        },
      },
      {
        key: "post-package-items",
        values: {
          1: "/app/contentengine/post-package-items",
          2: "/app/contentengine/post-package-items",
        },
      },
      {
        key: "my-packages",
        values: {
          1: "/app/mypackage/my-packages",
          2: "/app/mypackage/my-packages",
        },
      },
      {
        key: "all-blogs",
        values: {
          1: "/app/blog/all-blogs",
          2: "/app/blog/all-blogs",
        },
      },
      {
        key: "add-new-blog-post",
        values: {
          1: "/app/blog/add-new-blog-post",
          2: "/app/blog/add-new-blog-post",
        },
      },
      {
        key: "all-blogs-category",
        values: {
          1: "/app/blog/all-blogs-category",
          2: "/app/blog/all-blogs-category",
        },
      },
      {
        key: "all-blogs-domain",
        values: {
          1: "/app/domain/domains",
          2: "/app/domain/domains",
        },
      },
      {
        key: "add-blog-category",
        values: {
          1: "/app/blog/add-blog-category",
          2: "/app/blog/add-blog-category",
        },
      },
      {
        key: "view_templates",
        values: {
          1: "/app/admin/templates",
          // 2: "/app/review/templates",
          // 3: "/app/review/templates",
        },
      },
      {
        key: "add_template",
        values: {
          1: "/app/admin/add-template",
          // 2: "/app/review/add-template",
          // 3: "/app/review/add-template",
        },
      },
      {
        key: "blog-settings",
        values: {
          1: "/app/blog/blog-settings",
          2: "/app/blog/blog-settings",
        },
      },
    ];

    let route: any = routeArr.find((route) => {
      return route.key == type
    });

    return route.values[this.localData.role];
  }

  logout = () => {
    localStorage.clear();
    this.commonService.navigateTo("login")
  }

  getDomains = () => {
    this.spinnerService.showSpinner()
    const request = { page: this.config.currentPage, limit: this.config.itemsPerPage, search: this.search };
    this.httpService.getRequestWithParameters("domain/domains", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner()
        if (response.status == 200) {
          this.domains = response.data.data
          this.config.totalItems = response.data.total
        }
      }, (error) => {
        this.spinnerService.hideSpinner()
        this.spinnerService.errorSwal(error.error)
      })
  }


}
