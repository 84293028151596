import { EventEmitter, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { LocalData } from '../interfaces/Localdata';
import { SessionData } from '../interfaces/SessionData';
import { HttpService } from 'src/app/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  subject = {
    localData: new ReplaySubject<LocalData>(),
    sessionData: new ReplaySubject<SessionData>(),
  }
  localData = {} as LocalData;
  sessionData = {} as SessionData;

  tab = new EventEmitter();
  userInfo = new EventEmitter();

  eventObj = {
    verifyMobile: new EventEmitter(),
    userInfo: new EventEmitter(),
    steps_completed: new EventEmitter()
  }
  constructor(private router: Router, private httpService: HttpService) { 
    
    this.subject.localData.subscribe(data => {
      this.localData = data;
      localStorage.setItem("user", JSON.stringify({
        data,
      }))
      let userData = localStorage.getItem("user");
      if (userData != undefined && userData != null) {
        this.localData = JSON.parse(userData).data
      }
    })

    this.subject.sessionData.subscribe(data => {
      this.sessionData = data;
      sessionStorage.setItem("whitelabel", JSON.stringify({
        data,
      }))
    })

  }

  navigateTo = (route: string, withDelay: boolean = false, timeOut: number = 1500) => {

    let localData = { role: 2 };

    let userData = localStorage.getItem("user");
    if (userData != undefined && userData != null) {
      localData = JSON.parse(userData).data
    }

    let url: string = "";

    switch (route) {

      case "login":
        url = '/auth/login';
        break;

      case "user_dashboard":
        url = '/app/user/dashboard';
        break;

      case "dashboard":
        url = localData.role == 1 ? '/app/admin/dashboard' : localData.role == 2 ? '/app/user/dashboard' : '/app/company/dashboard';
        break;  

      case "company_dashboard":
        url = '/app/company/dashboard';
        break;

      case "admin_dashboard":
        url = '/app/admin/dashboard';
        break;

      case "view_templates":
        url = '/app/review/templates';
        break;

      case "view_companies":
        url = '/app/company/companies';
        break;

      case "view_business":
        url = '/app/business/businesses';
        break;

      case "view_numbers":
        url = '/app/number/numbers';
        break;

      case "view_product":
        url = '/app/business/products';
        break;

      case "view_customers":
        url = '/app/client/clients';
        break;

      case "view_lists":
        url = '/app/list/lists';
        break;

      case "view_plans":
        url = '/app/admin/plans';
        break;

      case "review":
        url = '/app/review/reviews/0';
        break;

      case "view_library":
        url = '/app/media/library';
        break;
      case "domains":
        url = '/app/domain/domains';
        break;
      case "billing":
        url = '/app/user/billing'
        break;  
      case "profile":
        url = '/app/user/profile'
        break;     
    }

    if (withDelay) {
      setTimeout(() => {
        this.router.navigateByUrl(url);
      }, timeOut);
    } else {
      this.router.navigateByUrl(url);
    }
  }

  getToken() {
    if (localStorage.getItem('token')) {
      let tok = localStorage.getItem('token');
      return tok;
    } else {
      return 0;
    }
  }

  formatPhone(countryCode: string, number: number) {

    switch (countryCode) {
      case "+1":
      default:
        return `(${number.toString().slice(0, 3)}) ${number.toString().slice(3, 6)}-${number.toString().slice(6, 10)}`
        break;

      case "+44":
        return `${number.toString().slice(0, 3)} ${number.toString().slice(3, 6)} ${number.toString().slice(6, 10)}`
        break;

      case "+61":
        return `${number.toString().slice(0, 2)} ${number.toString().slice(2, 6)} ${number.toString().slice(6, 10)}`
        break;

    }
  }


  generateRandomString(length: number) {
    const characters = '@#$&*()!abcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  checkFormValid = (form: FormGroup) => {

    if (form.invalid) {
      const invalidControl = document.querySelector('.ng-invalid');
      if (invalidControl) {
        invalidControl.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }

      return false;
    }
    return true;
  }

  compileTokens = async (message: string, tokens: any, tokenValues: any) => {

    tokens.map((token: any) => {
      message = message.split(token.name).join(tokenValues[token.value])
    })

    return message;

  }


  private selectedItems: any[] = [];
  getSelectedItems(): any[] {
    return this.selectedItems;
  }

  addItem(item: any): void {
    this.selectedItems.push(item);
  }

  removeItem(item: any): void {
    const index = this.selectedItems.findIndex(i => i.id === item.id);
    if (index !== -1) {
      this.selectedItems.splice(index, 1);
    }
  }

  resetSelectedItems(): void {
    this.selectedItems = [];
    console.log('selected business cleared');
  }

  checkavailablecredits(){
    console.log('check credits');
  }

  creditinfo = {
    available: 0,
    message: "No Credits Available. Please add credits to your account"
  };

  getCreditsInfo = async () => {
    
    try {
      const res: any = await this.httpService.getRequest("credit/credits").toPromise();
      if (res.status === 200) {
        //const isDifferenceValid = res.credit_info.current - res.credit_info.total_used >= 1;
        const isDifferenceValid = res.credit_info.current >= 1;
        if (isDifferenceValid) {
          this.creditinfo.available = 1;
          return this.creditinfo;
        } else {
          this.creditinfo.available = 0;
          return this.creditinfo;
        }
      } else {
        return this.creditinfo;
      }
    } catch (error) {
      this.creditinfo.available = 0;
      this.creditinfo.message = "Problem in fetching the credits Information.";
      return this.creditinfo;
    }

  }

  
}
