<div class="_white_bx">
    <div class="row">
        <div class="_white_bx col-md-6" style="border-top-right-radius:0;border-bottom-right-radius:0;">
            <h5>Current SMS Credits</h5>
            <h5>{{ credits.current }}</h5>
        </div>
        <div class="_white_bx col-md-6" style="border-top-left-radius:0;border-bottom-left-radius:0;border-left: 0;">
            <h5>Credit Used</h5>
            <h5>{{ credits.used }}</h5>
        </div>
        <div class="col-md-12 mt15 text-center" *ngIf="localData.role !=3 "> <!-- if not a company user -->
            <a (click)="openAddCreditModal()" mat-raised-button class="btn btn-primary">Buy Credits</a>
        </div>
    </div>
</div>