import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-merge-fields',
  templateUrl: './merge-fields.component.html',
  styleUrls: ['./merge-fields.component.css']
})
export class MergeFieldsComponent implements AfterViewInit {

  tokens = null;
  @Input() type: string = "review";
  @Output() token = new EventEmitter<object>();

  constructor(private httpService: HttpService, private spinnerService: SpinnerService) { }

  ngAfterViewInit(): void {
    if (this.tokens == null) {
      this.getTokens();
    }
  }

  getTokens = () => {
    this.spinnerService.showSpinner();
    this.httpService.getRequest("user/tokens")
      .subscribe((res: any) => {
        this.spinnerService.hideSpinner();
        if (res.status == 200) {
          this.tokens = res.data.filter((data: any) => {
            return data.type.includes(this.type)
          })
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }

  mergeToken = (token: string) => {
    this.token.emit({ token })
  }
}
