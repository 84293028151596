<div class="modal-header">
    <h4 class="modal-title">Encode/Decode strings</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      name="encodeDecodeForm"
      (ngSubmit)="encodeDecodeForm.form.valid && encode()"
      #encodeDecodeForm="ngForm"
    >
      <div class="form-group">
        <label for="data">Post Content</label>
        <input
          type="text"
          class="form-control"
          id="data"
          name="data"
          #data="ngModel"
          [(ngModel)]="encode_data.data"
          required
        />
  
        <p>Response:</p>
        <p>{{encode_data.response_string}}</p>
      </div>
  
      <button type="submit" class="btn btn-primary">Encode</button
      >&nbsp;&nbsp;&nbsp;
  
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="activeModal.close('Close click')"
      >
        Close
      </button>
      <app-spinner></app-spinner>
    </form>

    <form
      name="encodeDecodeForm"
      (ngSubmit)="encodeDecodeForm.form.valid && Decode()"
      #encodeDecodeForm="ngForm"
    >
      <div class="form-group">
        <label for="data">Post Content</label>
        <input
          type="text"
          class="form-control"
          id="data"
          name="data"
          #data="ngModel"
          [(ngModel)]="deode_data.data"
          required
        />
  
        <p>Response:</p>
        <p>{{deode_data.response_string}}</p>
      </div>
  
      <button type="submit" class="btn btn-primary">Decode</button
      >&nbsp;&nbsp;&nbsp;
  
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="activeModal.close('Close click')"
      >
        Close
      </button>
      <app-spinner></app-spinner>
    </form>
  </div>
  
