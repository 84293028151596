<h1>Privacy Policy</h1>

<p>The privacy of our visitors is of extreme importance to us. This privacy policy document is designed to advise you about how we collect, use, and protect the Personally Identifiable Information (hereinafter defined) that you provide to us through our website. By visiting this website, you are accepting the practices described in this Privacy Policy.</p>

<p>
<b>1.) INFORMATION WE GATHER AND TRACK</b>
</p>

<p>
<b>(a) Log Files</b><br>
Like many other Web sites, our site makes use of log files. The information inside the log files includes internet protocol ( IP ) addresses, type of browser, Internet Service Provider ( ISP ), date/time stamp, referring/exit pages, and number of clicks to analyze trends, administer the site, track user’s movement around the site, and gather demographic information. IP addresses, and other such information are not linked to any information that is personally identifiable.
</p>

<p>
<b>(b) Cookies and Web Beacons</b><br>
We do use cookies to store information about visitors preferences, record user-specific information on which pages the user access or visit, customize Web page content based on visitors browser type or other information that the visitor sends via their browser.
</p>

<p>
<b>(c) ADVERTISING PARTNERS – Visitor Information and Cookies:</b><br>
This website may accept forms of cash advertising, sponsorship, paid insertions or other forms of compensation.<br>
The compensation received may influence the advertising content, topics or posts made in this website. That content, advertising space or post may not always be identified as paid or sponsored content.<br>
The owner(s) of this website may be compensated to provide opinions on products, services, websites and various other topics. Even though the owner(s) of this website receives compensation for our posts or advertisements, we always give our honest opinions, findings, beliefs, or experiences on those topics or products. The views and opinions expressed on this website are purely the author. Any product claim, statistic, quote or other representation about a product or service should be verified with the manufacturer, provider or party in question.

<p>
<b>2.) USE OF INFORMATION</b><br>
Any of the information we collect from you may be used in, but not limited to, the following ways:
</p>

<p>
- To personalize your experience<br>
- To improve our website
</p>

<p>
<b>(a) Identification of Purchasers</b><br>
If you purchase one of our products or services, you authorize us to use your name and identification information in advertising or promotions.<br>
We may use personal information in an aggregate form (i.e., not individually attributable to you) for business analysis, operational, marketing and other promotional purposes.<br>
You are also agreeing to receive information about the product or service you purchased. This may include, but is not limited to, information about product or service updates, new features, or information we believe you may find interesting.
</p>

<p><b>3.) HOW WE PROTECT YOUR INFORMATION</b><br>
We implement a variety of security measures to maintain the safety of your personal information. We will not sell, provide, or transfer you email address to others.
</p>

<p>
<b>4.) CHILDREN'S PRIVACY PROTECTION</b><br>
This website does not provide services or sell products to children under the age of 18.<br>
If we discover we have received any information from a child under the age of 18 in violation of this policy, we will delete that information immediately. If you believe we have received any information from or about anyone under the age of 18, please contact us at the address listed below.
</p>

<p>
<b>5.) THIRD PARTY LINKS</b><br>
Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
</p>

<p>
<b>6.) POLICY CHANGES</b><br>
These policies may be amended by us at any time and without notice, but will be posted at this page. You agree that your continued use of our websites, product or service after that date will constitute your consent and acceptance of the amendment.
</p>

<p>
<b>7.) CONTACT INFORMATION</b><br>
If there are any questions regarding this privacy policy you may contact us via the best email address and/or phone number that you can find on our contact page.
</p>