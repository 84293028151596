import { Component, EventEmitter, OnInit, AfterContentInit } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-steps-completed',
  templateUrl: './steps-completed.component.html',
  styleUrls: ['./steps-completed.component.css']
})
export class StepsCompletedComponent implements OnInit, AfterContentInit {

  localData: any = {
    has_contact: 0,
    has_business: 0,
    has_twilio: 0,
    has_number: 0,
    has_review: 0,
    has_socialpateforms: 0,
    has_posts: 0,
  };
  showStep: boolean = false;

  submenuActive: string = "";
  activeIcon: string = ''; // Keeps track of the active icon ('number' or '')
  businessNumber: number = 1;
  activeStep: string = ''; // Initialize it to an empty string
  
  steps_completed: EventEmitter<any> = new EventEmitter();

  constructor(private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.spinnerService.steps_completed.subscribe(data => {
      if (data) {
        this.updateLocalStorage(data);
      }
    });
    this.checkLocalStorage(); // Call checkLocalStorage to set activeStep initially
  }

  ngAfterContentInit(): void {
    this.checkLocalStorage();
  }

  checkLocalStorage = () => {
    let userData = localStorage.getItem("user");
    // console.log("userData");
    // console.log(userData);
    if (userData !== undefined && userData !== null) {
      const data = JSON.parse(userData).data;
      let { role } = data;
      if (role === 0 || role === 1 || role === 2) { // Include admin role (assuming admin role is 0)
        this.localData = data.modules_completed;
        let { has_business, has_contact, has_number, has_review, has_socialpateforms, has_posts } = this.localData;
        this.showStep = has_business !== 0 && has_contact !== 0 && has_number !== 0 && has_review !== 0 && has_socialpateforms !== 0 && has_posts !== 0 ? false : true;
      }
    }
  }

  updateLocalStorage = (data: any) => {
    let userData = localStorage.getItem("user");
    if (userData !== undefined && userData !== null) {
      let storageData = JSON.parse(userData).data;

      let keys = Object.keys(data);

      keys.forEach((key) => {
        storageData.modules_completed[key] = data[key];
      });

      this.localData = storageData.modules_completed;

      localStorage.setItem("user", JSON.stringify({
        data: storageData,
      }));

      this.checkLocalStorage();
    }
  }

  currentRouterLink: string = ''; // Define currentRouterLink property

  setCurrentRouterLink(routerLink: string) {
    this.currentRouterLink = routerLink;
  }

  // Method to determine the background color
  getBackgroundColor(key: string): string {
    return this.localData[key] === 0 ? '#0000008a' : '#2f49d1';
  }
}
