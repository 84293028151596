import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TopbarComponent } from './components/topbar/topbar.component';
import { ReviewComponent } from './components/review/review.component';
import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermComponent } from './components/term/term.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { BlogCategoriesComponent } from './components/blog-categories/blog-categories.component';
import { ArchievesPostsComponent } from './components/archieves-posts/archieves-posts.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth/login",
    pathMatch: "full"
  },
  {
    path: "review/:id/:type", component: ReviewComponent
  },
  {
    path: "privacy-policy", component: PrivacyComponent
  },
  {
    path: "terms-and-conditions", component: TermComponent
  },
  {
    path: "blog",component:BlogComponent
  },
  {
    path: "blog/:id",component:BlogDetailsComponent
  },
  {
    path: "blog/category/:catid",component:BlogCategoriesComponent
  },
  {
    path: "blog/archive/:archid",component:ArchievesPostsComponent
  },
  {
    path: "auth", loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: "app",
    canActivate: [AuthGuard],
    component: TopbarComponent,
    children: [

      {
        path: "admin",
        canActivate: [RoleGuard],
        data: { role: [1] },
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: "company",
        canActivate: [RoleGuard],
        data: { role: [3] },
        loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule)
      },
      {
        path: "user",
        canActivate: [RoleGuard],
        data: { role: [1] },
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
      },
      {
        path: "client",
        canActivate: [RoleGuard],
        data: { role: [1, 2, 3] },
        loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule)
      },
      {
        path: "business",
        canActivate: [RoleGuard],
        data: { role: [1, 2, 3] },
        loadChildren: () => import('./modules/store/store.module').then(m => m.StoreModule)
      },
      {
        path: "post",
        canActivate: [RoleGuard],
        data: { role: [1, 2, 3] },
        loadChildren: () => import('./modules/posts/posts.module').then(m => m.PostsModule)
      },
      {
        path: "media",
        canActivate: [RoleGuard],
        data: { role: [1, 2, 3] },
        loadChildren: () => import('./modules/media/media.module').then(m => m.MediaModule)
      },
      {
        path: "contentengine",
        canActivate: [RoleGuard],
        data: { role: [1, 2, 3] },
        loadChildren: () => import('./modules/content-engine/content-engine.module').then(m => m.ContentEngineModule)
      },
      {
        path: "mypackage",
        canActivate: [RoleGuard],
        data: { role: [1, 2, 3] },
        loadChildren: () => import('./modules/my-packages/my-packages.module').then(m => m.MyPackagesModule)
      },
      {
        path: "blog",
        canActivate: [RoleGuard],
        data: { role: [1, 2, 3] },
        loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule)
      },
      {
        path: "review",
        canActivate: [RoleGuard],
        data: { role: [1, 2, 3] },
        loadChildren: () => import('./modules/review/review.module').then(m => m.ReviewModule)
      },
      {
        path: "list",
        canActivate: [RoleGuard],
        data: { role: [2, 3] },
        loadChildren: () => import('./modules/list/list.module').then(m => m.ListModule)
      },
      {
        path: "number",
        canActivate: [RoleGuard],
        data: { role: [2, 3] },
        loadChildren: () => import('./modules/number/number.module').then(m => m.NumberModule)
      },
      {
        path: "domain",
        canActivate: [RoleGuard],
        data: { role: [2] },
        loadChildren: () => import('./modules/domain/domain.module').then(m => m.DomainModule)
      }
    ],
  },
  {
    path: "**",
    redirectTo: "auth/login",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
