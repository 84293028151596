<aside class="sidebar">
  <div class="sidebar-start">
    <div class="sidebar-head">
      <a *ngIf="localData.role == 1; else userDashboardLink" routerLink="/app/admin/dashboard" class="logo-wrapper"
        title="Home">
        <app-logo></app-logo>
        <!-- <h3>Social Share</h3> -->
      </a>
      <ng-template #userDashboardLink>
        <a routerLink="/app/user/dashboard" class="logo-wrapper" title="Home">
          <app-logo></app-logo>
          <!-- <h3>Social Share...</h3> -->
        </a>
      </ng-template>
    </div>

    <div id="leftside-navigation" class="sidebar-body nano">
      <ul class="nano-content sidebar-body-menu">
        <!-- Dashboard -->
        <li>
          <a [ngClass]="activeTab === 'dashboard' ? 'active' : ''" (click)="setActiveTab('dashboard')"
            [routerLink]="routerLink('dashboard')">
            <span class="icon"><i class="fas fa-tachometer-alt"></i></span>
            Dashboard
          </a>
        </li>


        <!-- Businesses -->
        <li *ngIf="localData.role == 1 || localData.role == 2">
          <a class="show-cat-btn" [ngClass]="activeTab == 'business' ? 'active' : ''" (click)="toggleMenu('business')">
            <span class="icon"><i class="fas fa-briefcase"></i></span>
            Businesses
          </a>
          <ul class="cat-sub-menu" [ngClass]="showBusiness ? 'showMenu2' : 'hideMenu2'">
            <li>
              <a routerLink="/app/business/businesses" [ngClass]="subActiveTab == 'view_businesses' ? 'active' : ''"
                (click)="setActiveTab('business', 'view_businesses')">
                All Business
              </a>
            </li>
            <li>
              <a routerLink="/app/business/add-business" [ngClass]="subActiveTab == 'add_business' ? 'active' : ''"
                (click)="setActiveTab('business', 'add_business')">
                Add Business
              </a>
            </li>
          </ul>
        </li>

        <!-- Posts -->
        <li *ngIf="localData.role == 1 || localData.role == 2">
          <a [ngClass]="activeTab == 'post' ? 'active' : ''" (click)="toggleMenu('post')">
            <span class="icon"><i class="fas fa-comment-alt"></i></span>
            Posts
          </a>
          <ul class="cat-sub-menu" [ngClass]="showPosts ? 'showMenu2' : 'hideMenu2'">
            <li>
              <a [routerLink]="routerLink('post')" [ngClass]="subActiveTab == 'post' ? 'active' : ''"
                (click)="setActiveTab('post', 'post')">
                All Posts
              </a>
            </li>
            <li>
              <a [routerLink]="routerLink('add-post')" [ngClass]="subActiveTab == 'add-post' ? 'active' : ''"
                (click)="setActiveTab('post', 'add-post')">
                Single Post
              </a>
            </li>
            <li>
              <a [routerLink]="routerLink('add-multipost')" [ngClass]="subActiveTab == 'add-multipost' ? 'active' : ''"
                (click)="setActiveTab('post', 'add-multipost')">
                Multiple Post
              </a>
            </li>
          </ul>
        </li>

        <!-- Media -->
        <li *ngIf="localData.role == 1 || localData.role == 2">
          <a [ngClass]="activeTab == 'media' ? 'active' : ''" (click)="toggleMenu('media')">
            <span class="icon"><i class="fas fa-camera"></i></span>
            Media
          </a>
          <ul class="cat-sub-menu" [ngClass]="showMedia ? 'showMenu2' : 'hideMenu2'">
            <li>
              <a [routerLink]="routerLink('library')" [ngClass]="subActiveTab == 'library' ? 'active' : ''"
                (click)="setActiveTab('media', 'library')">
                Library
              </a>
            </li>
          </ul>
        </li>

        <li *ngIf="localData.role == 1|| localData.role == 2">
          <a [ngClass]="activeTab == 'contentengine' ? 'active' : ''"
          (click)="showContentEngine = !showContentEngine; activeTab = 'contentengine'">
            <span class="icon"><i class="fa fa-list"></i></span>
            Package
          </a>
          <ul
            class="cat-sub-menu"
            [ngClass]="showContentEngine ? 'showMenu2' : 'hideMenu2'"
          >
          <li>
            <a [routerLink]="routerLink('post-packages')"
               [ngClass]="subActiveTab == 'post-packages' ? 'active' : ''"
               (click)="setActiveTab('contentengine', 'post-packages')">Packages</a>
          </li>
          <li>
            <a [routerLink]="routerLink('post-package-items')"
               [ngClass]="subActiveTab == 'post-package-items' ? 'active' : ''"
               (click)="setActiveTab('contentengine', 'post-package-items')">Packages Posts</a>
          </li>
          </ul>
        </li>

        <!-- My Packages -->
        <li *ngIf="localData.role == 1 || localData.role == 2 || activeTab == 'showMypackages'">
          <a [ngClass]="activeTab == 'showMypackages' ? 'active' : ''" (click)="toggleMenu('showMypackages')"
            [routerLink]="routerLink('my-packages')">
            <span class="icon"><i class="fas fa-box"></i></span>
            My Packages
          </a>
        </li>

        <!-- Blog -->
        <li *ngIf="localData.role == 1 || localData.role == 2">
          <a [ngClass]="activeTab == 'blog' ? 'active' : ''" (click)="toggleMenu('blog')">
            <span class="icon"><i class="fas fa-pencil-alt"></i></span>
            Blog
          </a>
          <ul class="cat-sub-menu" [ngClass]="showBlog ? 'showMenu2' : 'hideMenu2'">
            <li>
              <ng-container *ngIf="domains && domains.length > 0; else redirectLink1">
                <a [routerLink]="routerLink('all-blogs')" [ngClass]="subActiveTab == 'all-blogs' ? 'active' : ''"
                  (click)="setActiveTab('blog', 'all-blogs')">
                  All Posts
                </a>
              </ng-container>
              <ng-template #redirectLink1>
                <a [routerLink]="'/app/domain/buy-blog-domain'"
                  [ngClass]="subActiveTab == 'all-blogs' ? 'active' : ''"
                  (click)="setActiveTab('blog', 'all-blogs')">
                  All Posts
                </a>
              </ng-template>
            </li>
            <li>
              <ng-container *ngIf="domains && domains.length > 0; else redirectLink2">
                <a [routerLink]="routerLink('all-blogs-category')"
                  [ngClass]="subActiveTab == 'all-blogs-category' ? 'active' : ''"
                  (click)="setActiveTab('blog', 'all-blogs-category')">
                  All Categories
                </a>
              </ng-container>
              <ng-template #redirectLink2>
                <a [routerLink]="'/app/domain/buy-blog-domain'"
                  [ngClass]="subActiveTab == 'all-blogs-category' ? 'active' : ''"
                  (click)="setActiveTab('blog', 'all-blogs-category')">
                  All Categories
                </a>
              </ng-template>
            </li>
            <li>
              <ng-container *ngIf="domains && domains.length > 0; else redirectLink3">
                <a [routerLink]="routerLink('blog-settings')" [ngClass]="subActiveTab == 'blog-settings' ? 'active' : ''"
                  (click)="setActiveTab('blog', 'blog-settings')">
                  Settings
                </a>
              </ng-container>
              <ng-template #redirectLink3>
                <a [routerLink]="'/app/domain/buy-blog-domain'"
                  [ngClass]="subActiveTab == 'blog-settings' ? 'active' : ''"
                  (click)="setActiveTab('blog', 'blog-settings')">
                  Settings
                </a>
              </ng-template>
            </li>
            <li>
              <ng-container *ngIf="domains && domains.length > 0; else redirectLink4">
                <a [routerLink]="routerLink('all-blogs-domain')" [ngClass]="subActiveTab == 'all-blogs-domain' ? 'active' : ''"
                  (click)="setActiveTab('blog', 'all-blogs-domain')">
                  Blog Domains
                </a>
              </ng-container>
              <ng-template #redirectLink4>
                <a [routerLink]="'/app/domain/buy-blog-domain'"
                  [ngClass]="subActiveTab == 'all-blogs-domain' ? 'active' : ''"
                  (click)="setActiveTab('blog', 'all-blogs-domain')">
                  Blog Domains
                </a>
              </ng-template>
            </li>
          </ul>
        </li>

        <!-- Templates -->
        <li *ngIf="localData.role == 1">
          <a class="show-cat-btn" [ngClass]="activeTab == 'templates' ? 'active' : ''"
            (click)="toggleMenu('templates')">
            <span class="icon"><i class="fas fa-file-alt"></i></span>
            Templates
          </a>
          <ul class="cat-sub-menu" [ngClass]="showTemplate ? 'showMenu' : 'hideMenu'">
            <li>
              <a [routerLink]="routerLink('view_templates')"
                [ngClass]="subActiveTab == 'view_templates' ? 'active' : ''"
                (click)="setActiveTab('templates','view_templates')">
                View Templates
              </a>
            </li>
            <li *ngIf="localData.role != 3 || (localData.role == 3 && localData.access.add_template)">
              <a [routerLink]="routerLink('add_template')" [ngClass]="subActiveTab == 'add_template' ? 'active' : ''"
                (click)="setActiveTab('templates','add_template')">
                Add Template
              </a>
            </li>
          </ul>
        </li>

        <!-- Sign-Out -->
        <li>
          <a class="show-cat-btn" (click)="logout()">
            <span class="icon"><i class="fas fa-sign-out-alt"></i></span>
            Sign-Out
          </a>
        </li>

      </ul>
    </div>
  </div>
</aside>