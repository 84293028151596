<div class="main-wrapper">
    <main class="main users" id="skip-target">
      <div class="container-fluid">
        <div class="dsp-flx mb10">
          <h2 *ngIf="!package.id" class="main-title">Add Package</h2>
          <h2 *ngIf="package.id" class="main-title">Update Package</h2>
          <a class="btn btn-primary" style="float: right;" routerLink="/app/contentengine/post-packages">Back</a>
        </div>
        <form name="packageForm" #packageForm="ngForm">
          <div class="blue-panel">
            <div class="blue-panel-body">
              <div class="_form_account _white_bx" style="border-radius: 10px;">
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="packageName" class="form-label">Package Name<span class="text text-danger mt-1"> * </span></label>
                  </div>
                  <div class="col-md-5">
                    <input name="packageName" id="packageName" [(ngModel)]="package.name" (input)="capitalizeFirstLetter($event)" class="form-control" required>
                    <div *ngIf="packageForm.submitted">
                      <div *ngIf="packageForm.controls?.['packageName']?.['errors']?.['required']"
                        class="text text-danger mt-1">
                        Package name is required
                      </div>
                    </div>
                  </div>
                </div>                
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="description" class="form-label">Description<span class="text text-danger mt-1"> * </span></label>
                  </div>
                  <div class="col-md-5">
                    <!-- <angular-editor [(ngModel)]="package.description" [config]="config"></angular-editor> -->
                    <ckeditor class="_ckeditor_body" name="description" [editor]="Editor" [(ngModel)]="package.description" aria-required="required" required></ckeditor>
                    <div *ngIf="packageForm.submitted">
                      <div *ngIf="packageForm.controls?.['description']?.['errors']?.['required']"
                        class="text text-danger mt-1">
                        Description is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="packageName" class="form-label">Industry<span class="text text-danger mt-1"> * </span></label>
                  </div>
                  <div class="col-md-5">
                    <input name="Industry" id="Industry" [(ngModel)]="package.topic" class="form-control" required>
                    <div *ngIf="packageForm.submitted">
                      <div *ngIf="packageForm.controls?.['Industry']?.['errors']?.['required']"
                        class="text text-danger mt-1">
                        Industry is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="packageImage" class="form-label">Add Package Image</label>
                  </div>
                  <div class="col-md-3">
                    <input type="file" name="media_image" class="w100 form-control" [(ngModel)]="media_image"
                      (change)="onFileSelected($event)">
                  </div>
                  <div class="col-md-3" style="max-width: 16.6%;">
                    <img *ngIf="mediaSrc" [src]="mediaSrc" alt="Selected Image"
                      style="max-width: 100%; max-height: 300px; border: 1px solid #ddd;border-radius: 10px;">
                    <img *ngIf="!mediaSrc" src="{{mediaSrc}}" alt="Default Image"
                      style="max-width: 100%; max-height: 300px; border: 1px solid #ddd;border-radius: 10px;">
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="price" class="form-label">Price<span class="text text-danger mt-1"> * </span></label>
                  </div>
                  <div class="col-md-1">
                    <input name="price" id="price" [(ngModel)]="package.price" class="form-control" required>
                    <div *ngIf="packageForm.submitted">
                      <div *ngIf="packageForm.controls?.['price']?.['errors']?.['required']"
                        class="text text-danger mt-1">
                        Price is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-8">
                    <button *ngIf="package.id" type="button" (click)="updatePackage()" class="btn btn-primary">
                      Update
                    </button>
                    <button *ngIf="!package.id" type="submit" (click)="addPackage(packageForm)" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>