<div class="_blog_page_warp ">
  <div class="header" [ngStyle]="headerStyles">
    <div class="container">
      <h1 class="title">{{ headerStyles['headerTitle'] }}</h1>
      <div>
        <a href="{{headerStyles['email'] }}" style="color: #000; text-decoration: none; margin-right: 20px;">Email : {{headerStyles['email'] }}</a>
        <span style="color: #000; margin-right: 20px;">|</span>
        <span style="color: #000; margin-right: 20px;">{{headerStyles['contact'] }}</span>
      </div>
    </div>
  </div>
  <div style="margin-left: 40px; justify-content: left; display: flex; margin-bottom: 20px;">
    <a class="custom-link" routerLink="/blog">
      Home
    </a>
    <span style="margin-left: 10px; justify-content: left; display: flex; font-size: 18px;">>> {{ blog.title }}</span>
  </div>  
  <div class="container _blog_detail_page">
    <div class="row">
      <!-- Blog Section -->
      <div class="col-md-8">
        <div *ngIf="!blog" class="alert alert-info" role="alert">
          No blog found.
        </div>
        <div *ngIf="blog" class="card mb-3">
          <div class="card-body">
            <div class="image-container">
              <img [src]="mediaSrcs" alt="Blog Image" class="img-fluid" style="margin-bottom: 20px;">
            </div>
            <h4 class="card-title">{{ blog.title }}</h4>
            <p class="card-text" style="margin-top: 10px!important;">
              <span class="text-muted" style="color:#00acd8 !important;">
                <i class="fas fa-calendar"></i> <!-- FontAwesome calendar icon -->
                {{ blog.created | date:'dd MMMM yyyy' }}
              </span>
            </p>
            <div [innerHTML]="blog.content" class="card-content"></div>
            <div class="tag-social-link" *ngIf="tags.length>0">
              <div class="tag">
                <h6 class="title">Tags :</h6> <a href="javascript:void(0);">{{blog.tags}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Sidebar Section -->
      <div class="col-md-4">
        <!-- Add your categories, recent posts, archives, tags here -->
        <!-- Example: -->
        <div class="p-3 mb-3 _white_bx">
          <h4>Categories</h4>
          <!-- List of categories with counts -->
          <ul>
            <li *ngFor="let category of categoryCounts | keyvalue" class="category-item">
              <a [routerLink]="['/blog/category', category.value.id]">
                {{ category.key }}
              </a>
              <span style="float: right;"> ({{ category.value.count }})</span>
            </li>
          </ul>
          <app-spinner *ngIf="isLoading"></app-spinner>
        </div>            

        <div class="p-3 mb-3 _white_bx">
          <h4>Recent Posts</h4>
          <ul class="_recent_post">
            <li *ngFor="let blog of blogsinfo" (click)="onclickopenpost(blog.id)" class="category-items">
              <a>
                <img [src]="backend_url + blog.file_url" alt="Blog Image" class="_blog_img">
                <div class="blog-info">
                  <span style="color: #00acd8;">{{ blog.title }}</span>
                  <span class="created-date">{{ blog.created | date:'dd MMMM yyyy' }}</span>
                </div>
              </a>
            </li>
          </ul>
          <app-spinner></app-spinner>
        </div>        

        <div class="p-3 mb-3 _white_bx" *ngIf="getKeys(countsByMonth).length > 0">
          <h4>Archives</h4>
          <ul>
            <ng-container *ngFor="let monthYear of getKeys(countsByMonth)">
              <li class="category-item" [routerLink]="['/blog/archive', formatMonthYear(monthYear)]">
                <span>{{ formatMonthYear(monthYear) }}</span>
                <!-- Display count by month if available -->
                <span style="float: right;">
                  (
                  {{ countsByMonth[monthYear] }}
                  )
                </span>
              </li>
            </ng-container>
          </ul>
          <app-spinner></app-spinner>
        </div>

        <div class="p-3 mb-3 _white_bx" *ngIf="tags.length>0">
          <h4>Tags</h4>
          <!-- List of tags -->
          <ul class="tag-list">
            <li *ngFor="let tag of tags">{{ tag }}</li>
          </ul>
        </div>
        <app-spinner></app-spinner>
      </div>
    </div>
  </div>

</div>