<div class="main-wrapper">
  <main class="main users" id="skip-target">
    <div class="container-fluid">
      <div class="dsp-flx mb10">
        <h2 *ngIf="!blog.id" class="main-title">Add Post</h2>
        <h2 *ngIf="blog.id" class="main-title">Update Post</h2>
        <a class="btn btn-primary" style="float: right;" routerLink="/app/blog/all-blogs">Back</a>
      </div>
      <form name="blogForm" #blogForm="ngForm">
        <div class="blue-panel">
          <div class="blue-panel-body">
            <div class="_form_account _white_bx" style="border-radius: 10px;">
              <div class="row mb-3">
                <div class="col-md-3">
                  <label for="blogName" class="form-label">Title<span class="text text-danger mt-1"> * </span></label>
                </div>
                <div class="col-md-5">
                  <input name="blogName" id="blogName" [(ngModel)]="blog.title" class="form-control" required>
                </div>
                <div class="col-md-3" style="max-width: 16.6%;">
                  <button class="btn btn-primary" type="button" (click)="generatePostContent()">AI Content</button>
                </div>
                <div *ngIf="blogForm.submitted">
                  <div *ngIf="blogForm.controls?.['blogName']?.['errors']?.['required']" class="text text-danger mt-1">
                    Title is required
                  </div>
                </div>
                <span *ngIf="showMessage" class="text text-danger mt-1 ai_validation" style="margin-left: 25%;">
                  Please fill in the AI Post text.
                </span>
              </div>

              <div class="row mb-3">
                <div class="col-md-3">
                  <label for="content" class="form-label">Content<span class="text text-danger mt-1"> * </span></label>
                </div>
                <div class="col-md-5">
                  <!-- <angular-editor [(ngModel)]="blog.content" [config]="config" aria-required="required"
                      required></angular-editor> -->
                  <ckeditor class="_ckeditor_body" name="content" [editor]="Editor" [(ngModel)]="blog.content"
                    aria-required="required" required></ckeditor>
                </div>
                <div *ngIf="blogForm.submitted && !validateContentNotEmpty(blog.content)" class="text text-danger mt-1">
                  Content is required
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-3">
                  <label for="package" class="form-label">Category<span class="text text-danger mt-1"> * </span></label>
                </div>
                <div class="col-md-5">
                  <select name="category" class="form-input w100" [(ngModel)]="blog.category" *ngIf="!blog.id" required>
                    <option value="">Category</option>
                    <option *ngFor="let blog of blogs" [value]="blog.id">{{ blog.title }}</option>
                  </select>
                  <select name="category" class="form-input w100" [(ngModel)]="blog.category" *ngIf="blog.id" required>
                    <option value="">Category</option>
                    <option *ngFor="let blog of blogs" [value]="blog.title">{{ blog.title }}</option>
                  </select>
                </div>
                <div *ngIf="blogForm.submitted">
                  <div *ngIf="blogForm.controls?.['category']?.['errors']?.['required']" class="text text-danger mt-1">
                    Category is required
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-3">
                  <label for="blogName" class="form-label">Tags<span class="text text-danger mt-1"> * </span></label>
                </div>
                <div class="col-md-5">
                  <tag-input [(ngModel)]="blog.tags" [addOnBlur]="true" (onAdd)="onTagAdded($event)" (onRemove)="onTagRemoved($event)">
                  </tag-input>
                  </div>
                <div *ngIf="blogForm.submitted">
                  <div *ngIf="blogForm.controls?.['blogName']?.['errors']?.['required']" class="text text-danger mt-1">
                    Tags are required
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-3">
                  <label for="postImage" class="form-label">Add Image<span class="text text-danger mt-1"> *
                    </span></label>
                </div>
                <div class="col-md-3">
                  <input type="file" name="media_image" class="w100 form-control" [(ngModel)]="media_image"
                    (change)="onFileSelected($event)">
                </div>
                <div class="col-md-3" style="max-width: 16.6%;">
                  <img *ngIf="mediaSrc" [src]="mediaSrc" alt="Selected Image"
                    style="max-width: 100%; max-height: 300px; border: 1px solid #ddd;border-radius: 10px;">
                  <img *ngIf="!mediaSrc" src="{{backend_url}}" alt="Default Image"
                    style="max-width: 100%; max-height: 300px; border: 1px solid #ddd;border-radius: 10px;">
                </div>
                <div *ngIf="blogForm.submitted">
                  <div *ngIf="blogForm.controls?.['media_image']?.['errors']?.['required']" class="text text-danger mt-1">
                    Image is required
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8">
                  <button type="button" (click)="updateBlog()" *ngIf="blog.id" class="btn btn-primary">
                    Update
                  </button>
                  <button type="submit" (click)="addBlogpost(blogForm)" *ngIf="!blog.id" class="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </main>
</div>