<div class="modal-header">
    <h4 class="modal-title">Media Library</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="dsp-flx mb10" style="justify-content: normal;">
        <app-search (searchChange)="searchChangeEvent($event)"></app-search>
        <i class="fas fa-th tab-icon" (click)="showTab(1)" [class.active]="selectedPostTab === 1"></i>
        <i class="fas fa-list tab-icon" (click)="showTab(2)" [class.active]="selectedPostTab === 2"></i>
    </div>
        <form
          name="addmedia"
          #addmedia="ngForm"
        >
        
        <div class="row" *ngIf="selectedPostTab == 1">
            <div class="col-md-3 mb20" *ngFor="let media of mediadata | paginate : config">
                <div class="thumbnail">
                  <a href="javascript:void(0);">
                    <div class="image-container">
                      <img src="{{ mediaBaseUrl }}{{ media.file_url}}" [class.media_selected]="media.selected" (click)="toggleMediaSelection(media)" alt="Lights">
                    </div>
                  </a>
                </div>
            </div>
            
        </div>
        <div class="row" *ngIf="selectedPostTab == 2">
          <div class="col-lg-12">
            <div class="users-table table-wrapper">
              <table class="dashboard-table">
                <thead>
                  <tr class="users-table-info">
                    <th width="40%">File</th>
                    <th width="10%">Created</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="mediadata && mediadata.length == 0">
                    <td colspan="6" style="text-align: center;">
                      No records found
                    </td>
                  </tr>
                  <tr *ngFor="let media of mediadata | paginate: config;">
                    <td>
                      <div style="display: flex; align-items: center;">
                        <img src="{{ mediaBaseUrl }}{{ media.file_url }}" alt="Lights" style="width: 150px; height: 125px; border-radius: 0px; margin-right: 7px;">
                        <div style="margin-left: 10px;">
                          <div style="font-weight: 700;">{{ getFileNameWithoutExtension(media.file_name) }}</div>
                          <div>{{ media.file_name }}</div>
                        </div>
                      </div>
                    </td>                    
                    <td>{{ media.uploaded_date | date: 'dd MMMM yyyy' }}</td>
                  </tr>
                </tbody>
              </table>               
            </div>
          </div>
        </div>
        <app-pagination (pageChange)="pageChangeEvent($event)"></app-pagination>
        </form>

        <a href="javascript:void(0);" class="btn btn-primary" (click)="addSelectedMedias()">Add Selected Media</a>&nbsp;&nbsp;&nbsp;

        <button
        type="button"
        class="btn btn-outline-dark"
        (click)="activeModal.close('Close click')"
         >
        Close
        </button>
        <app-spinner></app-spinner>
  </div>
  
