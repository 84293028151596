<div class="modal-header">
  <h4 class="modal-title">Update Password</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form name="updatePasswordForm" (ngSubmit)="updatePasswordForm.form.valid && updatePassword()"
    #updatePasswordForm="ngForm">
    <div class="form-group col-sm-12 pos-relative mb15">
      <div>
        <label for="password" class="col-sm-4">New Password<span style="color: red;">*</span></label>
        <input class="form-input col-sm-8 mb0" [type]="show_eye ? 'text' : 'password'" [(ngModel)]="user.password"
          name="password" required minlength="6" />
      </div>
      <div class="_dsp_flx_inputrow">
        <label class="col-sm-4"></label>
        <div class="col-sm-8" *ngIf="updatePasswordForm.submitted">
          <div *ngIf="updatePasswordForm.controls?.['password']?.['errors']?.['required']"
            class="text text-danger mt-1">
            Please enter new password
          </div>
          <div *ngIf="updatePasswordForm.controls?.['password']?.['errors']?.['minlength']"
            class="text text-danger mt-1">
            Password should have atleast six characters
          </div>
        </div>
      </div>
      <div class="_gen_btn">
        <a (click)="generatePassword()" class="btn btn-primary">
          <i class="fas fa-user-lock"></i> Generate
        </a>
      </div>
     

      <img class="show-hide-icon" [src]="
      show_eye
        ? 'assets/img/eyeslash.png'
        : 'assets/img/eye.png'
    " id="img_2" [title]="show_eye ? 'hide characters' : 'show characters'" (click)="show_eye = !show_eye" />
    </div>

    <app-spinner></app-spinner>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary">Submit</button>
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
        Close
      </button>
    </div>
  </form>
</div>