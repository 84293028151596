import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AddCreditComponent } from '../../modals/add-credit/add-credit.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from 'src/app/services/spinner.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-credit',
  templateUrl: './credit.component.html',
  styleUrls: ['./credit.component.css']
})
export class CreditComponent implements AfterViewInit {

  localData: any = {};

  credits = {
    current: 0,
    used: 0
  }
  cost_per_sms_credit: number = 1;

  constructor(private modalService: NgbModal, private spinnerService: SpinnerService, private httpService: HttpService) { }

  ngAfterViewInit(): void {
    let userData = localStorage.getItem("user");
    if (userData != undefined && userData != null) {
      this.localData = JSON.parse(userData).data
    }
    this.getCurrentCredits();
  }

  openAddCreditModal = () => {
    const modalRef = this.modalService.open(AddCreditComponent);
    modalRef.componentInstance.user.chargePerCredit = this.cost_per_sms_credit
  }

  getCurrentCredits = () => {
    this.spinnerService.showSpinner();
    this.httpService.getRequest("credit/current-credit").
      subscribe((res) => {
        this.spinnerService.hideSpinner();
        if (res.status === 200) {
          this.credits = res.data
          //this.cost_per_sms_credit = res.planInfo.cost_per_sms_credit
        } else {
          this.spinnerService.errorSwal(res);
        }
      }, error => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }
}
