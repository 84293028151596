export const environment = {
    production: false,
    base_url: "https://social.devajdevtech.xyz/",
    backend_url: "https://social.devajdevtech.xyz:3006/",
    supportEmail: "support@socialposter10x.com",
    ipInfo: "https://ipinfo.io?token=93b956fc5261de",
    spinner: {
        type: "ball-scale-multiple",
        color: "#fff"
    },
    gmapUrl: "https://www.google.com/maps/",
    gplace_finder: "https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder",
    sales_url: {
        agency: "https://www.reviewportal.app/white-label.html",
        whitelabel: "https://www.reviewportal.app/white-label.html",
        tracking_number: ""
    },
    SSLPRICE: 27,
    widget_script_url:"https://widget.socialposter10x.com/widget-script.js"
};
