<div class="main-wrapper">
    <main class="main users" id="skip-target">
      <div class="container-fluid">
        <div class="dsp-flx mb10">
          <h2 class="main-title">Blog Settings</h2>
          <a class="btn btn-primary" style="float: right;" routerLink="/app/blog/all-blogs">Back</a>
        </div>
        <form name="blogForm" #blogForm="ngForm">
          <div class="blue-panel">
            <div class="blue-panel-body">
              <div class="_form_account _white_bx" style="border-radius: 10px;">
                <div class="row mb-3">
                    <div class="col-md-3">
                        <h3 for="blogName">Button Settings</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <label for="buttonColor" class="form-label">Button Text Color</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="color" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.businessSetting.buttonTextColor : blog.buttonTextColor" 
                            name="buttonTextColor" style="width: 70px;height: 35px;background-color: #fff;">
                    </div>
                    
                    <div class="col-md-2">
                        <label for="buttonColor" class="form-label">Button Background Color</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="color" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.businessSetting.buttonBackgroundColor : blog.buttonBackgroundColor" 
                            name="buttonBackgroundColor" style="width: 70px;height: 35px;background-color: #fff;">
                    </div>
                    
                    <div class="col-md-2">
                        <label for="buttonColor" class="form-label">Hover Text Color</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="color" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.businessSetting.HoverTextColor : blog.HoverTextColor" 
                            name="HoverTextColor" style="width: 70px;height: 35px;background-color: #fff;">
                    </div>
                    
                    <div class="col-md-2">
                        <label for="buttonColor" class="form-label">Hover Background Color</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="color" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.businessSetting.HoverBackgroundColor : blog.HoverBackgroundColor" 
                            name="HoverBackgroundColor" style="width: 70px;height: 35px;background-color: #fff;">
                    </div>
                    
                    <div class="col-md-2">
                        <label for="buttonText" class="form-label">Button Text</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="text" class="form-control" id="buttonText" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.businessSetting.buttonText : blog.buttonText" 
                            name="buttonText">
                    </div>
                    
                </div>
            

                <div class="row mb-3 mt-5">
                    <div class="col-md-3">
                        <h3 for="blogName">Sidebar Settings</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <label for="buttonColor" class="form-label">Recent Posts Count</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="number" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.sidebarSetting.RecentPostCount : blog.RecentPostCount" 
                            name="RecentPostCount" style="width: 90px;height: 35px;">
                    </div>
                    <div class="col-md-2">
                        <label for="buttonColor" class="form-label">Featured Count</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="number" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.sidebarSetting.FeaturedCount : blog.FeaturedCount" 
                            name="FeaturedCount" style="width: 90px;height: 35px;">
                    </div>
                    <div class="col-md-2">
                        <label for="buttonColor" class="form-label">Category Count</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="number" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.sidebarSetting.CategoryCount : blog.CategoryCount" 
                            name="CategoryCount" style="width: 90px;height: 35px;">
                    </div>
                    <div class="col-md-2">
                        <label for="buttonColor" class="form-label">Archive Count</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="number" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.sidebarSetting.ArchiveCount : blog.ArchiveCount" 
                            name="ArchiveCount" style="width: 90px;height: 35px;">
                    </div>
                </div>
                

                <div class="row mb-3 mt-5">
                    <div class="col-md-3">
                        <h3 for="blogName">Header Settings</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <label for="buttonColor" class="form-label">Width</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="text" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.headerSetting.width : blog.width" 
                            name="width" style="width: 90px;height: 35px;">
                    </div>
                    <div class="col-md-2">
                        <label for="buttonColor" class="form-label">Height</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="text" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.headerSetting.height : blog.height" 
                            name="height" style="width: 90px;height: 35px;">
                    </div>
                    <div class="col-md-2">
                        <label for="buttonColor" class="form-label">Email</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="email" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.headerSetting.email : blog.email" 
                            name="email" style="height: 35px;">
                    </div>
                    <div class="col-md-2">
                        <label for="buttonColor" class="form-label">Contact</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="text" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.headerSetting.contact : blog.contact" 
                            name="contact" style="height: 35px;">
                    </div>
                    <div class="col-md-2">
                        <label for="postImage" class="form-label">Header Background Image<span class="text text-danger mt-1"> * </span></label>
                    </div>
                    <div class="col-md-2">
                        <input type="file" name="media_image" class="w100 form-control" [(ngModel)]="media_image"
                            (change)="onFileSelected($event)">
                    </div>
                    <div class="col-md-2" style="max-width: 16.6%;">
                        <img *ngIf="mediaSrc" [src]="mediaSrc" alt="Selected Image"
                             style="max-width: 100%; max-height: 300px; border: 1px solid #ddd; border-radius: 10px;">
                        <img *ngIf="!mediaSrc" [src]="backend_url" alt="Default Image"
                             style="max-width: 100%; max-height: 300px; border: 1px solid #ddd; border-radius: 10px;">
                    
                        <!-- Display delete icon only when mediaSrc is present -->
                        <a *ngIf="this.blog.blog_settings.headerSetting.media_image!=''" href="javascript:void(0);" class="delete-icon" (click)="deleteMedia(blog.id)">&times;</a>
                    </div>
                    
                    <div class="col-md-2">
                        <label for="buttonColor" class="form-label">Header Title</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="text" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.headerSetting.headerTitle : blog.headerTitle" 
                            name="headerTitle" style="height: 35px;">
                    </div>
                
                    <div class="col-md-2">
                        <label for="buttonColor" class="form-label">Background Color</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="color" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.headerSetting.backgroundcolor : blog.backgroundcolor" 
                            name="backgroundcolor" style="width: 70px;height: 35px;background-color: #fff;">
                    </div>
                    <div class="col-md-2">
                        <label for="buttoncolor" class="form-label">Color</label><span class="text text-danger mt-1"> * </span>
                    </div>
                    <div class="col-md-2">
                        <input type="color" class="form-control" id="buttonColor" 
                            [(ngModel)]="blog.blog_settings ? blog.blog_settings.headerSetting.color : blog.color" 
                            name="color" style="width: 70px;height: 35px;background-color: #fff;">
                    </div>
                    <!-- <div class="col-md-2">
                        <label for="postImage" class="form-label">Logo Image<span class="text text-danger mt-1"> * </span></label>
                    </div>
                    <div class="col-md-2">
                        <input type="file" name="media_images" class="w100 form-control" [(ngModel)]="media_images"
                            (change)="onFile($event)">
                    </div>
                    <div class="col-md-2" style="max-width: 16.6%;">
                        <img *ngIf="mediaSrcs" [src]="mediaSrcs" alt="Selected Image"
                            style="max-width: 100%; max-height: 300px; border: 1px solid #ddd;border-radius: 10px;">
                        <img *ngIf="!mediaSrcs" src="{{backend_url}}" alt="Default Image"
                            style="max-width: 100%; max-height: 300px; border: 1px solid #ddd;border-radius: 10px;">
                    </div> -->
                </div>
                

                <div class="col-sm-8">
                    <!-- Use *ngIf with blogSettingsFetched property to conditionally render the button -->
                    <button *ngIf="!blogSettingsFetched" type="submit" (click)="addBlogsettings(blogForm)" class="btn btn-primary">
                      Save
                    </button>
                    <button *ngIf="blogSettingsFetched" type="submit" (click)="updateBlogSettings(blogForm)" class="btn btn-primary">
                      Update
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>