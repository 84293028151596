<div class="main-wrapper">
    <main class="main users" id="skip-target">
      <div class="container-fluid">
        <div class="dsp-flx mb-4">
          <h2 *ngIf="!blog.id" class="main-title">Add Category</h2>
          <h2 *ngIf="blog.id" class="main-title">Update Category</h2>
          <a class="btn btn-primary" style="float: right;" routerLink="/app/blog/all-blogs-category">Back</a>
        </div>
        <form name="blogForm" #blogForm="ngForm">
          <div class="blue-panel">
            <div class="blue-panel-body">
              <div class="_form_account _white_bx" style="border-radius: 10px;">
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="blogName" class="form-label">Title<span class="text text-danger mt-1"> * </span></label>
                  </div>
                  <div class="col-md-5">
                    <input name="blogName" id="blogName" [(ngModel)]="blog.title" (input)="capitalizeFirstLetter($event)" class="form-control" required>
                  </div>
                  <div *ngIf="blogForm.submitted">
                    <div *ngIf="blogForm.controls?.['blogName']?.['errors']?.['required']" class="text text-danger mt-1">
                      Title is required
                    </div>
                  </div>
                </div>                
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="content" class="form-label">Content<span class="text text-danger mt-1"> * </span></label>
                  </div>
                  <div class="col-md-5">
                    <!-- <angular-editor [(ngModel)]="blog.content" [config]="config" aria-required="required"
                      required></angular-editor> -->
                      <ckeditor class="_ckeditor_body" name="content" [editor]="Editor" [(ngModel)]="blog.content" aria-required="required" required></ckeditor>
                  </div>
                  <div *ngIf="blogForm.submitted && !validateContentNotEmpty(blog.content)" class="text text-danger mt-1">
                    Content is required
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="postImage" class="form-label">Add Image<span class="text text-danger mt-1"> * </span></label>
                  </div>
                  <div class="col-md-3">
                    <input type="file" name="media_image" class="w100 form-control" [(ngModel)]="media_image"
                      (change)="onFileSelected($event)">
                  </div>
                  <div class="col-md-3" style="max-width: 16.6%;">
                    <img *ngIf="mediaSrc" [src]="mediaSrc" alt="Selected Image"
                      style="max-width: 100%; max-height: 300px; border: 1px solid #ddd;border-radius: 10px;">
                    <img *ngIf="!mediaSrc" src="{{backend_url}}" alt="Default Image"
                      style="max-width: 100%; max-height: 300px; border: 1px solid #ddd;border-radius: 10px;">
                  </div>
                  <div *ngIf="blogForm.submitted">
                    <div *ngIf="blogForm.controls?.['media_image']?.['errors']?.['required']" class="text text-danger mt-1">
                      Image is required
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-8">
                    <button type="button" (click)="updateBlogcategory()" *ngIf="blog.id" class="btn btn-primary">
                      Update
                    </button>
                    <button type="submit" (click)="addBlogcategory(blogForm)" *ngIf="!blog.id" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>