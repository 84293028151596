import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './services/http.service';
import { SpinnerService } from './services/spinner.service';
import { CommonService } from './services/common.service';
import { Whitelabel } from './interfaces/Whitelabel';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  environment = environment;
  docLocation: any
  whiteLabel = {} as Whitelabel;

  constructor(@Inject(DOCUMENT) private document: Document, private httpService: HttpService, private spinnerService: SpinnerService, private commonService: CommonService) {

    const localData = localStorage.getItem("user")

    if (localData && localData != undefined) {
      this.commonService.subject.localData.next(JSON.parse(localData).data)
    }
  };

  ngOnInit(): void {

    this.docLocation = this.document.location;
    const { pathname } = this.docLocation;
    if (!pathname.includes("auth/popup")) {
      this.getDomainInfo()
    }
  }

  getDomainInfo = () => {

    const { hostname, origin } = this.docLocation;

    this.spinnerService.showSpinner();

    this.httpService
      .getRequestWithParameters("app/domain-info", { domain: hostname }).
      subscribe((res) => {
        if (res.status === 200) {
          this.spinnerService.hideSpinner();
          this.whiteLabel = res.data

          if (!this.whiteLabel.isWhiteLabel) {
            const script = this.document.createElement("script")
            script.src = "https://widget.callcid.com/call-widget?cID=r4htuS6KVMqV5P5apEcY8mV4hZoSmdEM7BtqGNNTzRE%3D"
            script.id = "widget-script"
            this.document.body.appendChild(script)
          }

          this.commonService.subject.sessionData.next(this.whiteLabel)
          environment.supportEmail = this.whiteLabel.email
        }

      }, (error) => {
        this.spinnerService.hideSpinner();
      })
  }

}