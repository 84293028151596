import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { constants } from 'src/app/constant';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharePostComponent } from '../modals/share-post/share-post.component';
import { AddPostingDatetimeComponent } from '../modals/add-posting-datetime/add-posting-datetime.component';
import { MediaItemComponent } from '../modals/media-item/media-item.component';
import { NgForm } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { EditMultipostComponent } from '../modals/edit-multipost/edit-multipost.component';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-multipost',
  templateUrl: './multipost.component.html',
  styleUrls: ['./multipost.component.css']
})
export class MultipostComponent implements OnInit {


  backend_url: string | ArrayBuffer | null = `${environment.backend_url}`;
  addMultiPostForm: any;
  selectedAiTab: number = 1;
  showAiSection = false;

  selectedPostTab: number = 1;
  showPostSection = false;
  showMessage: boolean = false;

  business_image: any = null;
  formData = new FormData();

  items: any = {}
  posts: any[] = [];
  selectedBusinesses: any[];

  networks: any[] = [];
  multipost: any = {
    category: '',
    packagecategory: '',
    business_id: '',
    package_id: '',
  }
  packages = null;
  schedule_to = null;

  selectedItems: any[] = [];
  selectedBusinessIds: number[] = [];
  selectedBusinessNames: any[] = [];

  mediaBaseUrl = null;
  search: string = "";
  config = {
    itemsPerPage: 12,
    currentPage: 1,
    totalItems: 0,
  };
  selectedFile: File | null = null;
  mediaSrc: string | ArrayBuffer | null = '';
  media_image: any = null;

  contentGenerated = false;
  showContentRequiredMessage = false;
  fetchingPackages: boolean = false;

  constructor(private route: ActivatedRoute,
    private httpService: HttpService,
    private spinnerService: SpinnerService,
    public commonService: CommonService,
    private router: Router,
    private location: Location,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal) {
    this.selectedBusinesses = this.commonService.getSelectedItems();
  }

  ngOnInit(): void {
    this.getBusinessNetworks();
    this.getPackages();
    this.getBusinesses();
    this.getNetworks();
  }

  getBusinesses = () => {
    this.spinnerService.showSpinner()
    const request = {};
    this.httpService.getRequestWithParameters("business/get-businesses", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner()
        if (response.status == 200) {
          this.items = response.data.data
          console.log(this.items)
        }
      }, (error) => {
        this.spinnerService.hideSpinner()
        this.spinnerService.errorSwal(error.error)
      })
  }

  isBusinessChecked(name: string): boolean {
    return this.selectedBusinessNames.includes(name);
  }


  onCheckboxChange(event: any, item: any) {
    const business = item;
    if (event.target.checked) {
      if (!this.selectedBusinessNames.includes(business)) {
        this.selectedBusinessNames.push(business);
      }
    } else {
      const index = this.selectedBusinessNames.indexOf(business);
      if (index !== -1) {
        this.selectedBusinessNames.splice(index, 1);
      }
    }
    console.log(this.selectedBusinessNames);
  }

  getBusinessNetworks = () => {
    this.spinnerService.showSpinner()
    const request = {};
    this.httpService.getRequestWithParameters("business/networks", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner()
        if (response.status == 200) {
          this.items = response.data.data
        }
      }, (error) => {
        this.spinnerService.hideSpinner()
        this.spinnerService.errorSwal(error.error)
      })
  }

  getPackages = () => {
    this.spinnerService.showSpinner()
    const request = { page: this.config.currentPage, limit: this.config.itemsPerPage, search: this.search };
    this.httpService.getRequestWithParameters("company/get-packages", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner()
        if (response.status == 200) {
          this.packages = response.data.data
          console.log(this.packages)
          // this.multipost.package_id = response.data.data[0].package_id;
          //this.packagepostcount = response.data.packagespostCount
          this.config.totalItems = response.data.total
        }
      }, (error) => {
        this.spinnerService.hideSpinner()
        this.spinnerService.errorSwal(error.error)
      })
  }

  showTab(tabNumber: number): void {
    this.selectedPostTab = tabNumber;
  }

  showAiTab(tabNumber: number): void {
    if (tabNumber == 1) {
      this.selectedAiTab = tabNumber;
    } else if (tabNumber == 2 && this.contentGenerated) {
      this.selectedAiTab = tabNumber;
    } else {
      this.showContentRequiredMessage = true;
    }
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.selectedFile = file;
    this.formData.append("media_image", file);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.mediaSrc = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  getpackagepost(packageId: number) {
    const request = { packageId };
    this.showTab(3);
    this.httpService.getRequestWithParameters('business/get-package-post', request)
      .subscribe(
        (response) => {
          this.spinnerService.hideSpinner();
          if (response.status === 200) {
            this.posts = response.data.data;
            console.log(this.posts);
          }
        },
        (error) => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        }
      );
  }

  trackByFn(index: number, item: any): any {
    return item.id;
  }

  editpackage(postId: number, postContent: any, index: number, media: any, mediaId: any) {
    const modalRef = this.modalService.open(EditMultipostComponent, { size: 'lg' });
    modalRef.componentInstance.modalinputdata = { postId: postId, postContent: postContent, mediaId: mediaId, media: media };

    modalRef.result.then(
      (result) => {
        if (result) {
          if (!this.posts[index].media || typeof this.posts[index].media !== 'object') {
            this.posts[index].media = {};
          }
          this.posts[index].content = result.modifiedText;
          this.posts[index].modifiedContent = result.modifiedText;
          if (result.modifiedMedia) {
            this.posts[index].media.file_url = result.modifiedMediaName;
            this.posts[index].media.id = result.modifiedMedia;
            this.posts[index].modifiedMedia = result.modifiedMedia;
          }
        }
      },
      (reason) => {
        console.log('Modal dismissed with reason:', reason);
      }
    );
  }

  async deletepackagepost(index: number) {
    let result = await this.spinnerService.confirmSwal(`You want to remove this post`);
    if (result.isConfirmed) {
      this.posts[index].isHidden = true;
    }
  }

  scheduleMultipost() {
    const idsArray = this.selectedBusinessNames.map(item => item.business_id);
    const idsString = idsArray.join(",");
    if (this.posts && !this.posts.every((post: { isHidden: boolean }) => post.isHidden)) {
      const form_data = this.posts.map((existingData: any) => {
        const nextDay = this.getNextDay(this.selectedBusinessNames[0].day_names);
        const schedule_to_business = nextDay ? nextDay.fromTime || "" : "";
        const businessSchedule = this.selectedBusinessNames[0]?.business_schedule;
        const fromTime = businessSchedule ? `${businessSchedule.from_time} ${businessSchedule.from_time_period}` : "N/A";
        const toTime = businessSchedule ? `${businessSchedule.to_time} ${businessSchedule.to_time_period}` : "N/A";

        return {
          ...existingData,
          business_id: idsArray,
          schedule_to: this.schedule_to,
          schedule_to_business: schedule_to_business,
          to_time: toTime,
          from_time: fromTime
        };
      });

      console.log(this.posts);
      this.spinnerService.showSpinner();
      this.httpService.postRequest(form_data, "business/schedule-multipost")
        .subscribe((response) => {
          this.spinnerService.hideSpinner();
          if (response.status == 200) {
            this.spinnerService.successSwal(response);
            this.router.navigateByUrl("/app/post/posts");
          }
        }, (error) => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        });

    } else {
      this.spinnerService.errorSwal({ message: "No post to schedule" });
    }
  }

  showPackageErrorMessage() {
    this.addMultiPostForm.submitted = true;
  }

  getNextDay(dayNames: string): { date: string, fromTime: string | null } | null {
    const databaseDays = ['SUN', 'MON', 'TUES', 'WED', 'THU', 'FRI', 'SAT'];
    const daysArray = dayNames.split(',').filter(day => day !== '');
    const firstDay = daysArray[0];
    const dayIndex = databaseDays.indexOf(firstDay);

    if (dayIndex === -1) {
      console.error("Invalid day name found in the specified day names.");
      return null;
    }

    const today = new Date();
    for (let i = 1; i <= 7; i++) {
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + i);
      if (nextDay.getDay() === dayIndex) {
        console.log('Found next occurrence on:', nextDay);
        const formattedDate = formatDate(nextDay, 'yyyy-MM-dd', 'en-US');
        const businessSchedule = this.selectedBusinessNames[0]?.business_schedule;
        const fromTime = businessSchedule ? `${formattedDate} ${businessSchedule.from_time}:00.000` : null;
        const finalDateTime = fromTime ? fromTime : formattedDate;
        console.log('Final date-time:', finalDateTime);
        return { date: formattedDate, fromTime: fromTime };
      }
    }
    return null;
  }

  getNetworks = () => {
    this.spinnerService.showSpinner()
    const request = { page: 1, limit: 1000, search: '' };
    this.httpService.getRequestWithParameters("business/networks", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner()
        if (response.status == 200) {
          this.networks = response.data.data
        }
      }, (error) => {
        this.spinnerService.hideSpinner()
        this.spinnerService.errorSwal(error.error)
      })
  }

  adddatetime(id: any) {
    const modalRef = this.modalService.open(AddPostingDatetimeComponent);
    modalRef.componentInstance.modalinputdata = { id: id };
    modalRef.result.then(
      (result) => {
        this.schedule_to = result.schedule_to
        console.log(this.schedule_to)
      },
      (reason) => {
        console.log('Modal dismissed with reason:', reason);
      }
    );

  }

}
