import { Component, Input, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit {

  @Input() country = { country_id: 0 };
  @Input() showCountries = ""

  countries = null;

  constructor(private httpService: HttpService, private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.getCountries();
  }

  getCountries = () => {

    this.spinnerService.showSpinner();
    this.httpService.getRequest("app/countries").
      subscribe((res) => {
        this.spinnerService.hideSpinner();
        if (res.status === 200) {
          if (this.showCountries.length > 0) {
            this.countries = res.data.filter((country: any) => this.showCountries.split(",").includes(country.iso))
          } else {
            this.countries = res.data;
          }
        } else {
          this.spinnerService.errorSwal(res);
        }
      }, error => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }

}
