import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormatphonePipe } from 'src/app/pipes/formatphone.pipe';
import { ShortenStringPipe } from 'src/app/pipes/shorten-string.pipe';
import { ValidateEqualModule } from 'ng-validate-equal';
import { ChartModule } from '../chart/chart.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { PaginationModule } from '../pagination/pagination.module';
import { SearchModule } from '../search/search.module';
import { BackModule } from '../back/back.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MergeFieldsComponent } from 'src/app/components/reusable/merge-fields/merge-fields.component';
import { CountryComponent } from 'src/app/components/reusable/country/country.component';
import { CreditModule } from '../credit/credit.module';
import { LogoComponent } from 'src/app/components/logo/logo.component';

@NgModule({
  declarations: [
    FormatphonePipe,
    ShortenStringPipe,
    MergeFieldsComponent,
    CountryComponent,
    LogoComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ValidateEqualModule,
    ChartModule,
    SpinnerModule,
    PaginationModule,
    SearchModule,
    BackModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [
    MergeFieldsComponent,
    CountryComponent,
    ChartModule,
    SpinnerModule,
    PaginationModule,
    SearchModule,
    CommonModule,
    FormsModule,
    ValidateEqualModule,
    NgbModule,
    FormatphonePipe,
    ShortenStringPipe,
    BackModule,
    NgMultiSelectDropDownModule,
    CKEditorModule,
    CreditModule,
    LogoComponent,
  ],
})
export class SharedModule { }
