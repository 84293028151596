<div class="main-wrapper">
    <main class="main users" id="skip-target">
      <div class="container-fluid">
        <div class="dsp-flx mb-4">
          <h2 *ngIf="!post.id" class="main-title">Add Post Packages</h2>
          <h2 *ngIf="post.id" class="main-title">Update Post Packages</h2>
          <a class="btn btn-primary" style="float: right;" routerLink="/app/contentengine/post-package-items">Back</a>
        </div>
        <form name="packageForm" #packageForm="ngForm">
          <div class="blue-panel">
            <div class="blue-panel-body">
              <div class="_form_account _white_bx" style="border-radius: 10px;">
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="packageName" class="form-label">Post Name<span class="text text-danger mt-1"> * </span></label>
                  </div>
                  <div class="col-md-5">
                    <input name="postName" id="postName" [(ngModel)]="post.title" class="form-control" required (input)="capitalizeFirstLetter()">
                    <div *ngIf="packageForm.submitted">
                      <div *ngIf="packageForm.controls?.['postName']?.['errors']?.['required']" class="text text-danger mt-1">
                        Post name is required
                      </div>
                    </div>
                  </div>
                </div>                
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="description" class="form-label">Description<span class="text text-danger mt-1"> * </span></label>
                  </div>
                  <div class="col-md-5">
                    <!-- <angular-editor [(ngModel)]="post.content" [config]="config"></angular-editor> -->
                    <ckeditor class="_ckeditor_body" name="content" [editor]="Editor" [(ngModel)]="post.content" aria-required="required" required></ckeditor>
                    <div *ngIf="packageForm.submitted">
                      <div *ngIf="packageForm.controls?.['content']?.['errors']?.['required']"
                        class="text text-danger mt-1">
                        Description is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="package" class="form-label">Packages<span class="text text-danger mt-1"> * </span></label>
                  </div>
                  <div class="col-md-5">
                    <select name="category" class="form-input w100" [(ngModel)]="post.category" *ngIf="!post.id" required>
                      <option value="">Package category</option>
                      <option *ngFor="let package of packages" [value]="package.id">{{ package.name }}</option>
                    </select>
                    <select name="category" class="form-input w100" [(ngModel)]="post.category" *ngIf="post.id" required>
                      <option value="">Package category</option>
                      <option *ngFor="let package of packages" [value]="package.name">{{ package.name }}</option>
                    </select>
                    <div *ngIf="packageForm.submitted">
                      <div *ngIf="packageForm.controls?.['category']?.['errors']?.['required']"
                        class="text text-danger mt-1">
                        Package category is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label for="postImage" class="form-label">Add Post Image</label>
                  </div>
                  <div class="col-md-3">
                    <input type="file" name="media_image" class="w100 form-control" [(ngModel)]="media_image"
                      (change)="onFileSelected($event)">
                  </div>
                  <div class="col-md-3" style="max-width: 16.6%;">
                    <img *ngIf="mediaSrc" [src]="mediaSrc" alt="Selected Image"
                      style="max-width: 100%; max-height: 300px; border: 1px solid #ddd;border-radius: 10px;">
                    <img *ngIf="!mediaSrc" src="{{backend_url}}" alt="Default Image"
                      style="max-width: 100%; max-height: 300px; border: 1px solid #ddd;border-radius: 10px;">
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-8">
                    <button *ngIf="post.id" type="button" (click)="updatePackage()" class="btn btn-primary">
                      Update
                    </button>
                    <button *ngIf="!post.id" type="submit" (click)="addPackage(packageForm)" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>