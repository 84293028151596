import { Component, EventEmitter, Injectable, Input, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { CommonService } from 'src/app/services/common.service';
import { EventSourceService } from 'src/app/services/event-source.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})

@Injectable({
  providedIn: 'root'
})
export class NotificationComponent implements OnInit {

  @Input() notificationPopup = {isVisible:false};
  height: string = "0px";
  opacity: number = 0;
  notifications = null;
  start: number = 0; limit: number = 10;
  @Input() notification = { unread: 0 }

  public notificationEmitter = new EventEmitter<object>();

  constructor(private httpService: HttpService, private spinnerService: SpinnerService, private commonService: CommonService,
    private eventSource: EventSourceService) { }

  ngOnInit(): void {

    /*const eventSource = this.eventSource.connect("api/notification/event")
    eventSource.onmessage = ({ data }) => {

      console.log('New notification', JSON.parse(data));

      let dataObj = JSON.parse(data);
      let { is_new_notification } = dataObj;

      if (is_new_notification == 1) { // if there is a new notification

        let userInfo = JSON.parse(localStorage.getItem("user") || '{"data":{"notification":{"unread":0,"total":0}}}')

        let { unread: unreadNotification, total: totalNotification } = userInfo.data.notification;

        let notificationObj = { unread: unreadNotification++, total: totalNotification++ };

        userInfo = { ...userInfo.data, notification: notificationObj }
        localStorage.setItem("user", JSON.stringify({ data: userInfo }));

        this.notificationEmitter.emit({ notification: notificationObj })
      }
    };*/
  }

  navigateNotification = (notificationObj: any) => {

    switch (notificationObj.type) {
      case 1: // Notification of type review
        this.commonService.navigateTo("review")
        break;
    }
  }

  getUserNotifications = () => {

    if (this.notificationPopup.isVisible) {
      this.height = "0px";
      this.opacity = 0;
    } else {
      this.height = "auto";
      this.opacity = 1;
      this.spinnerService.showSpinner();
      this.httpService.getRequestWithParameters("notification/notifications", { start: this.start, limit: this.limit })
        .subscribe((res: any) => {
          this.spinnerService.hideSpinner();
          if (res.status == 200) {
            this.notifications = res.data
            this.notificationPopup.isVisible = true;
            this.notification = { unread: 0 };
            let userInfo = JSON.parse(localStorage.getItem("user") || '{"data":{"notification":{"unread":0,"total":0}}}')
            userInfo = { ...userInfo.data, notification: { unread: 0, total: 0 } }
            localStorage.setItem("user", JSON.stringify({ data: userInfo }));
          }
        }, error => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }
  }
}
