<div class="container pd30">
  <div class="row _justify_content_center">
    <div class="col-sm-6">
      <article class="page-center" *ngIf="!showMessageForm">
        <form class="_review_from form" name="reviewForm" (ngSubmit)="reviewForm.form.valid && submitReview()"
          #reviewForm="ngForm">
          <h4 class="text-center mb15">{{store.review_form_data.form_heading}}</h4>

          <div class="form-group col-sm-12 pos-relative mb15">
            <div>
              <label class="form-label-wrapper">
                <input name="fullname" class="form-input" type="text" placeholder="Full Name"
                  [(ngModel)]="review.fullname" #fullname="ngModel" required />
              </label>
            </div>
            <div class="_dsp_flx_inputrow">
              <div *ngIf="reviewForm.submitted">
                <div *ngIf="reviewForm.controls?.['fullname']?.['errors']?.['required']"
                  class="text text-danger col-sm-12">
                  Name is required
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-sm-12 pos-relative mb15">
            <div>
              <label class="form-label-wrapper">
                <input name="email" class="form-input" type="email" placeholder="Email" [(ngModel)]="review.email"
                  #email="ngModel" required email />
              </label>
            </div>
            <div class="_dsp_flx_inputrow">
              <div *ngIf="reviewForm.submitted">
                <div *ngIf="reviewForm.controls?.['email']?.['errors']?.['required']"
                  class="text text-danger col-sm-12">
                  Email is required
                </div>
                <div *ngIf="reviewForm.controls?.['email']?.['errors']?.['email']" class="text text-danger mt-1">
                  Please enter a valid email
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <label class="form-label-wrapper">
                <select name="country_code" class="form-input" [(ngModel)]="review.code" #code="ngModel" required>
                  <option value="" disabled>Select country</option>
                  <option *ngFor="let country of countries" value="{{ country.iso }}">
                    {{ country.name }}
                  </option>
                </select>
              </label>
              <div *ngIf="reviewForm.submitted">
                <div *ngIf="reviewForm.controls?.['code']?.['errors']?.['required']" class="text text-danger mt-1">
                  Please select country
                </div>
              </div>
            </div>

            <div class="form-group col-sm-8 pos-relative mb15">
              <div>
                <label class="form-label-wrapper">
                  <input name="number" [(ngModel)]="review.number" #number="ngModel" class="form-input" type="number"
                    placeholder="Mobile Number" required minlength="9" maxlength="10" />
                </label>
              </div>
              <div class="_dsp_flx_inputrow">
                <div *ngIf="reviewForm.submitted">
                  <div *ngIf="reviewForm.controls?.['number']?.['errors']?.['required']"
                    class="text text-danger col-sm-12">
                    Number is required
                  </div>
                  <div *ngIf="reviewForm.controls?.['number']?.['errors']?.['minlength']" class="text text-danger mt-1">
                    Mobile number should be atleast 9 digits
                  </div>
                  <div *ngIf="reviewForm.controls?.['number']?.['errors']?.['maxlength']" class="text text-danger mt-1">
                    Mobile number should not be more than 10 digits
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="review_type == 'product'">
            <div class="col-sm-12">
              <label class="form-label-wrapper">
                <textarea style="height: 150px;" name="message" class="form-input"
                  placeholder="Enter few lines that you want to share with us" [(ngModel)]="review.message"
                  #message="ngModel" required></textarea>
              </label>
              <div *ngIf="reviewForm.submitted">
                <div *ngIf="reviewForm.controls?.['message']?.['errors']?.['required']" class="text text-danger mt-1">
                  Please share your feedback
                </div>
              </div>
            </div>
          </div>

          <div class="_star_review mb15">
            <h5 class="text-center">
              {{store.review_form_data.review_label}}
            </h5>
          </div>
          <div class="mb20 text-center">
            <ngb-rating [max]="5" [(rate)]="review.rating" [readonly]="false">
              <ng-template let-fill="fill">
                <span class="star" [ngClass]="'color'" [class.filled]="fill === 100">&#9733;</span>
              </ng-template>
            </ngb-rating>
          </div>

          <button class="form-btn _continue_btn fs22 primary-btn button" type="submit">
            {{store.review_form_data.btn_label}}
          </button>

          <app-spinner></app-spinner>

        </form>
      </article>

      <article class="page-center" *ngIf="showMessageForm">
        <form class="_review_from form" name="feedbackMessageForm"
          (ngSubmit)="feedbackMessageForm.form.valid && submitReview()" #feedbackMessageForm="ngForm">

          <div class="_text_left mb10">
            <a class="brd-button" (click)="showMessageForm = !showMessageForm"><b><i class="fas fa-arrow-left"></i>
                Back</b></a>
          </div>
          <h4 class="text-center mb15">
            {{store.review_form_data.review_message_heading}}
          </h4>

          <label class="form-label-wrapper">
            <textarea name="message" class="form-input" placeholder="Enter few lines that you want to share with us"
              [(ngModel)]="review.message" #message="ngModel" required></textarea>
          </label>

          <div *ngIf="feedbackMessageForm.submitted">
            <div *ngIf="feedbackMessageForm.controls?.['message']?.['errors']?.['required']"
              class="text text-danger mt-1">
              Please share your feedback
            </div>
          </div>

          <button class="form-btn _continue_btn fs22 primary-btn button" type="submit">
            Submit
          </button>

          <app-spinner></app-spinner>
        </form>
      </article>
    </div>

    <div class="col-sm-4">
      <article class="white-block text-center">
        <div class="mb20">
          <ngb-rating [max]="5" [(rate)]="store.rating" [readonly]="true">
            <ng-template let-fill="fill">
              <span class="star" [ngClass]="'color'" [class.filled]="fill === 100" [style.width.%]="fill">&#9733;</span>
            </ng-template>
          </ngb-rating>
        </div>
        <span *ngIf="store != null">
          <h3 class="text-center">{{ store.name }}</h3>
          <p>{{ store.description }}</p>
          <span *ngIf="store.image != ''">
            <img src="{{ environment.backend_url }}{{ store.image }}" width="100" height="100" />
          </span>
        </span>
        <h3 class="mt40"> {{store.review_form_data.item_review_label}}
        </h3>
      </article>
    </div>
  </div>
</div>