import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-my-packages',
  templateUrl: './add-my-packages.component.html',
  styleUrls: ['./add-my-packages.component.css']
})
export class AddMyPackagesComponent implements OnInit {

  constructor(private modalService: NgbModal, private spinnerService: SpinnerService,private httpService: HttpService,private router: Router,) { }

  environment = environment
  packages: any[] = [];
  postcount: any[] = [];
  search: string = "";
  config = { itemsPerPage: 10, currentPage: 1, totalItems: 0 };
  packagepostcount = null;
  date: string=""; 
  // Inside your component class
  postCounts: { [packageId: number]: number } = {};

  ngOnInit(): void {
    this.getPackages();
    // this.getPostcount();
  }

  searchChangeEvent = (eventData: { search: string }) => {
    this.search = eventData.search
    this.getPackages()
  }

  getPackages = () => {
    this.spinnerService.showSpinner()
    const request = { page: this.config.currentPage, limit: this.config.itemsPerPage, search: this.search };
    this.httpService.getRequestWithParameters("company/get-my-packages", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner()
        if (response.status == 200) {
          this.packages = response.data.data
          this.packagepostcount = response.data.packagespostCount
          this.config.totalItems = response.data.total
        }
      }, (error) => {
        this.spinnerService.hideSpinner()
        this.spinnerService.errorSwal(error.error)
      })
  }

  formatDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }
  

  pageChangeEvent = (eventData: { page: number }) => {
    this.config.currentPage = eventData.page
    this.getPackages()
  }

  async deletePackage(id:number){
    let result = await this.spinnerService.confirmSwal(`You want to delete this package`);
    if (result.isConfirmed) {
      const request = { id: id };
      this.httpService.putRequest(request, "contentengine/delete-package")
        .subscribe((response) => {
          this.spinnerService.hideSpinner();
          if (response.status == 200) {
            this.spinnerService.successSwal(response);
            this.getPackages();
          }
        }, (error) => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }
  }

  addToMyPackages = (id:number,name:string,price:number) => {
      const reqObject = { 
        id: id,
        name: name,
        price: price,
       };
      this.spinnerService.showSpinner();
      this.httpService.postRequest(reqObject, "contentengine/add-mypackage")
        .subscribe((response) => {
          this.spinnerService.hideSpinner();
          if (response.status == 200) {
            this.spinnerService.successSwal(response);
            this.router.navigateByUrl('/app/mypackage/my-packages');
          }
        }, (error) => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
  }

  isMyPackage = (id:number) => {
    return true;
  }


}
