import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-share-post',
  templateUrl: './share-post.component.html',
  styleUrls: ['./share-post.component.css']
})

export class SharePostComponent implements OnInit {

  business = {
    business_id: "",
    network_id: "",
    postcontent: "Hello Users",
    postimage: "https://wpgateway.com/app/img/wpgateway.png"
  }

  constructor(public activeModal: NgbActiveModal, private spinnerService: SpinnerService, private httpService: HttpService) { }

  ngOnInit(): void {
  }

  addPost = () => {
    console.log(this.business);
    this.spinnerService.showSpinner();
    this.httpService.postRequest(this.business,"business/add-post")
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        console.log(response.data)
        if (response.status == 200) {
          this.activeModal.close();
          this.spinnerService.successSwal(response)
        }
      }, (error) => {
        console.log(error)
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }

}