<div class="modal-header">
    <h4 class="modal-title">Blog Domain Setting</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <i class="fas fa-times"></i>
    </button>
</div>
<div class="modal-body" style="width: 100%;">
    <form name="whiteLabelSettingForm" (ngSubmit)="updateSetting(whiteLabelSettingForm.form)"
        #whiteLabelSettingForm="ngForm">

        <div class="form-group col-sm-12 pos-relative">
            <div>
                <label for="password" class="col-sm-4">Support Email<span style="color: red;">*</span></label>
                <input class="form-input col-sm-8 mb0" type="email" [(ngModel)]="domain.email" name="email" required>
            </div>
            <div class="_dsp_flx_inputrow">
                <label class="col-sm-4"></label>
                <div class="col-sm-8" *ngIf="whiteLabelSettingForm.submitted">
                    <div *ngIf="whiteLabelSettingForm.controls?.['email']?.['errors']?.['required']"
                        class="text text-danger mt-1">
                        Please Enter Support Email
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group col-sm-12 pos-relative">
            <div>
                <label for="password" class="col-sm-4">Logo</label>
                <input class="form-input col-sm-8 mb0" type="file" (change)="handleFileChange($event)">
            </div>

            <div class="_dsp_flx mb10" *ngIf="whitelabel_logo != null">
                <label class="col-sm-3"></label>
                <img src="{{whitelabel_logo}}" width="100" height="100" class="mt10">
            </div>
        </div>

        <app-spinner></app-spinner>

        <div class="modal-footer mt15">
            <button type="submit" class="btn btn-primary">Submit</button>
            <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
                Close
            </button>
        </div>
    </form>
</div>