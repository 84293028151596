
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-media-item',
  templateUrl: './media-item.component.html',
  styleUrls: ['./media-item.component.css']
})
export class MediaItemComponent implements OnInit {
  @Output() formSubmitted: EventEmitter<{ mediaselect: any, imageselected: any }> = new EventEmitter();

  mediadata: Array<any> = [];
  mediaBaseUrl = null;
  search: string = "";
  config = {
    itemsPerPage: 12,
    currentPage: 1,
    totalItems: 0,
  };

  public businesslist: any;
  public selectedBusiness: any;

  modalinputdata = {
    id: "",
    postcontent: ""
  }

  items: any[] = [];
  selectedItems: any[] = [];
  selectedItem: number = -1;
  selectedPostTab: number = 1;

  constructor(
    public activeModal: NgbActiveModal,
    private spinnerService: SpinnerService, 
    private httpService: HttpService,
    public commonService: CommonService) {
      this.selectedItems = this.commonService.getSelectedItems();   
    }
    ngOnInit(): void {
      this.getMedia();
    }
  
    ngAfterViewInit(): void {
      this.getMedia();
    }
  
    pageChangeEvent = (eventData: { page: number }) => {
      this.config.currentPage = eventData.page
      this.getMedia()
    }
  
    searchChangeEvent = (eventData: { search: string }) => {
      this.search = eventData.search;
      this.config.currentPage = 1;
      this.getMedia();
  }

  onAddItemClick() {
    const selectedItem = this.items.find(item => item.id == this.selectedItem);
    if (selectedItem && !this.selectedItems.find(item => item.id == selectedItem.id)) {
      this.commonService.addItem(selectedItem); // Add item using the service
    }
  }

  showTab(tabNumber: number): void {
    // if (this.areFieldsFilled()) {
      this.selectedPostTab = tabNumber;
    // }
  }

  onRemoveItemClick(selectedItem: any) {
    this.commonService.removeItem(selectedItem); // Remove item using the service
  }
  getFileNameWithoutExtension(fileName: string): string {
    return fileName.split('.').slice(0, -1).join('.');
  }

  getMedia = () => {
    this.spinnerService.showSpinner();
    const request = { page: this.config.currentPage, limit: this.config.itemsPerPage, search: this.search };
    this.httpService.getRequestWithParameters("media/list-media", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status === 200) {
          this.mediadata = response.data.data;
          this.config.totalItems = response.data.total;
          this.mediaBaseUrl = response.data.url;
        }
        console.log(this.config.totalItems);
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      });
  }

  selectedMedias: Array<any> = [];
  toggleMediaSelection(media: any) {
    // First, clear the selection for all images
    this.mediadata.forEach(img => img.selected = false);
    // Then, select the clicked image
    media.selected = true;
  }

  addSelectedMedias() {
    this.selectedMedias = this.mediadata.filter(media => media.selected);
    this.formSubmitted.emit( { mediaselect: true, imageselected: this.selectedMedias} );
  }

}

