import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  constructor() { }
  currentPage: number = 1;
  @Output() pageChange = new EventEmitter<{ page: number }>();

  ngOnInit(): void {
  }

  emitEvent = () => {
    this.pageChange.emit({ page: this.currentPage });
  }
}
