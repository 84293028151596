import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';

import { AddPostingDatetimeComponent } from '../modals/add-posting-datetime/add-posting-datetime.component';

@Component({
  selector: 'app-posts-content-engine',
  templateUrl: './posts-content-engine.component.html',
  styleUrls: ['./posts-content-engine.component.css']
})
export class PostsContentEngineComponent implements OnInit {

  constructor(private modalService: NgbModal, private spinnerService: SpinnerService, private httpService: HttpService) { }
  config = { itemsPerPage: 10, currentPage: 1, totalItems: 0 };
  posts:any[]=[];
  search: string = "";

  ngOnInit(): void {
    this.getPosts();
  }
  searchChangeEvent = (eventData: { search: string }) => {
    this.search = eventData.search
    this.getPosts()
  }

  pageChangeEvent = (eventData: { page: number }) => {
    this.config.currentPage = eventData.page
    this.getPosts()
  }

  getPosts = () => {
    this.spinnerService.showSpinner()
    const request = { page: this.config.currentPage, limit: this.config.itemsPerPage, search: this.search };
    this.httpService.getRequestWithParameters("contentengine/postpackages", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner()
        if (response.status == 200) {
          this.posts = response.data.data
          this.config.totalItems = response.data.total
        }
      }, (error) => {
        this.spinnerService.hideSpinner()
        this.spinnerService.errorSwal(error.error)
      })
  }

  async deletePost(id: number) {
    let result = await this.spinnerService.confirmSwal(`You want to delete this post`);
    if (result.isConfirmed) {
      const request = { id: id };
      this.httpService.putRequest(request, "contentengine/delete-post")
        .subscribe((response) => {
          this.spinnerService.hideSpinner();
          if (response.status == 200) {
            this.spinnerService.successSwal(response);
            this.getPosts();
          }
        }, (error) => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }
  }

}
