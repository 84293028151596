import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {

  constructor(
    private httpService: HttpService,
    private spinnerService: SpinnerService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  media_image: any = null;

  selectedFile: File | null = null;
  mediaSrc: string | ArrayBuffer | null = '';
  mediaSrcs: string | ArrayBuffer | null = '';
  backend_url: string | ArrayBuffer | null = `${environment.backend_url}`;
  baseUrl = environment.backend_url;

  blog: any = {
    category: '',
    title: '',
    content: ''
  }
  blogs: any = {}
  tags: any = {}
  blogsarchive: any = {}
  blogsinfo: any = {}
  countsByMonth: any = {}
  config = { itemsPerPage: 10, currentPage: 1, totalItems: 0 };
  search: string = "";
  categoryCounts: { [key: string]: { id: any, count: number } } = {};
  headerStyles: { [key: string]: string } = {};
  sideBarCount: { [key: string]: string } = {};
  isLoading = false;

  ngOnInit(): void {
    let id = this.route.snapshot.params['id'];
    if (id !== undefined) {
      this.blog.id = id;
      this.getBlogInfo();
      this.getBlogsCategory();
      this.getBlogsPages();
      this.getArchived();
      // this.gettags();
      this.getBlogSettingInfo();
    }
    this.getBlogSettingInfo();
  }

  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }


  getBlogInfo = () => {
    this.spinnerService.showSpinner();
    this.httpService.getRequestWithParameters("blog/blogs-info", { id: this.blog.id })
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          this.blog = response.data;
          if (this.blog.tags) { // Check if tags exist
            this.tags = this.blog.tags.split(',').map((tag: any) => tag.trim());
          } else {
            this.tags = []; // Set tags to an empty array if not present
          }
          const baseUrl = environment.backend_url;
          if (this.blog.file_url) {
            this.mediaSrcs = baseUrl + this.blog.file_url;
          } else {
            this.mediaSrcs = baseUrl + 'image_icon.jpg';
          }
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      });
  }


  getBlogsCategory = () => {
    this.spinnerService.showSpinner();
    const request = { page: this.config.currentPage, limit: this.config.itemsPerPage, search: this.search };
    this.httpService.getRequestWithParameters("blog/all-blogs-category", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          this.blogs = response.data.data;
          this.config.totalItems = response.data.total;
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      });
  };

  slicedBlogs: any[] = [];


  // getBlogsPages = () => {
  //   this.spinnerService.showSpinner();
  //   const request = { page: this.config.currentPage, limit: this.config.itemsPerPage };
  //   const baseUrl = environment.backend_url;
  //   this.httpService.getRequestWithParameters("blog/all-blog-pages", request)
  //     .subscribe((response) => {
  //       this.spinnerService.hideSpinner();
  //       if (response.status == 200) {
  //         this.blogsinfo = response.data.data;
  //         const recentPostCount = this.blog.blog_settings.sidebarSetting.RecentPostCount;
  //         const categoryCount = this.blog.blog_settings.sidebarSetting.CategoryCount; // Get category count setting

  //         // Limit the number of recent posts based on the RecentPostCount setting
  //         if (recentPostCount > 0) {
  //           this.blogsinfo = this.blogsinfo.slice(0, recentPostCount);
  //         }

  //         const blogsArray = Object.values(this.blogsinfo);
  //         const slicedBlogs = blogsArray.slice(0, recentPostCount);
  //         this.blogsinfo.forEach((blog: any) => {
  //           blog.mediaSrc = baseUrl + blog.file_url;
  //         });
  //         this.config.totalItems = response.data.total;

  //         // Count occurrences of category_title
  //         this.categoryCounts = {}; // Clear existing counts
  //         this.blogsinfo.forEach((blog: any) => {
  //           const category = blog.category_title;
  //           if (!this.categoryCounts[category]) {
  //             this.categoryCounts[category] = { id: blog.category_id, count: 1 };
  //           } else {
  //             this.categoryCounts[category].count++;
  //           }
  //         });

  //         // Limit the number of categories to the specified count
  //         if (categoryCount > 0) {
  //           this.categoryCounts = Object.keys(this.categoryCounts)
  //             .slice(0, categoryCount)
  //             .reduce((obj, key) => {
  //               obj[key] = this.categoryCounts[key];
  //               return obj;
  //             }, {} as { [category: string]: { id: number; count: number; } });
  //         }
  //         console.log(this.categoryCounts)

  //       }
  //     }, (error) => {
  //       this.spinnerService.hideSpinner();
  //       this.spinnerService.errorSwal(error.error);
  //     });
  // };

  getBlogsPages = (): void => {
    this.isLoading = true;
    const request = { page: this.config.currentPage, limit: this.config.itemsPerPage };
    const baseUrl = environment.backend_url;

    this.httpService.getRequestWithParameters("blog/all-blog-pages", request)
      .subscribe((response) => {
        this.isLoading = false;
        if (response.status === 200) {
          this.blogsinfo = response.data.data;
          console.log("blogsinfo")
          console.log(this.blogsinfo)
          // Check if blog_settings and sidebarSetting exist and have valid values
          const sidebarSetting = this.blog?.blog_settings?.sidebarSetting;
          const recentPostCount = sidebarSetting?.RecentPostCount ?? 0;
          const categoryCount = sidebarSetting?.CategoryCount ?? 0;

          this.blogsinfo.forEach((blog: any) => {
            blog.mediaSrc = baseUrl + blog.file_url;
          });
          this.config.totalItems = response.data.total;

          // Count occurrences of category_title
          this.updateCategoryCounts(categoryCount);

          if (recentPostCount > 0) {
            this.blogsinfo = this.blogsinfo.slice(0, recentPostCount);
          }
        }
      }, (error) => {
        this.isLoading = false;
        this.spinnerService.errorSwal(error.error);
      });
  };

  private updateCategoryCounts = (categoryCount: number): void => {
    const counts: { [category: string]: { id: number; count: number } } = {};

    this.blogsinfo.forEach((blog: any) => {
      const category = blog.category_title;
      if (!counts[category]) {
        counts[category] = { id: blog.category_id, count: 1 };
      } else {
        counts[category].count++;
      }
    });

    if (categoryCount > 0) {
      this.categoryCounts = Object.keys(counts)
        .slice(0, categoryCount)
        .reduce((obj, key) => {
          obj[key] = counts[key];
          return obj;
        }, {} as { [category: string]: { id: number; count: number } });
    } else {
      this.categoryCounts = counts;
    }
  };

  getArchived = () => {
    this.spinnerService.showSpinner();
    const request = { page: this.config.currentPage, limit: this.config.itemsPerPage };
    const baseUrl = environment.backend_url; // Declare baseUrl before using it
    this.httpService.getRequestWithParameters("blog/archieved-blogs", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          const archivedBlogs = response.data.archivedBlogs;
          this.countsByMonth = response.data.countsByMonth;
          // Extract unique months from archivedBlogs
          this.blogsarchive = this.extractUniqueMonths(archivedBlogs);
          // Format the monthYear property of each archive entry to match the keys in countsByMonth
          this.blogsarchive.forEach((entry: any) => {
            const monthYear = this.formatMonthYear(entry.created);
            entry.monthYear = monthYear;
          });
          const archiveCount = this.blog?.blog_settings?.sidebarSetting?.ArchiveCount;
          this.blogsarchive = this.blogsarchive.slice(0, archiveCount);
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      });
  };
  

  formatMonthYear(monthYear: string): string {
    const dateParts = monthYear.split('-');
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]);
    const formattedMonth = new Date(year, month - 1).toLocaleDateString('en-US', { month: 'long' });
    return `${formattedMonth}-${year}`;
  }

  // Function to extract unique months from an array of blogs
  extractUniqueMonths(blogs: { created: Date; monthYear: string }[]): { created: Date; monthYear: string }[] {
    const uniqueMonths: { created: Date; monthYear: string }[] = [];
    const monthsSet = new Set(); // Using a Set to ensure uniqueness
    blogs.forEach(blog => {
      const monthYear = new Date(blog.created).toLocaleString('en-US', { month: 'long', year: 'numeric' });
      if (!monthsSet.has(monthYear)) {
        uniqueMonths.push({ created: blog.created, monthYear: monthYear });
        monthsSet.add(monthYear);
      }
    });
    return uniqueMonths;
  }

  onclickopenpost(id: number) {
    this.router.navigate(['/blog', id]).then(() => {
      window.location.reload();
    });
  }

  getBlogSettingInfo = () => {
    this.spinnerService.showSpinner();
    this.httpService.getRequest("blog/blogsettingsinfo")
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status === 200) {
          // Initialize the blog object if it's null or undefined
          if (!this.blog) {
            this.blog = {};
          }
          // Use example blog_settings if response data is null
          if (response.data === null) {
            this.blog.blog_settings = {
              businessSetting: { // Corrected the key name from 'buttonSetting' to 'businessSetting'
                buttonTextColor: '#ffffff',
                buttonBackgroundColor: '#00acd8',
                HoverTextColor: '#ffffff',
                HoverBackgroundColor: '#00acd8',
                buttonText: 'Read More'
              },
              sidebarSetting: {
                RecentPostCount: '5',
                FeaturedCount: '5',
                CategoryCount: '5',
                ArchiveCount: '5'
              },
              headerSetting: {
                width: '100%',
                height: '100px',
                email: 'example@example.com',
                contact: '1234567890',
                media_image: '',
                headerTitle: 'Blog Title',
                backgroundcolor: '#00acd8',
                color: '#ffffff'
              }
            };
          } else {
            // Use fetched blog_settings
            this.blog.blog_settings = response.data.blog_settings;
            // Parse JSON if blog_settings is a string
            if (typeof this.blog.blog_settings === 'string') {
              this.blog.blog_settings = JSON.parse(this.blog.blog_settings);
            }
          }
          // Set mediaSrc based on blog_settings
          const baseUrl = environment.backend_url;
          if (this.blog.blog_settings.headerSetting && this.blog.blog_settings.headerSetting.media_image) {
            this.mediaSrc = baseUrl + this.blog.blog_settings.headerSetting.media_image;
          } else {
            this.blog?.blog_settings?.headerSetting?.backgroundcolor
          }
          
          // Update header styles based on blog_settings
          this.headerStyles = {
            'background-color': this.blog?.blog_settings?.headerSetting?.backgroundcolor || '#00acd8',
            'headerTitle': this.blog?.blog_settings?.headerSetting?.headerTitle || 'Blog Posts',
            'email': this.blog?.blog_settings?.headerSetting?.email || 'test@gmail.com',
            'contact': this.blog?.blog_settings?.headerSetting?.contact || '11223344556',
            'background-image': this.mediaSrc ? 'url(' + this.mediaSrc + ')' : '',
            'background-size': 'cover',
            'background-repeat': 'no-repeat',
            'width': this.blog?.blog_settings?.headerSetting?.width || '100%',
            'height': this.blog?.blog_settings?.headerSetting?.height || '100px',
            'color': this.blog?.blog_settings?.headerSetting?.color || '#fff',
            'display': 'flex',
            'justify-content': 'space-between',
            'align-items': 'center',
            'padding': '0 20px',
            'margin-bottom': '20px'
          };
          this.sideBarCount = {
            'RecentPostCount': this.blog?.blog_settings?.sidebarSetting?.RecentPostCount || 5,
            'FeaturedCount': this.blog?.blog_settings?.sidebarSetting?.FeaturedCount || 3,
            'CategoryCount': this.blog?.blog_settings?.sidebarSetting?.CategoryCount || 5,
            'ArchiveCount': this.blog?.blog_settings?.sidebarSetting?.ArchiveCount || 5,
          };
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      });
  }

  onCategoryClick(categoryId: any) {
  }

}
