<div class="modal-header">
    <h4 class="modal-title">Add Post</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      name="addPostForm"
      (ngSubmit)="addPostForm.form.valid && addPost()"
      #addPostForm="ngForm"
    >
      <div class="form-group">
        <label for="postcontent">Post Content</label>
        <input
          type="text"
          class="form-control"
          id="postcontent"
          name="postcontent"
          #postcontent="ngModel"
          [(ngModel)]="business.postcontent"
          required
        />
  
        <div *ngIf="addPostForm.submitted">
          <div
            *ngIf="addPostForm.controls?.['postcontent']?.['errors']?.['required']"
            class="text text-danger mt-1"
          >
            Please enter post content
          </div>
        </div>

        <input
          type="text"
          class="form-control"
          id="postcontent"
          name="postimage"
          #postimage="ngModel"
          [(ngModel)]="business.postimage"
        />
        <p>Sample Image: https://wpgateway.com/app/img/wpgateway.png</p>
      </div>
  
      <button type="submit" class="btn btn-primary">Submit</button
      >&nbsp;&nbsp;&nbsp;
  
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="activeModal.close('Close click')"
      >
        Close
      </button>
      <app-spinner></app-spinner>
    </form>
  </div>
  
