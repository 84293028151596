import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-whitelabel-setting',
  templateUrl: './whitelabel-setting.component.html',
  styleUrls: ['./whitelabel-setting.component.css']
})
export class WhitelabelSettingComponent implements OnInit {

  @Output() domainSetting = new EventEmitter<object>();

  domain = {
    id: 0,
    email: "",
    logo: ""
  };

  whitelabel_logo: any = null;

  formData = new FormData()

  constructor(public activeModal: NgbActiveModal, private spinnerService: SpinnerService, private httpService: HttpService, private commonService: CommonService) { }

  ngOnInit(): void {

    if (this.domain.logo.length > 1) {
      this.whitelabel_logo = `${environment.backend_url}${this.domain.logo}`
    }
    this.formData.append("id", String(this.domain.id))
    this.formData.append("upload_path", "logo")
  }

  handleFileChange = (event: any) => {

    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.onload = e => this.whitelabel_logo = reader.result;
    reader.readAsDataURL(file);

    this.formData.delete("website_logo");
    this.formData.append("website_logo", file);
  }

  updateSetting = (form: FormGroup) => {

    if (this.commonService.checkFormValid(form)) {

      this.formData.delete("email")
      this.formData.append("email", this.domain.email)

      this.spinnerService.showSpinner();
      this.httpService.putRequest(this.formData, "domain/updatesetting")
        .subscribe((response) => {
          this.spinnerService.hideSpinner();
          if (response.status == 200) {
            this.spinnerService.successSwal(response)

            setTimeout(() => {
              this.activeModal.close();
              this.whitelabel_logo = ""
              this.domainSetting.emit({
                isUpdated: true
              })
            }, 1500);

          }
        }, (error) => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }

  }

}