import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
//import { AngularEditorConfig } from '@kolkov/angular-editor';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-content-engine',
  templateUrl: './add-content-engine.component.html',
  styleUrls: ['./add-content-engine.component.css']
})
export class AddContentEngineComponent implements OnInit {
  selectedFile: File | null = null;
  mediaSrc: string | ArrayBuffer | null = '';

  constructor(
    private httpService: HttpService,
    private spinnerService: SpinnerService,
    public commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  package: any = {
  }
  formData = new FormData();
  media_image: any = null;
  backend_url: string | ArrayBuffer | null = `${environment.backend_url}image_icon.jpg`;
  
  ngOnInit(): void {
    let id = this.route.snapshot.params['id'];
    if (id !== undefined) {
      this.package.id = id;
      this.getPackageInfo();
    }
  }

  Editor = ClassicEditor;
  // public config: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: true,
  //   height: '15rem',
  //   minHeight: '5rem',
  //   placeholder: 'Enter text here...',
  //   translate: 'no',
  //   customClasses: [
  //     {
  //       name: "quote",
  //       class: "quote",
  //     },
  //     {
  //       name: 'redText',
  //       class: 'redText'
  //     },
  //     {
  //       name: "titleText",
  //       class: "titleText",
  //       tag: "h1",
  //     },
  //   ]
  // }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.selectedFile = file;
    this.media_image = file; 
    this.formData.append("media_image", file);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.mediaSrc = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  capitalizeFirstLetter(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length > 0) {
      input.value = input.value.charAt(0).toUpperCase() + input.value.slice(1);
      this.package.name = input.value; // Update the model
    }
  }

  addPackage(form: any) {
    form.submitted = true;
    if (form.valid) {
      let mediaObjArr = Object.keys(this.package)
      mediaObjArr.forEach((val: any, key) => {
        this.formData.append(mediaObjArr[key].toString(), this.package[val]);
      })
      this.spinnerService.showSpinner();
      this.httpService.postRequest(this.formData, "contentengine/add-package")
        .subscribe((res) => {
          this.spinnerService.hideSpinner();
          if (res.status == 200) {
            this.spinnerService.successSwal(res);
            this.router.navigateByUrl("/app/contentengine/post-packages");
          }
        }, (error) => {
          this.formData = new FormData()
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }

  }

  getPackageInfo = () => {
    this.spinnerService.showSpinner();
    this.httpService.getRequestWithParameters("contentengine/packageinfo", { id: this.package.id })
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          this.package = response.data;
          console.log("packages")
          console.log(this.package)
          const baseUrl = environment.backend_url; 
          this.mediaSrc = baseUrl + this.package.media.file_url;
          if(this.package.media.file_url){
            this.mediaSrc = baseUrl + this.package.media.file_url;
          } else {
            this.mediaSrc = baseUrl + 'image_icon.jpg';
          }
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      });
  }

  updatePackage() {
    this.spinnerService.showSpinner();

    const updatedPackageData = {
      name: this.package.name,
      description: this.package.description,
      topic: this.package.topic,
      price: parseInt(this.package.price, 10)
    };

    if (this.selectedFile) {
      const formDataWithImage = new FormData();
      formDataWithImage.append('media_image', this.selectedFile);
      formDataWithImage.append('name', this.package.name);
      formDataWithImage.append('description', this.package.description);
      formDataWithImage.append('topic', this.package.topic);
      formDataWithImage.append('price', parseInt(this.package.price, 10).toString());

      this.httpService.putRequest(formDataWithImage, `contentengine/update-package/${this.package.id}`)
        .subscribe(
          (res) => {
            this.spinnerService.hideSpinner();
            if (res.status === 200) {
              this.spinnerService.successSwal(res);
              this.router.navigateByUrl('/app/contentengine/post-packages');
            }
          },
          (error) => {
            this.spinnerService.hideSpinner();
            this.spinnerService.errorSwal(error.error);
          }
        );
    } else {
      this.httpService.putRequest(updatedPackageData, `contentengine/update-package/${this.package.id}`)
        .subscribe(
          (res) => {
            this.spinnerService.hideSpinner();
            if (res.status === 200) {
              this.spinnerService.successSwal(res);
              this.router.navigateByUrl('/app/contentengine/post-packages');
            }
          },
          (error) => {
            this.spinnerService.hideSpinner();
            this.spinnerService.errorSwal(error.error);
          }
        );
    }
  }

}
