import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-credit',
  templateUrl: './add-credit.component.html',
  styleUrls: ['./add-credit.component.css']
})
export class AddCreditComponent implements OnInit {


  user = {
    user_id: 0,
    credits: 10,
    chargePerCredit: 0.5,
    is_added_by_admin: false
  }

  constructor(public activeModal: NgbActiveModal, private spinnerService: SpinnerService, private httpService: HttpService, private router: Router,) { }

  ngOnInit(): void {
  }

  addCredits = () => {
    // Check if credits entered is negative
    if (this.user.credits < 0) {
        this.spinnerService.errorSwal({ message: "Please enter valid credits." });
        return; // Exit the function if credits is negative
    }

    if (!this.user.credits && this.user.credits !== 0) {
      this.spinnerService.errorSwal({ message: "Please enter credits." });
      return; // Exit the function if credits field is empty
    }
    this.spinnerService.showSpinner();
    this.httpService.postRequest(this.user, "credit/addcredits")
        .subscribe((response) => {
            this.spinnerService.hideSpinner();
            console.log(response.data)
            if (response.status == 200) {
                this.activeModal.close();
                this.spinnerService.successSwal(response);
                this.router.navigate([this.router.url]);
            }
        }, (error) => {
            this.spinnerService.hideSpinner();
            this.spinnerService.errorSwal({ message: error.error.message });
        });
  }



}
