import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-encodedecode',
  templateUrl: './encodedecode.component.html',
  styleUrls: ['./encodedecode.component.css']
})

export class EncodedecodeComponent implements OnInit {

  encode_data = {
    data: "sadasdsadsad",
    type: "encrypt",
    response_string: ""
  }

  deode_data = {
    data: "sadasdsadsad",
    type: "decrypt",
    response_string: ""
  }

  constructor(public activeModal: NgbActiveModal, private spinnerService: SpinnerService, private httpService: HttpService) { }

  ngOnInit(): void {
  }

  encode = () => {

    console.log(this.encode_data);
    this.spinnerService.showSpinner();
    this.httpService.postRequest(this.encode_data,"app/encode-decode")
      .subscribe((response) => {  
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          this.encode_data.response_string = response.data.afterEncDec;
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }

  Decode = () => {

    console.log(this.deode_data);
    this.spinnerService.showSpinner();
     this.httpService.postRequest(this.deode_data,"app/encode-decode")
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          this.deode_data.response_string = response.data.afterEncDec;
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }

}
