<div class="modal-header">
  <h4 class="modal-title">Edit post</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form name="addPostForm" (ngSubmit)="addPostForm.form.valid && editPackage()" #addPostForm="ngForm">

    <div class="row mb10">
      <div class="col-sm-4">
        <input name="prompt" [(ngModel)]="post.prompt" class="form-control mb0" rows="5" #prompt="ngModel"
          placeholder="AI Post text" (input)="onInput()" />
      </div>
      <div class="col-sm-8">
        <ul class="tab-header ai_post_btn mb0">
          <li (click)="generatePostContent()">AI Post</li>
          <li (click)="generatePostMedia()">Generate AI Image</li>
        </ul>
      </div>
      <div class="col-sm-12">
        <div *ngIf="showMessage" class="text text-danger mt-1" style="margin-left: 10px;">
          Please fill in the AI Post text.
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <label for="description" class="form-label pl10">Post Content</label>
      <div class="col-md-12">
        <ckeditor class="_ckeditor_body" name="content" [editor]="Editor" [(ngModel)]="info.content"
          aria-required="required" required></ckeditor>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-8">
        <label for="description" class="form-label">Post Image</label>
        <input type="file" name="media_image" class="w100 form-control"
          (change)="handleFileChange($event,'media_image')">
        <p class="mb10 mt5">OR</p>
        <a href="javascript:void(0);" (click)="openImageGallery()" class="btn btn-primary">Upload from
          gallery</a>
      </div>

      <div class="col-md-4" style="padding-top: 16px;">
        <img *ngIf="modalinputdata.media" [src]="media_url" alt="Post Image" style="width: 81px;
          height: 63px;
          border-radius: 10px;
          margin-right: 10px;
          margin-left: 0;">
        <a *ngIf="modalinputdata.media" (click)="removeImage();" class="delete-post-img">
          <span _ngcontent-hnm-c33="" class="icon"><i _ngcontent-hnm-c33="" class="fas fa-trash"></i></span>
        </a>
        <span *ngIf="!modalinputdata.media" class="no-img-post">No Image</span>
      </div>
    </div>
    <div style="border-top: 1px solid #ddd;padding-top: 15px;">
      <button type="submit" class="btn btn-primary">Save</button>&nbsp;&nbsp;
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close click')">
        Close
      </button>
    </div>
    <app-spinner></app-spinner>
  </form>
</div>