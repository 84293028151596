<div class="modal-header" style="background-color: transparent;">
    <ul class="tab-header">
      <li (click)="showTab(1)">Agency Info</li>
      <li (click)="showTab(2)">Notification</li>
    </ul>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body">
    <div class="blue-panel">
      <div class="col-md-12">
        <div class="_form_account _white_bx">
          <form name="profileForm" (ngSubmit)="profileForm.form.valid && updateProfile()" #profileForm="ngForm">
            <!-- Agency Info Fields -->
            <div *ngIf="selectedPostTab === 1">
              <div class="_input_row">
                <div class="_input_row">
                  <label class="col-md-2">Name</label>
                  <input class="col-md-10" type="text" name="name" placeholder="name" [(ngModel)]="user.name"
                    #name="ngModel" required />
                  <div *ngIf="profileForm.submitted">
                    <div *ngIf="profileForm.controls?.['name']?.['errors']?.['required']" class="text text-danger mt-1">
                      Name is required
                    </div>
                  </div>
                </div>
                <div class="_input_row">
                  <label class="col-md-2">Email</label>
                  <input class="col-md-10" type="email" name="email" placeholder="email" [(ngModel)]="user.email"
                    #email="ngModel"  />
                </div>
                <div class="_input_row">
                  <label class="col-md-2">Country</label>
                  <select class="col-md-5" *ngIf="countries != null" name="country" [(ngModel)]="user.country_id"
                    #country="ngModel" disabled required>
                    <option value="" disabled selected>Select country</option>
                    <option *ngFor="let country of countries" value="{{ country.id }}">
                      {{ country.name }}
                    </option>
                  </select>
                  <input class="col-md-5" type="number" name="mobile" placeholder="Mobile" [(ngModel)]="user.mobile"
                    #mobile="ngModel" />
                </div>
                <div class="_input_row">
                  <label class="col-md-2">Address</label>
                  <input class="col-md-10" type="text" name="address" placeholder="address" [(ngModel)]="user.address"
                    #address="ngModel" required />
                  <div *ngIf="profileForm.submitted">
                    <div *ngIf="profileForm.controls?.['address']?.['errors']?.['required']"
                      class="text text-danger mt-1">
                      Address is required
                    </div>
                  </div>
                </div>
                <div class="_input_row">
                  <label class="col-md-2">State</label>
                  <input class="col-md-10" type="text" name="state" placeholder="state" [(ngModel)]="user.state"
                    #state="ngModel" required />
                  <div *ngIf="profileForm.submitted">
                    <div *ngIf="profileForm.controls?.['state']?.['errors']?.['required']" class="text text-danger mt-1">
                      State is required
                    </div>
                  </div>
                </div>
                <div class="_input_row">
                  <label class="col-md-2">City</label>
                  <input class="col-md-10" type="text" name="city" placeholder="city" [(ngModel)]="user.city"
                    #city="ngModel" required />
                  <div *ngIf="profileForm.submitted">
                    <div *ngIf="profileForm.controls?.['city']?.['errors']?.['required']" class="text text-danger mt-1">
                      City is required
                    </div>
                  </div>
                </div>
                <div class="_input_row">
                  <label class="col-md-2">Zip</label>
                  <input class="col-md-10" type="number" name="zipcode" placeholder="zipcode" [(ngModel)]="user.zipcode"
                    #zipcode="ngModel" required />
                  <div *ngIf="profileForm.submitted">
                    <div *ngIf="profileForm.controls?.['zipcode']?.['errors']?.['required']"
                      class="text text-danger mt-1">
                      Zipcode is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div *ngIf="selectedPostTab === 2">
              <h5>DEFAULT NOTIFICATIONS</h5>
              <p style="margin-bottom: 30px;">Where Do You Want To Send Conversion Notifications?</p>
              <div class="_input_row" style="margin-bottom: 20px;">
                <label class="col-md-2">Email</label>
                <input class="col-md-5" type="email" name="email" placeholder="email" [(ngModel)]="user.email"
                  #email="ngModel" />
              </div>
              <div class="_input_row" style="margin-bottom: 20px;">
                <label class="col-md-2">Mobile Phone</label>
                <input class="col-md-5" type="number" name="mobile" placeholder="Mobile" [(ngModel)]="user.mobile"
                  #mobile="ngModel" />
              </div>
              <div class="_input_row" style="margin-bottom: 20px;">
                <label class="col-md-2">Mobile Notification times</label>
                <label style="margin-right: 10px;"><input type="checkbox" name="weekDay" value="SUN"
                    (change)="onCheckboxChange('SUN')" [checked]="selectedDays.includes('SUN')">SUN</label>
                <label style="margin-right: 10px;"><input type="checkbox" name="weekDay" value="MON"
                    (change)="onCheckboxChange('MON')" [checked]="selectedDays.includes('MON')">MON</label>
                <label style="margin-right: 10px;"><input type="checkbox" name="weekDay" value="TUE"
                    (change)="onCheckboxChange('TUE')" [checked]="selectedDays.includes('TUE')">TUE</label>
                <label style="margin-right: 10px;"><input type="checkbox" name="weekDay" value="WED"
                    (change)="onCheckboxChange('WED')" [checked]="selectedDays.includes('WED')">WED</label>
                <label style="margin-right: 10px;"><input type="checkbox" name="weekDay" value="THU"
                    (change)="onCheckboxChange('THU')" [checked]="selectedDays.includes('THU')">THU</label>
                <label style="margin-right: 10px;"><input type="checkbox" name="weekDay" value="FRI"
                    (change)="onCheckboxChange('FRI')" [checked]="selectedDays.includes('FRI')">FRI</label>
                <label style="margin-right: 10px;"><input type="checkbox" name="weekDay" value="SAT"
                    (change)="onCheckboxChange('SAT')" [checked]="selectedDays.includes('SAT')">SAT</label>
              </div>
              <div style="margin-left: 280px;">
                <div *ngFor="let range of timeRanges; let i = index" class="time-range-container">
                  <label for="fromHour{{i}}">From Time:&nbsp;&nbsp;</label>
                  <select id="fromHour{{i}}" name="fromHour" [(ngModel)]="range.fromHour" style="width: 80px;">
                    <option value="">01</option>
                    <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                  </select>
  
                  <select id="fromMinute{{i}}" name="fromMinute" [(ngModel)]="range.fromMinute" style="width: 80px;">
                    <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                  </select>
  
                  <select id="fromAmPm{{i}}" name="fromAmPm" [(ngModel)]="range.fromAmPm" style="width: 70px;">
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
  
                  <label for="toHour{{i}}">&nbsp;&nbsp;To Time:&nbsp;&nbsp;</label>
                  <select id="toHour{{i}}" name="toHour" [(ngModel)]="range.toHour" style="width: 80px;">
                    <option value="">12</option>
                    <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                  </select>
  
                  <select id="toMinute{{i}}" name="toMinute" [(ngModel)]="range.toMinute" style="width: 80px;">
                    <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                  </select>
  
                  <select id="toAmPm{{i}}" name="toAmPm" [(ngModel)]="range.toAmPm" style="width: 70px;">
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
              </div>
            </div>
            <app-spinner></app-spinner>
            <div class="d-flex justify-content-end mt-3">
              <ng-container *ngIf="selectedPostTab === 1">
                <button href="javascript:void(0);" class="btn btn-primary mr-2"
                  (click)="showTab(2)">Next</button>&nbsp;&nbsp;&nbsp;
                <button type="button" class="btn btn-outline-dark"
                  (click)="activeModal.close('Close click')">Skip</button>
              </ng-container>
              <ng-container *ngIf="selectedPostTab === 2">
                <button href="javascript:void(0);" class="btn btn-primary"
                  (click)="updateProfile()">Save</button>&nbsp;&nbsp;&nbsp;
                <button type="button" class="btn btn-outline-dark"
                  (click)="activeModal.close('Close click')">Skip</button>
              </ng-container>
            </div>
  
          </form>
        </div>
      </div>
    </div>
  </div>