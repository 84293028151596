import { Component, OnInit } from '@angular/core';
import { NgbActiveModal,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { MediaItemComponent } from '../media-item/media-item.component';
import { AddCreditComponent } from '../add-credit/add-credit.component';

@Component({
  selector: 'app-edit-multipost',
  templateUrl: './edit-multipost.component.html',
  styleUrls: ['./edit-multipost.component.css']
})
export class EditMultipostComponent implements OnInit {
  fileSelected: any = '';
  backend_url: string | ArrayBuffer | null = `${environment.backend_url}`;
  media_url: string | ArrayBuffer | null = '';
  modalinputdata: { postId?: number, postContent?: any, media?: any, mediaId?: any } = {};
  formData = new FormData();
  media: any = {
  }
  info: any = {
  }

  post: any = {}
  
  showMessage: boolean = false;


  constructor(
    public activeModal: NgbActiveModal,
    private spinnerService: SpinnerService, 
    private httpService: HttpService, 
    private route: ActivatedRoute, 
    private router: Router,
    public commonService: CommonService,
    private modalService: NgbModal
    ) { }

  ngOnInit(): void {
    if (this.modalinputdata && this.modalinputdata.postId) {
      const postId = this.modalinputdata.postId;

      this.info.id = this.modalinputdata.postId;
      this.info.content = this.modalinputdata.postContent;
      this.info.media_id = this.modalinputdata.mediaId;
      this.info.media = this.modalinputdata.media;
      //this.getPostInfo(postId);
    }
    this.media_url = `${environment.backend_url}`+this.modalinputdata.media;
  }

  removeImage = () => {
    this.modalinputdata.media = "";
    this.info.media = 0;
    this.info.media_id = 0;
  }

  editPackage() {
    if(this.fileSelected != ''){

      let mediaObjArr = Object.keys(this.media)
      mediaObjArr.forEach((val: any, key) => {
        this.formData.append(mediaObjArr[key].toString(), this.media[val]);
      })

      this.spinnerService.showSpinner();  
      this.httpService.postRequest(this.formData, "media/add-media")
      .subscribe((res) => {
        this.spinnerService.hideSpinner();
        if (res.status == 200) {
          this.info.media = res.filename;
          this.info.media_id = res.media_id;
          console.log(this.info.media);
          console.log(this.info.media_id)

          const modifiedText = this.info.content;
          const modifiedMediaName = this.info.media;
          const modifiedMedia = this.info.media_id;
          this.activeModal.close({ modifiedText, modifiedMediaName, modifiedMedia });

        }
      }, (error) => {
        this.formData = new FormData()
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
      
    } else {

      const modifiedText = this.info.content;
      const modifiedMediaName = this.info.media;
      const modifiedMedia = this.info.media_id;
      this.activeModal.close({ modifiedText, modifiedMediaName, modifiedMedia });

    }
    
  }

  handleFileChange = (event: any, property: string = 'media_image') => {
    if (property == "media_image") {
      const fileInput = event.target;
      if (fileInput.files && fileInput.files[0]) {
        const file = fileInput.files[0];
        this.fileSelected = file;
        this.formData.append("media_image", file);
        const reader = new FileReader();
        reader.onload = () => {
          this.media_url = reader.result as string;
          this.modalinputdata.media = this.media_url;
        };
        reader.readAsDataURL(file);
      }
    }
  }

  Editor = ClassicEditor;
  onInput() {
    if (this.post.prompt) {
      this.showMessage = false;
    }
  }
  
  generatePostContent = () => {
    if (!this.post.prompt) {
      this.showMessage = true;
      return;
    }
    this.spinnerService.showSpinner();

    const request = {
      prompt: this.post.prompt
    };

    this.httpService.getRequestWithParameters("business/generate-post", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          this.info.content = response.data.data;
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      });
  }

  generatePostMedia = async () => {
    if (!this.post.prompt) {
      this.showMessage = true;
      return;
    }
    this.spinnerService.showSpinner();
    var result = await this.commonService.getCreditsInfo();

    if(!result.available){
      this.spinnerService.hideSpinner();
      //this.spinnerService.errorSwal({message:result.message});
      this.openAddCreditModal();
    } else {

      const request = {
        prompt: this.post.prompt
      };
      this.httpService.getRequestWithParameters("business/generate-image", request)
        .subscribe((response) => {
          this.spinnerService.hideSpinner();
          if (response.status == 200) {
            this.info.media_id = response.data.media_id;
            this.info.media = response.data.final_image_url;

            const baseUrl = environment.backend_url;
            this.media_url = baseUrl + response.data.final_image_url;
            this.modalinputdata.media = this.media_url;

            this.fileSelected = '';//reset the file upload important

          }
        }, (error) => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        });

    }

  }


  openImageGallery = () => {
    const modalRef = this.modalService.open(MediaItemComponent, { size: 'lg' });
    modalRef.componentInstance.modalinputdata = {};
    modalRef.componentInstance.formSubmitted.subscribe((result: any) => {
      if (result.mediaselect) {
        this.fileSelected = '';
        this.media_url = environment.backend_url + result.imageselected[0].file_url;
        this.modalinputdata.media = this.media_url;

        this.info.media_id = result.imageselected[0].id;
        this.info.media = result.imageselected[0].file_url;
        modalRef.close();
      }
    });
  }

  openAddCreditModal = () => {
    const modalRef = this.modalService.open(AddCreditComponent);
  }

}
