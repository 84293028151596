import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    public router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let user = JSON.parse(localStorage.getItem("user") || '{"data":{"role":0}}');

    let { role: userRole } = user.data; // get the role saved in localstorage

    let role = route.data['role'] // get the role passed from canactivate guard

    // if (!role.includes(userRole)) {
    //   this.router.navigate(['auth/login']);
    //   return false;
    // } else {
    return true;
    // }
  }
}
