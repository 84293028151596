import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  environment = environment
  blogs: any[] = [];
  postcount = null;
  baseUrl: string = environment.backend_url; // Make sure to import environment
  search: string = "";
  config = { itemsPerPage: 10, currentPage: 1, totalItems: 0 };
  packagepostcount = null;
  date: string = "";
  mediaSrc: string | ArrayBuffer | null = '';
  backend_url: string | ArrayBuffer | null = `${environment.backend_url}image_icon.jpg`;
  blog: any = {}

  constructor(private modalService: NgbModal, private spinnerService: SpinnerService, private httpService: HttpService,private router: Router,
    ) { }

  ngOnInit(): void {
    this.getBlogSettingInfo(); // Call this first
    this.getBlogsPages();
  }
  pageChangeEvent = (eventData: { page: number }) => {
    this.config.currentPage = eventData.page
    this.getBlogsPages()
  }
  searchChangeEvent = (eventData: { search: string }) => {
    this.search = eventData.search
    this.getBlogsPages()
  }
  getBlogsPages = () => {
    this.spinnerService.showSpinner()
    const request = { page: this.config.currentPage, limit: this.config.itemsPerPage,search: this.search};
    this.httpService.getRequestWithParameters("blog/all-blog-pages",request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner()
        if (response.status == 200) {
          this.blogs = response.data.data
          const baseUrl = environment.backend_url;
          this.blogs.forEach(blogs => {
            blogs.mediaSrc = baseUrl + blogs.file_url;
            blogs.isExpanded = false; // Initialize isExpanded property
          });
          this.config.totalItems = response.data.total;
        }
      }, (error) => {
        this.spinnerService.hideSpinner()
        this.spinnerService.errorSwal(error.error)
      })
  }

  truncateContent(content: string): string {
    return content.length > 50 ? content.slice(0, 50) + '...' : content;
  }

  activeButton: 'recent' | 'popular' = 'recent'; // Default active button

  toggleActive(button: 'recent' | 'popular'): void {
    this.activeButton = button;
  }
  
  onclickopenpost(id: number) {
    this.router.navigate(['/blog', id]).then(() => {
      window.location.reload();
    });
  }

  getBlogSettingInfo = () => {
    this.spinnerService.showSpinner();
    this.httpService.getRequest("blog/blogsettingsinfo")
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status === 200) {
          // Initialize the blog object if it's null or undefined
          if (!this.blog) {
            this.blog = {};
          }
          // Use example blog_settings if response data is null
          if (response.data === null) {
            this.blog.blog_settings = {
              businessSetting: { // Corrected the key name from 'buttonSetting' to 'businessSetting'
                buttonTextColor: '#ffffff',
                buttonBackgroundColor: '#00acd8',
                HoverTextColor: '#ffffff',
                HoverBackgroundColor: '#00acd8',
                buttonText: 'Read More'
              },
              sidebarSetting: {
                RecentPostCount: '5',
                FeaturedCount: '5',
                CategoryCount: '5',
                ArchiveCount: '5'
              },
              headerSetting: {
                width: '100%',
                height: '100px',
                email: 'example@example.com',
                contact: '1234567890',
                media_image: '',
                headerTitle: 'Blog Title',
                backgroundcolor: '#00acd8',
                color: '#ffffff'
              }
            };
          } else {
            // Use fetched blog_settings
            this.blog.blog_settings = response.data.blog_settings;
            // Parse JSON if blog_settings is a string
            if (typeof this.blog.blog_settings === 'string') {
              this.blog.blog_settings = JSON.parse(this.blog.blog_settings);
            }
          }
          // Set mediaSrc based on blog_settings
          const baseUrl = environment.backend_url;
          if (this.blog.blog_settings.headerSetting && this.blog.blog_settings.headerSetting.media_image) {
            this.mediaSrc = baseUrl + this.blog.blog_settings.headerSetting.media_image;
          } else {
            this.mediaSrc = baseUrl + 'image_icon.jpg';
          }
          console.log(this.mediaSrc)
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      });
  }

  isHovered: boolean = false;

  onMouseEnter() {
      this.isHovered = true;
  }

  onMouseLeave() {
      this.isHovered = false;
  }

  getButtonStyle() {
      if (this.isHovered) {
          return {
              'color':this.blog.blog_settings.businessSetting.HoverTextColor+'!important',
              'background-color':  this.blog.blog_settings.businessSetting.HoverBackgroundColor+'!important'
          };
      } else {
          return {
              'color': this.blog.blog_settings.businessSetting.buttonTextColor,
              'background-color': this.blog.blog_settings.businessSetting.buttonBackgroundColor
          };
      }
  }


}