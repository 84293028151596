<div class="main-wrapper">
    <main class="main users" id="skip-target">
      <div class="container-fluid">
        <div class="_text_right mb10">
          <a class="btn btn-primary" routerLink="/app/mypackage/my-packages">Back</a>
        </div>
        <div class="dsp-flx mb10">
          <h2 class="main-title">Packages</h2>
          <app-search (searchChange)="searchChangeEvent($event)" ></app-search>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="users-table table-wrapper">
              <table class="dashboard-table">
                <thead>
                  <tr class="users-table-info">
                    <th width="20%">Content Package Name</th>
                    <th width="20%">Industry</th>
                    <th width="10%">Number of posts</th>
                    <th width="10%">Action</th>
                  </tr>
                </thead>
                <tbody *ngIf="packages != null">
                  <tr *ngFor="let package of packages | paginate : config">
                    <td>
                      <div class="pages-table-img">
                        {{package.name}}
                      </div>
                    </td>
                    <td>
                      <div class="pages-table-img">
                        {{package.topic}}
                      </div>
                    </td>
                    <td>
                        {{ packagepostcount ? packagepostcount[package.id] : '-' }}
                    </td>
                    <td>
                      <a class="btn btn-primary" *ngIf="!package.bought" (click)="addToMyPackages(package.id,package.name,package.price)">Add</a>
                      <a class="btn btn-success" *ngIf="package.bought" href="jacascript:void(0);">Added</a>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="packages == null">
                  <tr>
                    <td style="text-align: center;" colspan="4"> No Record Found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <app-pagination (pageChange)="pageChangeEvent($event)"></app-pagination>
          <app-spinner></app-spinner>
        </div>
      </div>
    </main>
  </div>
  
  