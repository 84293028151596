import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpRequestInterceptor } from './interceptor/http-request/http-request.interceptor';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { ReviewComponent } from './components/review/review.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NotificationComponent } from './components/notification/notification.component';
import { UpdatePasswordComponent } from './components/modals/update-password/update-password.component';
import { SharedModule } from './modules/shared/shared.module';
import { StepsCompletedComponent } from './components/reusable/steps-completed/steps-completed.component';
import { MyComponentComponent } from './components/my-component/my-component.component';
import { SharePostComponent } from './components/modals/share-post/share-post.component';
import { AddPostingDatetimeComponent } from './components/modals/add-posting-datetime/add-posting-datetime.component';
import { MediaItemComponent } from './components/modals/media-item/media-item.component';
import { EditMultipostComponent } from './components/modals/edit-multipost/edit-multipost.component';
import { CompleteUserInfoComponent } from './components/modals/complete-user-info/complete-user-info.component';
import { AllBlogsComponent } from './components/all-blogs/all-blogs.component';
import { AddNewBlogPostComponent } from './components/add-new-blog-post/add-new-blog-post.component';
import { AddBlogCategoryComponent } from './components/add-blog-category/add-blog-category.component';
import { AllBlogsCategoryComponent } from './components/all-blogs-category/all-blogs-category.component';
import { ContentEngineComponent } from './components/content-engine/content-engine.component';
import { AddContentEngineComponent } from './components/add-content-engine/add-content-engine.component';
import { PostsContentEngineComponent } from './components/posts-content-engine/posts-content-engine.component';
import { AddPostPackagesComponent } from './components/add-post-packages/add-post-packages.component';
import { BuyPackageComponent } from './components/buy-package/buy-package.component';
import { PostsComponent } from './components/posts/posts.component';
import { AddPostComponent } from './components/add-post/add-post.component';
import { MultipostComponent } from './components/multipost/multipost.component';
import { MyPackagesComponent } from './components/my-packages/my-packages.component';
import { AddMyPackagesComponent } from './components/add-my-packages/add-my-packages.component';
import { AddMediaComponent } from './components/add-media/add-media.component';
import { MediaComponent } from './components/media/media.component';
import { BuyWhiteLabelComponent } from './components/buy-white-label/buy-white-label.component';
import { WhiteLabelComponent } from './components/white-label/white-label.component';
import { CheckDomainAvailableComponent } from './components/check-domain-available/check-domain-available.component';
import { ScheduledPostsComponent } from './components/scheduled-posts/scheduled-posts.component';
import { FooterComponent } from './components/footer/footer.component';
import { TermComponent } from './components/term/term.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { BlogComponent } from './components/blog/blog.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { EncodedecodeComponent } from './components/modals/encodedecode/encodedecode.component';
import { BlogSettingsComponent } from './components/blog-settings/blog-settings.component';
import { ArchivedPostsComponent } from './components/archived-posts/archived-posts.component';
// import { RefundDetailComponent } from './components/modals/refund-detail/refund-detail.component';
// import { CardDetailComponent } from './components/reusable/card-detail/card-detail.component';
// import { PaypalDetailComponent } from './components/reusable/paypal-detail/paypal-detail.component';
// import { UpdateCardDetailComponent } from './components/update-card-detail/update-card-detail.component';
import { TagInputModule } from 'ngx-chips';
import { BlogCategoriesComponent } from './components/blog-categories/blog-categories.component';
import { ArchievesPostsComponent } from './components/archieves-posts/archieves-posts.component';
import { WhitelabelSettingComponent } from './components/modals/whitelabel-setting/whitelabel-setting.component';
//import { LogoComponent } from './components/logo/logo.component';


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    TopbarComponent,
    ReviewComponent,
    NotificationComponent,
    UpdatePasswordComponent,
    StepsCompletedComponent,
    MyComponentComponent,
    SharePostComponent,
    AddPostingDatetimeComponent,
    MediaItemComponent,
    EditMultipostComponent,
    CompleteUserInfoComponent,
    AddNewBlogPostComponent,
    AddBlogCategoryComponent,
    AddContentEngineComponent,
    PostsContentEngineComponent,
    AddPostPackagesComponent,
    BuyPackageComponent,
    MultipostComponent,
    AddMyPackagesComponent,
    ScheduledPostsComponent,
    FooterComponent,
    TermComponent,
    PrivacyComponent,
    BlogComponent,
    BlogDetailsComponent,
    EncodedecodeComponent,
    BlogSettingsComponent,
    ArchivedPostsComponent,
    BlogCategoriesComponent,
    ArchievesPostsComponent,
    WhitelabelSettingComponent,
    //LogoComponent,
    // RefundDetailComponent,
    // CardDetailComponent,
    // PaypalDetailComponent,
    // UpdateCardDetailComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    AngularEditorModule,
    TagInputModule, 
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
