import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
//import { AngularEditorConfig } from '@kolkov/angular-editor';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-post-packages',
  templateUrl: './add-post-packages.component.html',
  styleUrls: ['./add-post-packages.component.css']
})
export class AddPostPackagesComponent implements OnInit {

  constructor(
    private httpService: HttpService,
    private spinnerService: SpinnerService,
    public commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  // backend_url: string | ArrayBuffer | null = `${environment.backend_url}image_icon.jpg`;
  ngOnInit(): void {
    this.getPackagesforpost();
    let id = this.route.snapshot.params['id'];
    if (id !== undefined) {
      this.post.id = id;
      this.getPostInfo();
    }
  }

  Editor = ClassicEditor;
  // public config: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: true,
  //   height: '15rem',
  //   minHeight: '5rem',
  //   placeholder: 'Enter text here...',
  //   translate: 'no',
  //   customClasses: [
  //     {
  //       name: "quote",
  //       class: "quote",
  //     },
  //     {
  //       name: 'redText',
  //       class: 'redText'
  //     },
  //     {
  //       name: "titleText",
  //       class: "titleText",
  //       tag: "h1",
  //     },
  //   ]
  // }

  packages = null;
  search: string = "";
  date: string = "";
  post: any = {
    category: '',
  }
  formData = new FormData();
  media_image: any = null;

  selectedFile: File | null = null;
  mediaSrc: string | ArrayBuffer | null = '';
  backend_url: string | ArrayBuffer | null = `${environment.backend_url}image_icon.jpg`;


  getPackagesforpost = () => {
    this.spinnerService.showSpinner()
    const request = {};
    this.httpService.getRequestWithParameters("contentengine/contentengine-packages-posts", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner()
        if (response.status == 200) {
          this.packages = response.data.data
          console.log(this.packages)
        }
      }, (error) => {
        this.spinnerService.hideSpinner()
        this.spinnerService.errorSwal(error.error)
      })
  }

  capitalizeFirstLetter() {
    if (this.post.title && this.post.title.length > 0) {
      this.post.title = this.post.title.charAt(0).toUpperCase() + this.post.title.slice(1);
    }
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.selectedFile = file;
    this.formData.append("media_image", file);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.mediaSrc = e.target.result; 
    };
    reader.readAsDataURL(file);
  }

  addPackage(form: any) {
    form.submitted = true;
    if (form.valid) {
      let mediaObjArr = Object.keys(this.post)
      mediaObjArr.forEach((val: any, key) => {
        this.formData.append(mediaObjArr[key].toString(), this.post[val]);
      })
      this.spinnerService.showSpinner();
      this.httpService.postRequest(this.formData, "contentengine/add-package-post")
        .subscribe((res) => {
          this.spinnerService.hideSpinner();
          if (res.status == 200) {
            this.spinnerService.successSwal(res);
            this.router.navigateByUrl("/app/contentengine/post-package-items");
          }
        }, (error) => {
          this.formData = new FormData()
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }

  }

  getPostInfo = () => {
    this.spinnerService.showSpinner();
    this.httpService.getRequestWithParameters("contentengine/postinfo", { id: this.post.id })
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          this.post = response.data;
          const baseUrl = environment.backend_url; 
          this.mediaSrc = baseUrl + this.post.media.file_url;
          if(this.post.media.file_url){
            this.mediaSrc = baseUrl + this.post.media.file_url;
          } else {
            this.mediaSrc = baseUrl + 'image_icon.jpg';
          }
          this.post.category = response.data.package_posts.package.name
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      });
  }

  updatePackage() {
    this.spinnerService.showSpinner();

    const updatedPackageData = {
      title: this.post.title,
      content: this.post.content,
      category: this.post.category
    };

    if (this.selectedFile) {
      const formDataWithImage = new FormData();
      formDataWithImage.append('media_image', this.selectedFile);
      formDataWithImage.append('title', this.post.title);
      formDataWithImage.append('content', this.post.content);
      formDataWithImage.append('category', this.post.category);

      this.httpService.putRequest(formDataWithImage, `contentengine/update-package-post/${this.post.id}`)
        .subscribe(
          (res) => {
            this.spinnerService.hideSpinner();
            if (res.status === 200) {
              this.spinnerService.successSwal(res);
              this.router.navigateByUrl('/app/contentengine/post-package-items');
            }
          },
          (error) => {
            this.spinnerService.hideSpinner();
            this.spinnerService.errorSwal(error.error);
          }
        );
    } else {
      this.httpService.putRequest(updatedPackageData, `contentengine/update-package-post/${this.post.id}`)
        .subscribe(
          (res) => {
            this.spinnerService.hideSpinner();
            if (res.status === 200) {
              this.spinnerService.successSwal(res);
              this.router.navigateByUrl('/app/contentengine/post-package-items');
            }
          },
          (error) => {
            this.spinnerService.hideSpinner();
            this.spinnerService.errorSwal(error.error);
          }
        );
    }
  }

}
