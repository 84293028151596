<div class="main-wrapper">
    <main class="main users" id="skip-target">
      <div class="container-fluid">
        <div class="dsp-flx mb10">
          <div class="dsp-flx">
            <h2 class="main-title mr30">Archieved Posts</h2>
            <app-search (searchChange)="searchChangeEvent($event)"></app-search>
          </div>
          <!-- <a class="btn btn-primary" style="margin-left: 52%" routerLink="/app/blog/archieved-posts">Archieved Posts</a> -->
          <a class="btn btn-primary" routerLink="/app/blog/all-blogs">Back</a>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="users-table table-wrapper">
              <table class="dashboard-table">
                <thead>
                  <tr class="users-table-info">
                    <th width="20%">Title</th>
                    <th width="20%">Content</th>
                    <th width="20%">Category</th>
                    <th width="15%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let blog of blogs | paginate : config">
                    <td>
                      <div class="pages-table-img">
                        {{blog.title}}
                      </div>
                    </td>
                    <td>
                      <div class="pages-table-img" [innerHTML]="blog.content | slice:0:100"></div>
                    </td>
                    <td>
                      {{blog.category_title}}
                    </td>
                    <td>
                      <a class="btn btn-primary" href="javascript:void(0);" (click)="restoreBlog(blog.id)">Restore Post</a>
                      &nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <app-pagination (pageChange)="pageChangeEvent($event)"></app-pagination>
          <app-spinner></app-spinner>
        </div>
      </div>
    </main>
  </div>