import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buy-package',
  templateUrl: './buy-package.component.html',
  styleUrls: ['./buy-package.component.css']
})
export class BuyPackageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
