import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {

  user = {
    user_id: 0,
    password: "",
  }

  show_eye: boolean = false;
  show_confirm_eye: boolean = false;

  constructor(public activeModal: NgbActiveModal, private spinnerService: SpinnerService, private httpService: HttpService, private commonService: CommonService) { }

  ngOnInit(): void {
  }

  generatePassword = () => {
    this.user.password = this.commonService.generateRandomString(10)
  }

  updatePassword = async () => {

    this.spinnerService.showSpinner();
    this.httpService.postRequest(this.user, "password/updatepassword")
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          this.spinnerService.successSwal(response)
          this.activeModal.close();
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }

}
