import { Component, OnInit, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
//import { AddRemoveBusinessComponent } from '../modals/add-remove-business/add-remove-business.component';
import { AddPostingDatetimeComponent } from '../modals/add-posting-datetime/add-posting-datetime.component';
import { EditMultipostComponent } from '../modals/edit-multipost/edit-multipost.component';
import { NgbDate, NgbDateStruct, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-scheduled-posts',
  templateUrl: './scheduled-posts.component.html',
  styleUrls: ['./scheduled-posts.component.css']
})
export class ScheduledPostsComponent implements OnInit {

  backend_url: string | ArrayBuffer | null = `${environment.backend_url}`;
  environment = environment
  scheduledpost: any = {}
  posts: any = {
    businesspost: '',
  }
  multipost:any={
    businesspost: '',
  }
  search: string = "";
  config = { itemsPerPage: 5, currentPage: 1, totalItems: 0 };

  networks: any = [];

  post: any = {}
  posted: any = 0;
  show_filter = 0;
  constructor(private modalService: NgbModal, private spinnerService: SpinnerService, private httpService: HttpService, private route: ActivatedRoute) { }
  
  startDatePickerVisible = false;
  endDatePickerVisible: boolean = false;
  calanderinputdata: { date: NgbDateStruct, startdate: NgbDateStruct, enddate: NgbDateStruct, selectedDate: NgbDateStruct, selectedHour?: string, selectedMinute?: string, selectedrange?: string , searchedItem?: any} = { 
    date: this.getCurrentDate(),
    startdate: this.getCurrentDate(),
    enddate: this.getCurrentDate(),
    selectedDate: this.getCurrentDate(),
    selectedHour: '12', // Set the default value as a string
    selectedMinute: '00', 
    selectedrange: 'AM',
    searchedItem: '',
  };

  formattedStartDate: string = "";//this.formatStartDate();
  formattedEndDate: string = "";//this.formatEndDate();
  currentYear!: number;
  currentMonth!: number;

  selectedPostTab: number = 1;
  
  ngOnInit(): void {
    let post_id = this.route.snapshot.params['id'];
    if (post_id !== undefined) {
      this.post.id = post_id;
    }
    this.getCountOfpostsforBusiness();
    this.getScheduledItems();
    this.getNetworks();
  }

  formatStartDate(): string {
    return this.formattedStartDate = `${this.calanderinputdata.startdate.year}-${
        String(this.calanderinputdata.startdate.month).padStart(2, '0')
      }-${String(this.calanderinputdata.startdate.day).padStart(2, '0')}`;
  }

  formatEndDate(): string {
    return this.formattedEndDate = `${this.calanderinputdata.enddate.year}-${
      String(this.calanderinputdata.enddate.month).padStart(2, '0')
    }-${String(this.calanderinputdata.enddate.day).padStart(2, '0')}`;
  }

  getCurrentDate(): NgbDateStruct {
    const currentDate = new Date();
    //const nextDay = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
    const nextDay = new Date();
    return {
      year: nextDay.getFullYear(),
      month: nextDay.getMonth() + 1,
      day: nextDay.getDate(),
    };
  }

  // Additional method to get NgbDate from Date
  getNgbDateFromDate(date: Date): NgbDate {
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  onStartDateToggle() {
    this.startDatePickerVisible = !this.startDatePickerVisible;
  }
    // Method to toggle the visibility of the datepicker
  toggleEndDatePicker() {
    this.endDatePickerVisible = !this.endDatePickerVisible;
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    // Check if the click is outside the datepicker and close it if needed
    const isOutsideDatepicker = !(event.target as HTMLElement).closest('.datepicker-container');
    if (this.startDatePickerVisible && isOutsideDatepicker) {
      this.startDatePickerVisible = false;
      this.formatStartDate();
    }
    if (this.endDatePickerVisible && isOutsideDatepicker) {
      this.endDatePickerVisible = false;
      this.formatEndDate();
    }
  }

  onDateRangeChange(event: any): void {
    const selectedValue = event.target.value;
    const currentDate = this.getCurrentDate();
    // Update the start and end dates based on the selected date range
    switch (selectedValue) {
      case 'Today':
        this.calanderinputdata.startdate = this.calanderinputdata.enddate = this.getCurrentDate();
        break;
      case 'Yesterday':
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        this.calanderinputdata.startdate = this.calanderinputdata.enddate = this.getNgbDateFromDate(yesterday);
        break;
      case 'ThisWeek':
        const today = this.getCurrentDate();
        const currentDayOfWeek = new Date(today.year, today.month - 1, today.day).getDay();

        // Calculate the difference between the current day and Sunday (0 represents Sunday)
        const daysUntilSunday = (currentDayOfWeek === 0 ? 0 : 7) - currentDayOfWeek;

        // Set the start date to the current day minus the days until Sunday
        const startOfWeek = new Date(today.year, today.month - 1, today.day - daysUntilSunday);
        this.calanderinputdata.startdate = this.getNgbDateFromDate(startOfWeek);

        // Set the end date to the current day
        this.calanderinputdata.enddate = today;

        break;
      case 'LastWeek':
        const currentDayOfTheWeek = new Date(currentDate.year, currentDate.month - 1, currentDate.day).getDay();
      
        // Calculate the difference between the current day and Sunday (0 represents Sunday)
        const daysUntilTheSunday = (currentDayOfTheWeek === 0 ? 0 : 7) - currentDayOfTheWeek;
      
        // Set the end date to the current day minus the days until Sunday (end of last week)
        const endOfLastWeek = new Date(currentDate.year, currentDate.month - 1, currentDate.day - daysUntilTheSunday - 1);
      
        // Set the start date to the start of last week (Sunday)
        const startOfLastWeek = new Date(endOfLastWeek.getFullYear(), endOfLastWeek.getMonth(), endOfLastWeek.getDate() - 6);
      
        this.calanderinputdata.startdate = this.getNgbDateFromDate(startOfLastWeek);
        this.calanderinputdata.enddate = this.getNgbDateFromDate(endOfLastWeek);
        break;
      case 'ThisMonth':
        const firstDayOfThisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        this.calanderinputdata.startdate = this.getNgbDateFromDate(firstDayOfThisMonth);
        this.calanderinputdata.enddate = this.getCurrentDate();
        break;
      case 'Last30days':
        const last30Days = new Date();
        last30Days.setDate(last30Days.getDate() - 30);
        this.calanderinputdata.startdate = this.getNgbDateFromDate(last30Days);
        this.calanderinputdata.enddate = this.getCurrentDate();
        break;
      case 'LastMonth':
        const firstDayOfLastMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
        const lastDayOfLastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
        this.calanderinputdata.startdate = this.getNgbDateFromDate(firstDayOfLastMonth);
        this.calanderinputdata.enddate = this.getNgbDateFromDate(lastDayOfLastMonth);
        break;
      case 'NextMonth':
        const firstDayOfNextMonth = new Date(currentDate.year, currentDate.month, 1);
        const lastDayOfNextMonth = new Date(currentDate.year, currentDate.month + 1, 0);
        this.calanderinputdata.startdate = this.getNgbDateFromDate(firstDayOfNextMonth);
        this.calanderinputdata.enddate = this.getNgbDateFromDate(lastDayOfNextMonth);
        break;
      case 'Next30days':
        const next30Days = new Date(currentDate.year, currentDate.month - 1, currentDate.day);
        next30Days.setDate(next30Days.getDate() + 30);
        this.calanderinputdata.startdate = this.getCurrentDate();
        this.calanderinputdata.enddate = this.getNgbDateFromDate(next30Days);
        break;
      case 'NextWeek':
        const currentDayOfWeek1 = new Date(currentDate.year, currentDate.month - 1, currentDate.day).getDay();    
        // Calculate the difference between the current day and Sunday (0 represents Sunday)
        const daysUntilNextSunday = (currentDayOfWeek1 === 0 ? 7 : 7 - currentDayOfWeek1);
        // Set the start date to the current day plus the days until Sunday
        const startOfNextWeek = new Date(currentDate.year, currentDate.month - 1, currentDate.day + daysUntilNextSunday);
        this.calanderinputdata.startdate = this.getNgbDateFromDate(startOfNextWeek);
        // Set the end date to the end of next week (Saturday)
        const endOfNextWeek = new Date(startOfNextWeek.getFullYear(), startOfNextWeek.getMonth(), startOfNextWeek.getDate() + 6);
        this.calanderinputdata.enddate = this.getNgbDateFromDate(endOfNextWeek);
        break;
      case 'Next3Months':
        const startOfNext3Months = new Date(currentDate.year, currentDate.month + 2, 1);
        const endOfNext3Months = new Date(currentDate.year, currentDate.month + 3, 0);
        this.calanderinputdata.startdate = this.getNgbDateFromDate(startOfNext3Months);
        this.calanderinputdata.enddate = this.getNgbDateFromDate(endOfNext3Months);
        break;      
      default:
        // Handle default case or any additional logic
        break;
    }
  
    // Update the formatted start and end dates
    this.formatStartDate();
    this.formatEndDate();
  }

  applyDateRangeFilter() {
    if (this.calanderinputdata.startdate && this.calanderinputdata.enddate) {
      this.show_filter = 1;
    } else {
      this.show_filter = 0;
    }
    this.getRelatedPostsList();
  }

  reset_filter(){
    this.show_filter = 0;
    this.formattedStartDate = "";
    this.formattedEndDate = "";
    const daterangeElement = document.querySelector('#daterange') as HTMLSelectElement | null;
    if (daterangeElement) {
      daterangeElement.value = '';
    }
    this.getRelatedPostsList();
  }

  // Reset function
  resetConfig() {
    this.config = { itemsPerPage: 5, currentPage: 1, totalItems: 0 };
  }

  getRelatedPostsList = () => {
    this.getCountOfpostsforBusiness();
    if(this.selectedPostTab == 1){
      this.posted = 0;
      this.getScheduledItems();
    } else if(this.selectedPostTab == 2){
      this.posted = 1;
      this.getPostedItems();
    } else if(this.selectedPostTab == 3){
      this.posted = 0;
      this.getRejectedItems();
    }
  }

  ScheduledItemscount = 0;
  postedItemscount = 0;
  rejectedItemscount = 0;

  totalSecheduledItems = 0;
  totalPostedItems = 0;
  totalRejectededItems = 0;
  getCountOfpostsforBusiness = () => {
    this.spinnerService.showSpinner()
    
    const request = { 
      page: this.config.currentPage, 
      limit: this.config.itemsPerPage, 
      search: this.calanderinputdata.searchedItem,
      startdate: this.formattedStartDate,
      enddate: this.formattedEndDate,
      business_id: this.post.id,
      posted: this.posted, 
      show_filter:this.show_filter
    };

    this.httpService.getRequestWithParameters("business/businesses-related-scheduledpostscount", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner()
          if (response.status == 200) {
            this.totalSecheduledItems = response.data.total
            this.totalPostedItems = response.data.posted_total
            this.totalRejectededItems = response.data.rejected_total

            this.ScheduledItemscount = response.data.total_found
            this.postedItemscount = response.data.posted_total_found
            this.rejectedItemscount = response.data.rejected_total_found
          }
      }, (error) => {
        this.spinnerService.hideSpinner()
        this.spinnerService.errorSwal(error.error)
      })
  }

  ScheduledItems: any = {}
  getScheduledItems = () => {
    this.spinnerService.showSpinner()
    const request = { 
      page: this.config.currentPage, 
      limit: this.config.itemsPerPage, 
      search: this.calanderinputdata.searchedItem,
      startdate: this.formattedStartDate,
      enddate: this.formattedEndDate,
      business_id: this.post.id,
      posted: this.posted, 
      show_filter:this.show_filter
    };

    this.httpService.getRequestWithParameters("business/businesses-related-scheduleditems", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner()
          if (response.status == 200) {
            this.ScheduledItems = response.data.data
            this.ScheduledItemscount = response.data.total
            this.config.totalItems = response.data.total
          }
      }, (error) => {
        this.spinnerService.hideSpinner()
        this.spinnerService.errorSwal(error.error)
      })
  }

  postedItems: any = {}
  getPostedItems = () => {
    this.spinnerService.showSpinner()
    const request = { 
      page: this.config.currentPage, 
      limit: this.config.itemsPerPage, 
      search: this.calanderinputdata.searchedItem,
      startdate: this.formattedStartDate,
      enddate: this.formattedEndDate,
      business_id: this.post.id,
      posted: this.posted, 
      show_filter:this.show_filter
    };

    this.httpService.getRequestWithParameters("business/businesses-related-posteditems", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner()
        
          if (response.status == 200) {
            this.postedItems = response.data.data
            console.log(this.postedItems)
            this.postedItemscount = response.data.total
            this.config.totalItems = response.data.total
          }

      }, (error) => {
        this.spinnerService.hideSpinner()
        this.spinnerService.errorSwal(error.error)
      })
  }

  rejectedItems: any = {}
  getRejectedItems = () => {
    this.spinnerService.showSpinner()
    const request = { 
      page: this.config.currentPage, 
      limit: this.config.itemsPerPage, 
      search: this.calanderinputdata.searchedItem,
      startdate: this.formattedStartDate,
      enddate: this.formattedEndDate,
      business_id: this.post.id,
      posted: this.posted,
      show_filter:this.show_filter 
    };

    this.httpService.getRequestWithParameters("business/businesses-related-rejecteditems", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner()
        
          if (response.status == 200) {
            this.rejectedItems = response.data.data
            console.log("rejecteditems")
            console.log(this.rejectedItems)
            this.rejectedItemscount = response.data.total
            this.config.totalItems = response.data.total
          }

      }, (error) => {
        this.spinnerService.hideSpinner()
        this.spinnerService.errorSwal(error.error)
      })
  }

  tabTitle: string = "Posting Schedule";
  showTab(tabNumber: number, tabTitle: string): void {
      this.resetConfig();
      this.tabTitle = tabTitle;
      this.selectedPostTab = tabNumber;
      this.getRelatedPostsList();
  }

  pageChangeEvent = (eventData: { page: number }) => {
    this.config.currentPage = eventData.page
    if(this.selectedPostTab == 1){
      this.getScheduledItems();
    } else if(this.selectedPostTab == 2) {
      this.getPostedItems();
    } else if(this.selectedPostTab == 3) {
      this.getRejectedItems();
    }
  }

  async deletePost(id: number) {
    let result = await this.spinnerService.confirmSwal(`You want to remove this post from posting Schedule`);
    if (result.isConfirmed) {
      const request = { id: id };
      this.httpService.putRequest(request, "business/delete-scheduled-post")
        .subscribe((response) => {
          this.spinnerService.hideSpinner();
          if (response.status == 200) {
            this.spinnerService.successSwal(response);
            this.getCountOfpostsforBusiness();
            this.getScheduledItems();
          }
        }, (error) => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }
  }

  editpackage(postId: number, postContent: any, index: number, media: any, mediaId: any) {

    const modalRef = this.modalService.open(EditMultipostComponent,{ size: 'lg' });
    modalRef.componentInstance.modalinputdata = { postId: postId, postContent: postContent, mediaId: mediaId, media: media };


    modalRef.result.then(
      (result) => {

        if (!this.ScheduledItems[index].file_url) {
          this.ScheduledItems[index].file_url = "";
        }

        this.ScheduledItems[index].content = result.modifiedText;
        this.ScheduledItems[index].modifiedContent = result.modifiedText;
        this.ScheduledItems[index].file_url = result.modifiedMediaName;
        this.ScheduledItems[index].modifiedMedia = result.modifiedMedia;

        const request = this.ScheduledItems[index]
        
        this.spinnerService.showSpinner();
        this.httpService.postRequest(request, "business/update-scheduled-post")
          .subscribe((response) => {
            this.spinnerService.hideSpinner();
            if (response.status == 200) {
              this.spinnerService.successSwal(response);
              this.getScheduledItems();
            }
          }, (error) => {
            this.spinnerService.hideSpinner();
            this.spinnerService.errorSwal(error.error);
          });

      },
      (reason) => {
        console.log('Modal dismissed with reason:', reason);
      }
    );

  }

  adddatetime(postId: number,index: number) {
    const modalRef = this.modalService.open(AddPostingDatetimeComponent);
    modalRef.componentInstance.modalinputdata = { id: postId };

    modalRef.result.then(
      (result) => {
        this.ScheduledItems[index].schedule_to = result.schedule_to;
        const request = this.ScheduledItems[index]
        this.spinnerService.showSpinner();
        this.httpService.postRequest(request, "business/reschedule-scheduled-post")
          .subscribe((response) => {
            this.spinnerService.hideSpinner();
            if (response.status == 200) {
              this.spinnerService.successSwal(response);
              this.getScheduledItems();
            }
          }, (error) => {
            this.spinnerService.hideSpinner();
            this.spinnerService.errorSwal(error.error);
          });
      },
      (reason) => {
        console.log('Modal dismissed with reason:', reason);
      }
    );

  }

  async delete_business_post(id: number){
    console.log(id)
        let result = await this.spinnerService.confirmSwal(`You want to delete this post`);
        if (result.isConfirmed) {
          this.spinnerService.showSpinner();
          const request = { id: id};
          this.httpService.putRequest(request, "business/delete-businesspost")
            .subscribe((response) => {
              this.spinnerService.hideSpinner();
              if (response.status == 200) {
                this.spinnerService.successSwal(response);
                window.location.reload()
              }
            }, (error) => {
              this.spinnerService.hideSpinner();
              this.spinnerService.errorSwal(error.error);
            })
        }
    
      }

  getNetworks = () => {
    this.spinnerService.showSpinner();
    this.httpService.getRequestWithParameters("network/networks", { id:0 })
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          this.networks = response.data.data
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }

  isNetworkConnected(businessNetwork: any[], targetNetworkId: number): boolean {
    return businessNetwork.some(network => network.network_id === targetNetworkId);
  }

}
