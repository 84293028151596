<!-- <footer>
    <div *ngIf="isLoginPage()" class="text-center ng-scope">
        <span>
            <a routerLink="privacy-policy" style="color: blue;">Privacy Policy</a> |
            <a routerLink="terms-and-conditions" style="color: blue;">Terms of Service</a> |
            <a href="mailto:{{environment.supportEmail}}" target="_blank">{{environment.supportEmail}}</a>
        </span>
    </div>

    <div *ngIf="!isLoginPage()" class="text-center ng-scope">
        <span>
            <a routerLink="privacy-policy" style="color: blue;">Privacy Policy</a> |
            <a routerLink="terms-and-conditions" style="color: blue;">Terms of Service</a> |
            <ng-container>
              <a (click)="checkDomain($event)" class="clickable-link">Blog</a> |
            </ng-container>                     
            <a href="mailto:{{environment.supportEmail}}" target="_blank">{{environment.supportEmail}}</a> 

        </span>
    </div>
</footer> -->

<!-- <footer *ngIf="displayFooter"> -->
<footer>
    <div class="text-center ng-scope">
        <span>
            <a *ngIf="!whiteLabel.isWhiteLabel" routerLink="pub/privacy-policy" style="color: blue;">Privacy Policy |
            </a><a *ngIf="!whiteLabel.isWhiteLabel" routerLink="pub/terms-and-conditions" style="color: blue;">Terms of Service | </a>
            <ng-container>
                <a *ngIf="!isLoginPage()" (click)="checkDomain($event)" class="clickable-link"> Blog | </a>
            </ng-container> 
            <a href="mailto:{{whiteLabel.email}}" target="_blank">{{whiteLabel.email}}</a>
        </span>
    </div>
</footer>

