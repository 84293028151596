
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { CommonService } from 'src/app/services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';


@Component({
  selector: 'app-complete-user-info',
  templateUrl: './complete-user-info.component.html',
  styleUrls: ['./complete-user-info.component.css']
})
export class CompleteUserInfoComponent implements OnInit {

  user: any = {}

  sid_type: string = "password";
  token_type: string = "password";
  selectedPostTab: number = 1;
  countries = null;
  formData = new FormData();

  timeRanges: any[] = [{
    id: 0,
    fromHour: '01',
    fromMinute: '00',
    fromAmPm: 'AM',
    toHour: '12',
    toMinute: '00',
    toAmPm: 'PM'
  }];

  id: number = 1;
  fromHour: string = '01';
  fromMinute: string = '00';
  fromAmPm: string = 'AM';
  toHour: string = '12';
  toMinute: string = '00';
  toAmPm: string = 'PM';

  hours: string[] = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
  minutes: string[] = ['00', '15', '30', '45'];


  constructor(
    public activeModal: NgbActiveModal,
    private spinnerService: SpinnerService,
    private httpService: HttpService,
    public commonService: CommonService,
    private modalService: NgbModal,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getProfile();
    this.getCountries();
  }

  getCountries = () => {

    this.spinnerService.showSpinner();
    this.httpService.getRequest("country/countries").
      subscribe((res) => {
        this.spinnerService.hideSpinner();
        if (res.status === 200) {
          this.countries = res.data;
        } else {
          this.spinnerService.errorSwal(res);
        }
      }, error => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }

  getProfile = () => {
    this.spinnerService.showSpinner();
    this.httpService.getRequest("user/profile").subscribe((res) => {
      this.spinnerService.hideSpinner();
      if (res.status === 200) {
        this.user = res.data;
        console.log(this.user);
        // this.selectedDays = this.user.day_names.split(',');
        // this.timeRanges = [];

        // const fromTime = this.user.from_time;
        // const toTime = this.user.to_time;
        // const fromTimePeriod = this.user.from_time_period;
        // const toTimePeriod = this.user.to_time_period;

        // const timeRange = {
        //   fromHour: fromTime.split(':')[0],
        //   fromMinute: fromTime.split(':')[1],
        //   fromAmPm: fromTimePeriod,
        //   toHour: toTime.split(':')[0],
        //   toMinute: toTime.split(':')[1],
        //   toAmPm: toTimePeriod
        // };
        // this.timeRanges.push(timeRange);

        // console.log(this.selectedDays);
        // console.log(this.timeRanges);

      } else {
        this.spinnerService.errorSwal(res);
      }
    }, error => {
      this.spinnerService.hideSpinner();
      this.spinnerService.errorSwal(error.error);
    });
  }



  isTimeValid(range: any): boolean {
    const fromTime = this.convertTo24HourFormat(
      `${range.fromHour}:${range.fromMinute} ${range.fromAmPm}`
    );
    const toTime = this.convertTo24HourFormat(
      `${range.toHour}:${range.toMinute} ${range.toAmPm}`
    );

    return fromTime < toTime;
  }

  convertTo24HourFormat(timeString: string): number {
    const time = new Date(`2000-01-01 ${timeString}`).getTime();
    return isNaN(time) ? -1 : time;
  }

  updateProfile() {
    const selectedDays = this.selectedDays;
    let fromTimes = '';
    let toTimes = '';
    let toTimePeriod = '';
    let fromTimePeriod = '';

    for (const range of this.timeRanges) {
      const { fromHour, fromMinute, fromAmPm, toHour, toMinute, toAmPm } = range;

      fromTimes += `${fromHour}:${fromMinute},`;
      toTimes += `${toHour}:${toMinute},`;
      toTimePeriod += `${toAmPm}`;
      fromTimePeriod += `${fromAmPm}`
    }
    fromTimes = fromTimes.slice(0, -1);
    toTimes = toTimes.slice(0, -1);

    const updatedProfile = {
      name: this.user.name,
      email: this.user.email,
      country: this.user.country,
      plan: this.user.paln,
      address: this.user.address,
      state: this.user.state,
      city: this.user.city,
      zip: this.user.zipcode,
      phone: this.user.mobile,

      day_names: selectedDays.join(','),
      from_time: fromTimes,
      to_time: toTimes,
      from_time_period: fromTimePeriod,
      to_time_period: toTimePeriod

    };

    if(this.user.address == "" || this.user.state == "" || this.user.city == "" || this.user.zipcode == ""){

    } else {
    this.httpService.putRequest(this.user, "user/updateprofile")
      .subscribe(
        (res) => {
          this.spinnerService.hideSpinner();
          if (res.status === 200) {
            this.spinnerService.successSwal(res);
            this.activeModal.close();
          }
        },
        (error) => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        }
      );
    }
  }

  showTab(tabNumber: number): void {
    this.selectedPostTab = tabNumber;
  }

  addNotification(form: any) {
    form.submitted = true;
    if (form.valid) {
      let mediaObjArr = Object.keys(this.user)
      mediaObjArr.forEach((val: any, key) => {
        this.formData.append(mediaObjArr[key].toString(), this.user[val]);
      })

    }
    console.log(this.user)
  }

  selectedDays: string[] = [];

  onCheckboxChange(day: string) {
    if (this.selectedDays.includes(day)) {
      this.selectedDays = this.selectedDays.filter(d => d !== day);
    } else {
      this.selectedDays.push(day);
    }
  }
}
