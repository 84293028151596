<div class="_blog_page_warp">
    <div class="header" [ngStyle]="{
        'background-color': blog?.blog_settings?.headerSetting?.backgroundcolor || '#00acd8',
        'background-image': blog?.blog_settings?.headerSetting?.media_image ? 'url(' + (baseUrl + blog.blog_settings.headerSetting.media_image) + ')' : '',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'width': blog?.blog_settings?.headerSetting?.width || '100%',
        'height': blog?.blog_settings?.headerSetting?.height || '200px',
        'color': blog?.blog_settings?.headerSetting?.color || '#fff',
        'display': 'flex;',
        'justify-content': 'space-between',
        'align-items': 'center',
        'padding': '0 20px',
        'margin-bottom': '40px'
    }">
    <div class="container">
      <h1 class="title" >{{ blog?.blog_settings?.headerSetting?.headerTitle || 'Blog Posts' }}</h1>
      <div>
        <a href="{{this.blog?.blog_settings?.headerSetting?.email}}" style="color: #000; text-decoration: none; margin-right: 20px;">Email</a>
        <span style="color: #000; margin-right: 20px;">|</span>
        <span style="color: #000; margin-right: 20px;">{{this.blog?.blog_settings?.headerSetting?.contact}}</span>
      </div>
      </div>
    </div>
  
  
  <div class="container">
    <div class="row" style="justify-content: space-between;">
      <!-- Left side: Blogs -->
      <div class="col-md-8">
        <ng-container *ngIf="blogs.length > 0; else noBlogs">
          <div class="row">
            <div *ngFor="let blogs of blogs | paginate : config" class="col-md-3 mb-3">
              <div class="card _blog_list"> <!-- Added padding -->
                <div class="blog-images">
                  <div class="img">
                    <a [routerLink]="'/blog/' + blogs.id">
                      <img [src]="blogs.mediaSrc" alt="Blog Image" class="card-img-top">
                    </a>
                  </div>
                </div>
                <div class="card-body">
                  <a [routerLink]="'/blog/' + blogs.id" style="text-decoration: none; color: #00acd8;">
                    <h6 class="card-title">{{ blogs.title }}</h6>
                  </a>
                  <div [innerHTML]="truncateContent(blogs.content)" style="font-size:15px;"></div>
                </div>
                <a [routerLink]="'/blog/' + blogs.id"  class="btn btn-primary _blog_btn" [ngStyle]="getButtonStyle()"
                  (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
                  {{ blog?.blog_settings?.businessSetting?.buttonText }}
                </a>        
                <div class="card-footer">
                  <small class="text-muted">{{ blogs.created | date:'dd MMMM yyyy' }}</small>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #noBlogs>
          <div class="col-md-12">
            <div class="alert alert-info" role="alert">
              No blogs found.
            </div>
          </div>
        </ng-template>
        <app-pagination (pageChange)="pageChangeEvent($event)"></app-pagination>
      </div>
  
  <!-- Right side: Search -->
  <div class="col-md-3 pl0 pr0">
    <div class="container">
      <app-search (searchChange)="searchChangeEvent($event)"></app-search>
      <div class="_recent_bx">
        <button type="button" class="btn btn-primary"
          [ngStyle]="{'color': blog?.blog_settings?.businessSetting?.buttonTextColor, 'background-color': blog?.blog_settings?.businessSetting?.buttonBackgroundColor}"
          [class.active]="activeButton === 'recent'" (click)="toggleActive('recent')">Recent</button>
        <button type="button" class="btn btn-primary"
          [ngStyle]="{'color': blog?.blog_settings?.businessSetting?.buttonTextColor, 'background-color': blog?.blog_settings?.businessSetting?.buttonBackgroundColor}"
          [class.active]="activeButton === 'popular'" (click)="toggleActive('popular')"
          style="margin-left: 10px;">Popular</button>
        <div class="row" *ngIf="activeButton === 'recent'">
          <div class="col-12">
            <ul class="list-unstyled">
              <li *ngFor="let blogItem of blogs.slice(0, blog?.blog_settings?.sidebarSetting?.RecentPostCount)">
                <a (click)="onclickopenpost(blogItem.id)">
                  <span>{{ blogItem.title }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row" *ngIf="activeButton === 'popular'">
          <div class="col-12">
            <!-- Add content for the 'popular' section here if needed -->
          </div>
        </div>
      </div>
    </div>
  </div>
  
      <app-spinner></app-spinner>
    </div>
  </div>
  </div>