import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-archived-posts',
  templateUrl: './archived-posts.component.html',
  styleUrls: ['./archived-posts.component.css']
})
export class ArchivedPostsComponent implements OnInit {

  environment = environment
  blogs: any[] = [];
  postcount = null;
  search: string = "";
  config = { itemsPerPage: 10, currentPage: 1, totalItems: 0 };
  packagepostcount = null;
  date: string = "";

  constructor(private modalService: NgbModal, private spinnerService: SpinnerService, private httpService: HttpService) { }

  ngOnInit(): void {
    this.getBlogs();
  }

  searchChangeEvent = (eventData: { search: string }) => {
    this.search = eventData.search
    this.getBlogs()
  }

  pageChangeEvent = (eventData: { page: number }) => {
    this.config.currentPage = eventData.page
    this.getBlogs()
  }

  getBlogs = () => {
    this.spinnerService.showSpinner();
    const request = { page: this.config.currentPage, limit: this.config.itemsPerPage, search: this.search };
    this.httpService.getRequestWithParameters("blog/all-archieved-blogs", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          this.blogs = response.data.data.map((blog: any) => {
            // Truncate content at the first full stop
            const index = blog.content.indexOf('.');
            blog.content = index !== -1 ? blog.content.slice(0, index + 1) : blog.content;
            return blog;
          });
          console.log(this.blogs)
          this.config.totalItems = response.data.total;
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      });
  }
  

  async deleteBlog(id: number) {
    let result = await this.spinnerService.confirmSwal(`You want to delete this blog post`);
    if (result.isConfirmed) {
      const request = { id: id };
      this.httpService.putRequest(request, "blog/delete-blog")
        .subscribe((response) => {
          this.spinnerService.hideSpinner();
          if (response.status == 200) {
            this.spinnerService.successSwal(response);
            this.getBlogs();
          }
        }, (error) => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }
  }

  async archieveBlog(id: number) {
    let result = await this.spinnerService.confirmSwal(`You want to delete this blog post`);
    if (result.isConfirmed) {
      const request = { id: id };
      this.httpService.putRequest(request, "blog/archieve-blog")
        .subscribe((response) => {
          this.spinnerService.hideSpinner();
          if (response.status == 200) {
            this.spinnerService.successSwal(response);
            this.getBlogs();
          }
        }, (error) => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }
  }

  async restoreBlog(id: number) {
    let result = await this.spinnerService.confirmSwal(`You want to restore this blog post`);
    if (result.isConfirmed) {
      const request = { id: id };
      this.httpService.putRequest(request, "blog/restore-blog")
        .subscribe((response) => {
          this.spinnerService.hideSpinner();
          if (response.status == 200) {
            this.spinnerService.successSwal(response);
            this.getBlogs();
          }
        }, (error) => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }
  }

}
