<div class="main-wrapper">
  <main class="main users" id="skip-target">
    <div class="container-fluid">
      <div class="dsp-flx mb10">
        <div class="dsp-flx">
          <h2 class="main-title mr30">Post Packages</h2>
          <app-search (searchChange)="searchChangeEvent($event)"></app-search>
        </div>
        <a class="btn btn-primary" routerLink="/app/contentengine/add-post-package-items">Add New</a>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="users-table table-wrapper">
            <table class="dashboard-table">
              <thead>
                <tr class="users-table-info">
                  <th width="20%">Title</th>
                  <th width="20%">Description</th>
                  <th width="10%">Package</th>
                  <th width="15%">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="posts && posts.length == 0">
                  <td colspan="6" style="text-align: center;">
                    No records found
                  </td>
                </tr>
                <tr *ngFor="let post of posts | paginate : config">
                  <td>
                    <div class="pages-table-img">
                      {{post.name}}
                    </div>
                  </td>
                  <td>
                    <div class="pages-table-img" [innerHTML]="post.description"></div>
                  </td>
                  <td>
                    {{post.package_name}}
                  </td>
                  <td>
                    <a class="btn btn-primary"
                      routerLink="/app/contentengine/edit-post-package-items/{{ post.id }}">Edit</a> &nbsp;
                    <a class="btn btn-primary" href="javascript:void(0);" (click)="deletePost(post.id)">Delete</a>
                    &nbsp;
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="posts == null">
                <tr>
                  <td style="text-align: center;" colspan="4"> No Record Found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <app-pagination (pageChange)="pageChangeEvent($event)"></app-pagination>
        <app-spinner></app-spinner>
      </div>
    </div>
  </main>
</div>