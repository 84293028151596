import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';
import { EventSourcePolyfill } from 'ng-event-source';

@Injectable({
  providedIn: 'root'
})
export class EventSourceService {

  constructor(private commonService: CommonService) { }

  connect = (url: string) => {

    let tok = this.commonService.getToken();

    let headers = { headers: { 'Authorization': `Bearer ${tok}`},heartbeatTimeout:60000 }

    const es = new EventSourcePolyfill(`${environment.backend_url}${url}`, headers);
    return es
  }

}
