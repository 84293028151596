<h1>Terms of Service</h1>
<p>
By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trade mark law.
</p>

<p>
<b>Intellectual Property Rights</b><br>
All copyrights, trademarks, patents and other intellectual property rights in and on our website and all content and software located on the site shall remain the sole property of our company or its licensors. The use of our trademarks, content and intellectual property is forbidden without the express written consent from the owner of this website.
</p>

<p>
<b>You must not:</b><br>
* Republish material from our website without prior written consent. * Sell or rent material from our website. * Reproduce, duplicate, create derivative, copy or otherwise exploit material on our website for any purpose. * Redistribute any content from our website, including onto another website.
</p>

<p>
<b>Acceptable Use</b><br>
You agree to use our website only for lawful purposes, and in a way that does not infringe the rights of, restrict or inhibit anyone else’s use and enjoyment of the website. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content or disrupting the normal flow of dialogue within our website.<br>
You must not use our website to send unsolicited commercial communications. You must not use the content on our website for any marketing related purpose without our express written consent.
</p>

<p>
<b>Restricted Access</b><br>
We may in the future need to restrict access to parts (or all) of our website and reserve full rights to do so. If, at any point, we provide you with a username and password for you to access restricted areas of our website, you must ensure that both your username and password are kept confidential.
</p>

<p>
<b>Revisions</b><br>
The owner of this website may change these terms from time to time and so you should check these terms regularly. Your continued use of our website will be deemed acceptance of the updated or amended terms. If you do not agree to the changes, you should cease using our website immediately.
If any of these terms are determined to be illegal, invalid or otherwise unenforceable, it shall be severed and deleted from these terms and the remaining terms shall survive and continue to be binding and enforceable.
</p>

<p>
<b>Limitation of Liability</b><br>
THE MATERIALS AT THIS SITE ARE PROVIDED "AS IS" WITHOUT ANY EXPRESS OR IMPLIED WARRANTY OF ANY KIND INCLUDING WARRANTIES OF MERCHANTABILITY, NONINFRINGEMENT OF INTELLECTUAL PROPERTY, OR FITNESS FOR ANY PARTICULAR PURPOSE. IN NO EVENT SHALL THE OWNER OF THIS WEBSITE OR ITS AGENTS OR OFFICERS BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION, INJURY OR DEATH) ARISING OUT OF THE USE OF OR INABILITY TO USE THE MATERIALS, EVEN IF THE OWNER OF THIS WEBSITE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES.
</p>