import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { constants } from 'src/app/constant';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  environment = environment
  constant = constants
  show_eye: boolean = false;
  userModel = {
    email: "",
    password: ""
  }
  userEmail: string = "";

  public notification = new EventEmitter<object>();

  constructor(public commonService: CommonService, private httpService: HttpService, private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    const email = sessionStorage.getItem("forgotEmail");
    if (email != undefined) {
      this.userEmail = email
    }
    sessionStorage.removeItem("forgotEmail")
  }

  login = (form: FormGroup) => {

    if (this.commonService.checkFormValid(form)) {

      this.spinnerService.showSpinner();
      this.httpService
        .postRequest(this.userModel, "app/login").
        subscribe((res) => {
          this.spinnerService.hideSpinner();
          if (res.status === 200) {
            let user_data = JSON.stringify({
              data: res.data,
            });
            this.notification.emit({ notification: res.data.notification })
            localStorage.setItem("user", user_data);
            this.commonService.navigateTo("dashboard"); // redirect to dashboard
            this.commonService.tab.emit({
              activeTab: "dashboard",
              subActiveTab: ""
            })
          } else {
            this.spinnerService.errorSwal(res);
          }
        }, error => {
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }

  }

}

