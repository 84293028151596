import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
//import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-add-new-blog-post',
  templateUrl: './add-new-blog-post.component.html',
  styleUrls: ['./add-new-blog-post.component.css']
})
export class AddNewBlogPostComponent implements OnInit {

  constructor(
    private httpService: HttpService,
    private spinnerService: SpinnerService,
    public commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  formData = new FormData();
  media_image: any = null;
  contentGenerated = false;

  selectedFile: File | null = null;
  mediaSrc: string | ArrayBuffer | null = '';
  backend_url: string | ArrayBuffer | null = `${environment.backend_url}image_icon.jpg`;

  items = ['Pizza', 'Pasta', 'Parmesan'];

  selectedPostTab: number = 1;
  showPostSection = false;
  showMessage: boolean = false;
  // btags :any = {};
  blog: any = {
    category: '',
    title: '',
    content: '',
    tags: ''
  }
  blogs: any = {}
  ngOnInit(): void {
    this.getCategories()
    let id = this.route.snapshot.params['id'];
    if (id !== undefined) {
      this.blog.id = id;
      this.getBlogInfo();
    }
  }

  Editor = ClassicEditor;
  // public config: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: true,
  //   height: '15rem',
  //   minHeight: '5rem',
  //   placeholder: 'Enter text here...',
  //   translate: 'no',
  //   customClasses: [
  //     {
  //       name: "quote",
  //       class: "quote",
  //     },
  //     {
  //       name: 'redText',
  //       class: 'redText'
  //     },
  //     {
  //       name: "titleText",
  //       class: "titleText",
  //       tag: "h1",
  //     },
  //   ]
  // }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.selectedFile = file;
    this.formData.append("media_image", file);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.mediaSrc = e.target.result;
    };
    reader.readAsDataURL(file);
  }


  validateContentNotEmpty(content: string): boolean {
    return content.trim().length > 0;
  }

  onInput() {
    if (this.blog.title) {
      this.showMessage = false;
    }
  }

  addBlogpost(form: any) {
    form.submitted = true;
    if (!this.media_image) {
      // If no image selected, set an error message
      form.controls['media_image'].setErrors({ 'required': true });
      return; // Stop further execution
    }

    if (!this.validateContentNotEmpty(this.blog.content)) {
      form.controls['content'].setErrors({ 'required': true });
    }
    if (form.valid) {
      // Ensure this.blog.tags is a string before splitting
      if (typeof this.blog.tags !== 'string') {
        // If it's not a string, convert it to a string
        this.blog.tags = this.blog.tags.map((tag: any) => tag.value).join(',');
      }
  
      // Now that this.blog.tags is guaranteed to be a string, split it
      this.blog.tags = this.blog.tags.split(',').map((tag: string) => tag.trim());
  
      let mediaObjArr = Object.keys(this.blog);
      mediaObjArr.forEach((val: any, key) => {
        this.formData.append(mediaObjArr[key].toString(), this.blog[val]);
      });
      console.log(this.blog)
      // Now, you can send the updated blog object with transformed tags to the backend
      // this.spinnerService.showSpinner();
      this.httpService.postRequest(this.formData, "blog/add-blog")
        .subscribe((res) => {
          this.spinnerService.hideSpinner();
          if (res.status == 200) {
            this.spinnerService.successSwal(res);
            this.router.navigateByUrl("/app/blog/all-blogs");
          }
        }, (error) => {
          this.formData = new FormData()
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }
}


  getBlogInfo = () => {
    this.spinnerService.showSpinner();
    this.httpService.getRequestWithParameters("blog/bloginfo", { id: this.blog.id })
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          this.blog = response.data;
          this.blog.tags = this.blog.tags.split(',').map((tag:any) => tag.trim());
          // console.log(this.tags)
          const baseUrl = environment.backend_url;
          if(this.blog.media.file_url){
            this.mediaSrc = baseUrl + this.blog.media.file_url;
          } else {
            this.mediaSrc = baseUrl + 'image_icon.jpg';
          }
          this.blog.category = response.data.blog_post_category.blog_category.title;
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      });
  }

  tagValues: string[] = []; // Array to hold tag values


  updateBlog() {
    this.spinnerService.showSpinner();

    // Extract tag values that are strings
    this.tagValues = this.blog.tags.map((tag: any) => typeof tag === 'string' ? tag : tag.value);

    const updatedBlogData = {
      title: this.blog.title,
      content: this.blog.content,
      category: this.blog.category,
      tags: this.tagValues.join(', ')
    };
    // Check if a file is selected
    if (this.selectedFile) {
      const formDataWithImage = new FormData();
      formDataWithImage.append('media_image', this.selectedFile);
      formDataWithImage.append('title', this.blog.title);
      formDataWithImage.append('content', this.blog.content);
      formDataWithImage.append('category', this.blog.category);
      formDataWithImage.append('tags', updatedBlogData.tags); // Join the array elements into a single string
  
      this.httpService.putRequest(formDataWithImage, `blog/update-blog/${this.blog.id}`)
        .subscribe(
          (res) => {
            this.spinnerService.hideSpinner();
            if (res.status === 200) {
              this.spinnerService.successSwal(res);
              this.router.navigateByUrl('/app/blog/all-blogs');
            }
          },
          (error) => {
            this.spinnerService.hideSpinner();
            this.spinnerService.errorSwal(error.error);
          }
        );
    } else {
      // If no file selected, send data without FormData
      this.httpService.putRequest(updatedBlogData, `blog/update-blog/${this.blog.id}`)
        .subscribe(
          (res) => {
            this.spinnerService.hideSpinner();
            if (res.status === 200) {
              this.spinnerService.successSwal(res);
              this.router.navigateByUrl('/app/blog/all-blogs');
            }
          },
          (error) => {
            this.spinnerService.hideSpinner();
            this.spinnerService.errorSwal(error.error);
          }
        );
    }
  }
  
  onTagAdded(event: any) {
    console.log('Tag added:', event);
    // Check if the added tag is not already in the existing tagValues array
    if (!this.tagValues.includes(event)) {
      // Add the new tag to the existing tagValues array
      this.tagValues.push(event);
    }
  }
  
  onTagRemoved(event: any) {
    console.log('Tag removed:', event);
    // Find the index of the tag to be removed
    const index = this.tagValues.indexOf(event);
    // If the tag is found in the tagValues array, remove it
    if (index !== -1) {
      this.tagValues.splice(index, 1);
    }
  }
  

  getCategories = () => {
    this.spinnerService.showSpinner()
    const request = {};
    this.httpService.getRequestWithParameters("blog/get-categories", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner()
        if (response.status == 200) {
          this.blogs = response.data.data
        }
      }, (error) => {
        this.spinnerService.hideSpinner()
        this.spinnerService.errorSwal(error.error)
      })
  }

  generatePostContent = () => {
    if (!this.blog.title) {
      this.showMessage = true;
      return;
    }
    this.spinnerService.showSpinner();

    const request = {
      prompt: this.blog.title
    };

    this.httpService.getRequestWithParameters("business/generate-post-content", request)
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          this.contentGenerated = true;
          this.blog.content = response.data.data;
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      });
  }

}
