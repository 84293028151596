
<div class="main-wrapper">
  <main class="main users" id="skip-target">
    <div class="container-fluid">
      <div class="_text_right mb10">
      </div>
      <div class="dsp-flx mb10">
        <h2 class="main-title">Schedule Multiple Posts</h2>
      </div>
      <a class="btn btn-primary" *ngIf="selectedPostTab == 1" style="float: right;" routerLink="/app/post/posts">Back</a>

      <div class="row pl10 pr10 mlnone mrnone">
        <ul class="tab-header mb0">
          <li (click)="showTab(1)" [class.active]="selectedPostTab === 1"><span class="counts">1</span>Select Package</li>
          <li (click)="showTab(2)" [class.active]="selectedPostTab === 2"><span class="counts">2</span>Select Business</li>
          <li (click)="showTab(3)" [class.active]="selectedPostTab === 3"><span class="counts">3</span>Schedule</li>
        </ul>
        <form class="pl0 pr0" name="addMultiPostForm" (ngSubmit)="addMultiPostForm.form.valid && scheduleMultipost()"
          #addMultiPostForm="ngForm">
          <div class="blue-panel" [class.active]="selectedPostTab === 1" *ngIf="selectedPostTab == 1">
            <div class="blue-panel-body">
              <div class="_form_account _white_bx">
                <div class="row mb20">
                  <div class="col-md-2">
                    <label>Select Package<span class="text text-danger mt-1"> * </span></label>
                  </div>
                  <div class="col-md-4">
                    <select name="category" class="form-input w100 mb0" [(ngModel)]="multipost.package_id" required>
                      <option value="">Select Package</option>
                      <option *ngFor="let package of packages" [value]="package.package_id">{{ package.package
                        .name }}</option>
                    </select>
                  </div>
                </div>
                <fieldset class="col-sm-6 mt10 pl0">
                  <div class="form-group">
                    <button class="btn btn-primary" (click)="showTab(2)">
                      Next
                    </button>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div class="blue-panel" [class.active]="selectedPostTab === 2" *ngIf="selectedPostTab == 2">
            <div class="blue-panel-body">
              <div class="_form_account _white_bx">
                <div class="row mb-3">
                  <div class="col-md-2">
                    <label>Select Business<span class="text text-danger mt-1"> * </span></label>
                  </div>
                  <div class="col-md-10" style="display: flex; align-items: center;flex-wrap: wrap;">
                    <div *ngIf="networks && networks.length == 0" style="margin-right: 10px;">
                      <label class="text text-danger mt-1">No business is connected with any social platform.</label>
                    </div>
                    <div *ngFor="let item of networks; let i = index" style="margin-right: 10px; margin-bottom: 10px;">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" [value]="item.id" [checked]="isBusinessChecked(item.name)" (change)="onCheckboxChange($event, item)">
                        <label class="form-check-label" for="inlineCheckbox{{ i + 1 }}">{{ item.name }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <button *ngIf="networks && networks.length > 0" class="btn btn-primary" (click)="getpackagepost(multipost.package_id)">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="blue-panel" [class.active]="selectedPostTab === 3" *ngIf="selectedPostTab == 3">
            <div class="_form_account _white_bx pl20 pr20">
              <div class="users-table table-wrapper">
                <table class="dashboard-table">
                  <thead>
                    <tr class="users-table-info">
                      <th >Post Content</th>
                      <th width="25%">Post Image</th>
                      <th >Action</th>
                    </tr>
                  </thead>

                  <tbody *ngFor="let post of posts let i = index">
                    <tr *ngIf="!post.isHidden">
                      <td>
                        <div class="pages-table-img">
                          <div class="pages-table-img" [innerHTML]="post.content"></div>
                        </div>
                      </td>
                      <td>
                        <img *ngIf="post.media && post.media.file_url" [src]="backend_url+post.media.file_url"
                          alt="Post Image" style="max-width: 500px; max-height: 1000px;">
                        <span *ngIf="!post.media || !post.media.file_url">No Image</span>
                      </td>
                      <td>
                        <a title="Edit"
                          style="font-size: 20px; padding: 8px 12px; border: none; background: none; cursor: pointer;"
                          (click)="editpackage(post.id,post.content,i,post.media.file_url,post.media.id)">

                          <i class="fas fa-edit"></i></a>
                        <span style="margin-right: 20px;"></span>
                        <a title="Delete"
                          style="font-size: 20px; padding: 8px 12px; border: none; background: none; cursor: pointer;"
                          (click)="deletepackagepost(i)">
                          <i class="fas fa-trash-alt"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="posts && posts.length == 0">
                    <tr >
                      <td style="text-align: center;" colspan="4">No posts found</td>
                    </tr>
                  </tbody>
                </table>
                <div *ngIf="posts && posts.length > 0" class="mt15" role="group">
                  <a class="btn btn-primary mr10" (click)="adddatetime(1)"><span class="icon"><i
                        class="fa fa-calendar"></i></span> Date and
                    Time</a>
                  <button class="btn btn-primary" type="submit" class="btn btn-primary"> Schedule All Posts</button>
                </div>
              </div>
              
            </div>

          </div>

        </form>
      </div>
    </div>
  </main>
</div>

