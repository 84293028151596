import { Component, OnInit } from '@angular/core';
import { SessionData } from 'src/app/interfaces/SessionData';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {

  environment = environment
  logo: string = "";
  iswhitelable: boolean = false;
  sessionData = {} as SessionData

  constructor(private commonService: CommonService) {
    this.commonService.subject.sessionData.subscribe((data: SessionData) => {
      console.log(data)
      if(data.isWhiteLabel == 0){
        this.iswhitelable = false;
      } else {
        this.iswhitelable = true;
      }
      this.logo = data.logo
    })
  }

  ngOnInit(): void { }
}