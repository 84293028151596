import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'shortenString'
})
export class ShortenStringPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(value: string, ...args: any[]): unknown {

    let [isFullText, stringLen] = args

    if (!isFullText) {
      return this.sanitizer.bypassSecurityTrustHtml(value.length > stringLen ? `${value.substring(0, stringLen)}... <a id='readMore' name='readMore' style='color:blue'>Read More</a>` : value);
    }

    return value;
  }

}