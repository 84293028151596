<div class="notification-wrapper notification-block"
  (click)="notificationPopup.isVisible= !notificationPopup.isVisible;getUserNotifications()">
  <div *ngIf="notificationPopup.isVisible" class="notifications" id="box"
    [ngStyle]="{ height: height, opacity: opacity, 'z-index': 9 }">
    <h2>Notifications</h2>&nbsp;&nbsp;&nbsp;&nbsp;
    <button type="button" routerLink="/app/notification/notifications" class="btn btn-primary">View All</button>
    <div>
      <div class="notifications-item" *ngFor="let notification of notifications">
        <div class="text notification-drp" (click)="navigateNotification(notification)">
          <h4>
            {{ notification.client.firstname }}
            {{ notification.client.lastname }}
          </h4>
          <p [ngStyle]="{'background-color': (notification.is_read == 0) ? 'blue' : ''}">{{ notification.message }}</p>
          <small><span>{{notification.created_at | date:"d-MMM-y h:mm a"}}</span> </small>
        </div>
      </div>
    </div>
  </div>
  <span>
    <button title="Notifications">
      <i class="fas fa-regular fa-bell" style="font-size: 26px;"></i>
      <span *ngIf="notification != null">{{ notification.unread }}</span>
    </button>
  </span>
</div>