<!-- <section class="cust-login">
  <div autoscroll="false" ng-class="app.viewAnimation" class="content-wrapper">
    <div autoscroll="false" class="ng-zoomBackDown ng-fluid">
      <div class="block-center main-login-form">
        <form name="loginForm" (ngSubmit)="loginForm.form.valid && login()" #loginForm="ngForm">
          <div class="branding-block">
            <div class="brand-msg">
              <h4>Welcome Back!</h4>
              <p>Enter your ID and Password To Continue</p>
            </div>
            <p class="text-support">{{ constant.supportEmail }}</p>
          </div>
          <div class="panel panel-dark panel-flat panel-login">
            <div class="panel-body panel-body-login">
              <h3 class="text-center">Login into your account</h3>
              <fieldset>
                <div class="form-group">
                  <label class="input-form-lable">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <input class="form-control" placeholder="Enter your email" type="email" name="email"
                      [(ngModel)]="userModel.email" #email="ngModel" required email />
                  </label>
                  <div *ngIf="loginForm.submitted">
                    <div *ngIf="loginForm.controls?.['email']?.['errors']?.['required']" class="text text-danger mt-1">
                      Email is required
                    </div>

                    <div *ngIf="loginForm.controls?.['email']?.['errors']?.['email']" class="text text-danger mt-1">
                      Please enter a valid Email
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <div class="form-group mt10">
                  <label class="input-form-lable">
                    <i class="fa fa-lock fs-20" aria-hidden="true"></i>
                    <input class="form-control" placeholder="Enter your password"
                      [type]="show_eye ? 'text' : 'password'" name="password" [(ngModel)]="userModel.password"
                      #password="ngModel" required minlength="6" />

                    <div *ngIf="loginForm.submitted">
                      <div *ngIf="loginForm.controls?.['password']?.['errors']?.['required']"
                        class="text text-danger mt-1">
                        Password is required
                      </div>
                      <div *ngIf="loginForm.controls?.['password']?.['errors']?.['minlength']"
                        class="text text-danger mt-1">
                        Password length should be minimum six characters
                      </div>
                    </div>

                    <img class="show-hide-icon" [src]="
                        show_eye
                          ? 'assets/img/eyeslash.png'
                          : 'assets/img/eye.png'
                      " id="img_2" [title]="show_eye ? 'hide characters' : 'show characters'"
                      (click)="show_eye = !show_eye" />
                  </label>
                </div>
              </fieldset>
              <fieldset>
                <div class="form-group mt10">
                  <div class="checkbox c-checkbox fs-14">
                    <div class="_text_right">
                      <a routerLink="/auth/forgot-password" class="text-white">Forgot password?</a><br>
                      <a routerLink="/auth/no-password" class="text-white">I don't have a password yet</a><br>
                      <a routerLink="/auth/forgot-email" class="text-white">I have an account but i don't know for which
                        email</a>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <div class="form-group">
                  <button class="btn btn-primary btn-sm pull-right h4 login-page-submit" type="submit">
                    Login
                  </button>
                </div>
              </fieldset>
            </div>
          </div>
          <app-spinner></app-spinner>
        </form>

        <div class="panel-body panel-body-login brd-none pd-none mr-none" *ngIf="false">
          <form>
            <div class="branding-block">
              <img src="assets/img/brand.png" alt="" />
              <div class="brand-msg">
                <h4>Welcome Back!</h4>
                <p>Enter your ID and Password To Continue</p>
              </div>
              <p class="text-support">{{ constant.supportEmail }}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="bottom-register-block">
    </div>
  </div>
</section> -->


<section class="cust-login">
  <!-- Page content-->
  <div autoscroll="false" ng-class="app.viewAnimation" class="content-wrapper">
    <div autoscroll="false" class="ng-zoomBackDown ng-fluid">
      <div class="block-center main-login-form">
        <form name="loginForm" (ngSubmit)="login(loginForm.form)" #loginForm="ngForm">
          <div class="branding-block">
            <!-- <img src="assets/img/brand.png" alt=""> -->
            <div class="brand-msg">
              <h4>Welcome Back!</h4>
              <p>Enter Your Email And Password To Continue</p>
              <div class="logo_block" style="margin-top: 60px;">
                <app-logo></app-logo>
              </div>
            </div>
            <p class="text-support">{{ environment.supportEmail }}</p>
          </div>
          <div class="panel panel-dark panel-flat panel-login">
            <div class="panel-body panel-body-login">
              <h3 class="text-center">SIGN IN TO ACCESS THE PORTAL</h3>
              <fieldset>
                <span *ngIf="userEmail !=''" style="background-color: orange;">The email corresponding to
                  this ID
                  is {{userEmail}} </span>
                <div class="form-group">
                  <label class="input-form-lable">
                    <i class="fa fa-envelope fs-20" aria-hidden="true"></i>
                    <input class="form-control" placeholder="Enter your email" type="email" name="email"
                      [(ngModel)]="userModel.email" #email="ngModel" required email />
                  </label>
                  <div *ngIf="loginForm.submitted">
                    <div *ngIf="loginForm.controls?.['email']?.['errors']?.['required']" class="text text-danger mt-1">
                      Email is required
                    </div>

                    <div *ngIf="loginForm.controls?.['email']?.['errors']?.['email']" class="text text-danger mt-1">
                      Please enter a valid Email
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <!--<label>Password</label>-->
                <div class="form-group mt10">
                  <label class="input-form-lable">
                    <i class="fa fa-lock fs-20" aria-hidden="true"></i>
                    <input class="form-control" placeholder="Enter your password"
                      [type]="show_eye ? 'text' : 'password'" name="password" [(ngModel)]="userModel.password"
                      #password="ngModel" required minlength="6" />

                    <div *ngIf="loginForm.submitted">
                      <div *ngIf="loginForm.controls?.['password']?.['errors']?.['required']"
                        class="text text-danger mt-1">
                        Password is required
                      </div>
                      <div *ngIf="loginForm.controls?.['password']?.['errors']?.['minlength']"
                        class="text text-danger mt-1">
                        Password length should be minimum six characters
                      </div>
                    </div>

                    <img class="show-hide-icon" [src]="
                        show_eye
                          ? 'assets/img/eyeslash.png'
                          : 'assets/img/eye.png'
                      " id="img_2" [title]="show_eye ? 'hide characters' : 'show characters'"
                      (click)="show_eye = !show_eye" />
                  </label>
                </div>
              </fieldset>
              <fieldset>
                <div class="form-group mt10">
                  <div class="checkbox c-checkbox fs-14">
                    <label class="pull-left">
                      <input type="checkbox" name="remember" style="height: 0px;" />
                      <span class="fa fa-check"></span>Remember Me
                    </label><br>
                    <div class="_text_right">
                      <a routerLink="/auth/forgot-password" class="text-white">Forgot Password?</a><br>
                      <a routerLink="/auth/no-password" class="text-white">I don't have a Password yet</a><br>
                      <a routerLink="/auth/forgot-email" class="text-white">I have an account but i don't know for which
                        Email</a>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <div class="form-group">
                  <button class="btn btn-primary btn-sm pull-right h4 login-page-submit" type="submit">
                    Login
                  </button>
                </div>
              </fieldset>
            </div>
          </div>
          <app-spinner></app-spinner>
        </form>
      </div>
    </div>
    <div class="bottom-register-block">
      <!-- Don't have an account? <a href="register" class="text-primary">Register Now</a> -->
      <!-- <div>
        <a routerLink="/auth/forgot-password" class="text-white">Blog</a><br>
      </div> -->
    </div>
  </div>
</section>

