<canvas
  [height]="height"
  baseChart
  class="chart"
  [data]="data"
  [plugins]="chartPlugins"
  [options]="chartOptions"
  [type]="chartType"
>
</canvas>
