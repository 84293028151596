<!-- <div class="menu_tabButtons">
    <ul *ngIf="showStep">
        <li>
            <a href="#" title="Add business" (click)="submenuActive='business'"
                [class]="submenuActive =='business'?'submenu-active':''"
                routerLink="{{localData.has_business !== 0 ? '/app/business/businesses':'/app/business/add-business'}}">
                <img src="{{localData.has_business !== 0 ? 'assets/img/done.png':'assets/img/blank.png'}}"> 1:
                BizProduct
            </a>
        </li>
        <li>
            <a href="#" title="Add contact" (click)="submenuActive='contact'"
                [class]="submenuActive =='contact'?'submenu-active':''"
                routerLink="{{localData.has_contact !== 0 ? '/app/client/clients':'/app/client/add-client/0/0'}}">
                <img src="{{localData.has_contact !== 0 ? 'assets/img/done.png':'assets/img/blank.png'}}"> 2: Contacts
            </a>
        </li>
        <li>
            <a href="#" title="Number" (click)="submenuActive='number'"
                [class]="submenuActive =='number'?'submenu-active':''"
                routerLink="{{localData.has_number !== 0 ? '/app/number/numbers':'/app/number/buy-number'}}">
                <img src="{{localData.has_number !== 0 ? 'assets/img/done.png':'assets/img/blank.png'}}"> 3: Number
            </a>
        </li>
        <li>
            <a href="#" title="Review" (click)="submenuActive='review'"
                [class]="submenuActive =='review'?'submenu-active':''"
                routerLink="{{localData.has_review !== 0 ? '/app/review/reviews':'/app/client/clients'}}">
                <img src="{{localData.has_review !== 0 ? 'assets/img/done.png':'assets/img/blank.png'}}"> 4: Ask
                Review
            </a>
        </li>
    </ul>
</div> -->
<div *ngIf="showStep">
    <div class="custom-horizontal-stepper">
        <div class="custom-step">
            <!-- Business Step -->
            <div class="step-container" [ngClass]="{'active': submenuActive === 'business' || activeStep === 'business'}" style="margin: 10px;">
                <div class="step-label">
                    <a href="#" title="Add business" (click)="submenuActive='business'; activeStep = 'business'"
                        [class]="submenuActive === 'business' ? 'submenu-active' : ''"
                        [routerLink]="localData.has_business !== 0 ? '/app/business/businesses' : '/app/business/add-business'">
                        <i [ngStyle]="{'background-color': getBackgroundColor('has_business')}"
                            [class]="submenuActive === 'business' || activeStep === 'business' ? '' : 'fas fa-pencil-alt'"
                            style="width: 24px; height: 24px; border-radius: 50%; color: white; justify-content: center; align-items: center; margin-right: 5px; display: inline-flex;">
                            <span class="step-number">{{ submenuActive === 'business' || activeStep === 'business' ? '1' : '' }}</span>
                        </i> Business
                    </a>
                </div>
            </div>
            <div style="border-top-width: 1px; border-top-style: solid; flex: auto; border-top-color: #0000001f;"></div>

            <!-- Social Platforms Step -->
            <div class="step-container" [ngClass]="{'active': submenuActive === 'Socialplatforms' || activeStep === 'Socialplatforms'}" style="margin: 10px;">
                <div class="step-label">
                    <a href="#" title="Add contact" (click)="submenuActive='Socialplatforms'; activeStep = 'Socialplatforms'"
                        [class]="submenuActive === 'Socialplatforms' ? 'submenu-active' : ''"
                        [routerLink]="localData.has_socialpateforms !== 0 ? '/app/business/businesses' : '/app/business/businesses'">
                        <i [ngStyle]="{'background-color': getBackgroundColor('has_socialpateforms')}"
                            [class]="submenuActive === 'Socialplatforms' || activeStep === 'Socialplatforms' ? '' : 'fas fa-pencil-alt'"
                            style="width: 24px; height: 24px; border-radius: 50%; color: white; justify-content: center; align-items: center; margin-right: 5px; display: inline-flex;">
                            <span class="step-number">{{ submenuActive === 'Socialplatforms' || activeStep === 'Socialplatforms' ? '2' : '' }}</span>
                        </i> Social Platforms
                    </a>
                </div>
            </div>
            <div style="border-top-width: 1px; border-top-style: solid; flex: auto; border-top-color: #0000001f;"></div>

            <!-- Posts Step -->
            <div class="step-container" [ngClass]="{'active': submenuActive === 'posts' || activeStep === 'posts'}" style="margin: 10px;">
                <div class="step-label">
                    <a href="#" title="Review" (click)="submenuActive='posts'; activeStep = 'posts'"
                        [class]="submenuActive === 'posts' ? 'submenu-active' : ''"
                        [routerLink]="localData.has_posts !== 0 ? '/app/post/posts' : '/app/post/posts'">
                        <i [ngStyle]="{'background-color': getBackgroundColor('has_posts')}"
                            [class]="submenuActive === 'posts' || activeStep === 'posts' ? '' : 'fas fa-pencil-alt'"
                            style="width: 24px; height: 24px; border-radius: 50%; color: white; justify-content: center; align-items: center; margin-right: 5px; display: inline-flex;">
                            <span class="step-number">{{ submenuActive === 'posts' || activeStep === 'posts' ? '3' : '' }}</span>
                        </i> Posts
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>




