import { NgModule } from '@angular/core';
import { ChartComponent } from 'src/app/components/reusable/chart/chart.component';
import { NgChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    ChartComponent,
  ],
  imports: [
    CommonModule,
    NgChartsModule
  ],
  exports: [
    NgChartsModule,
    ChartComponent,
  ]
})
export class ChartModule { }
