import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-posting-datetime',
  templateUrl: './add-posting-datetime.component.html',
  styleUrls: ['./add-posting-datetime.component.css']
})
export class AddPostingDatetimeComponent implements OnInit {
  // modalinputdata = {
  //   id: '',
  //   date: '',
  //   time: '',
  //   selectedHour: 0, // Set the default value to 0
  //   selectedMinute: 0 // Set the default value to 0
  // };

  modalinputdata: { date: NgbDateStruct, selectedHour?: string, selectedMinute?: string, selectedrange?: string } = { 
    date: this.getCurrentDate(),
    selectedHour: '12', // Set the default value as a string
    selectedMinute: '00', 
    selectedrange: 'AM'
  };
  

  currentYear!: number; // Add the definite assignment assertion
  currentMonth!: number; // Add the definite assignment assertion

  getCurrentDate(): NgbDateStruct {
    const currentDate = new Date();
    const nextDay = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Adding 1 day in milliseconds
    return {
      year: nextDay.getFullYear(),
      month: nextDay.getMonth() + 1,
      day: nextDay.getDate(),
    };
  }

  hours: string[] = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
  minutes: string[] = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

  constructor(
    public activeModal: NgbActiveModal, 
    private spinnerService: SpinnerService, 
    private httpService: HttpService
    ) 
    {
    }

    ngOnInit() {
      this.currentYear = new Date().getFullYear();
      this.currentMonth = new Date().getMonth() + 1;
      this.modalinputdata.date = this.getCurrentDate();
    }
    

    onHourChange(event: Event) {
      const selectedHour = (event.target as HTMLSelectElement)?.value;
      this.modalinputdata.selectedHour = selectedHour;
    }

    onMinuteChange(event: Event) {
      const selectedMinute = (event.target as HTMLSelectElement)?.value;
      this.modalinputdata.selectedMinute = selectedMinute;
    }
    
    onRangeChange(event: Event) {
      const selectedRange = (event.target as HTMLSelectElement)?.value;
      this.modalinputdata.selectedrange = selectedRange;
    }
    

    selectPostTime = () => {
      const selectedDate = this.modalinputdata.date;
      const selectedHour = this.modalinputdata.selectedHour || '12';
      const selectedMinute = this.modalinputdata.selectedMinute || '00';
      const selectedRange = this.modalinputdata.selectedrange?.toUpperCase(); // Convert to uppercase
    
      // Pad months and days with zeros if needed
      const paddedMonth = selectedDate.month.toString().padStart(2, '0');
      const paddedDay = selectedDate.day.toString().padStart(2, '0');
    
      // Combine date, hour, minute, and range into a string
      let formattedDateTime = `${selectedDate.year}-${paddedMonth}-${paddedDay} ${selectedHour}:${selectedMinute}:00`;
    
      // Convert to 24-hour format if PM is selected
      if (selectedRange === 'PM') {
        let hour = parseInt(selectedHour);
        if (hour !== 12) {
          hour += 12;
        }
        formattedDateTime = `${selectedDate.year}-${paddedMonth}-${paddedDay} ${hour}:${selectedMinute}:00`;
      }
    
      this.activeModal.close({ schedule_to: formattedDateTime });
    }
    
    
    

}
