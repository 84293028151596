import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
//import { AngularEditorConfig } from '@kolkov/angular-editor';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-add-blog-category',
  templateUrl: './add-blog-category.component.html',
  styleUrls: ['./add-blog-category.component.css']
})
export class AddBlogCategoryComponent implements OnInit {

  constructor(
    private httpService: HttpService,
    private spinnerService: SpinnerService,
    public commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  formData = new FormData();
  media_image: any = null;

  selectedFile: File | null = null;
  mediaSrc: string | ArrayBuffer | null = '';
  backend_url: string | ArrayBuffer | null = `${environment.backend_url}image_icon.jpg`;
  blog: any = {
    title: '',
    content: ''
  }

  ngOnInit(): void {
    let id = this.route.snapshot.params['id'];
    if (id !== undefined) {
      this.blog.id = id;
      this.getBlogCategoryInfo();
    }
  }

  Editor = ClassicEditor;
  // public config: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: true,
  //   height: '15rem',
  //   minHeight: '5rem',
  //   placeholder: 'Enter text here...',
  //   translate: 'no',
  //   customClasses: [
  //     {
  //       name: "quote",
  //       class: "quote",
  //     },
  //     {
  //       name: 'redText',
  //       class: 'redText'
  //     },
  //     {
  //       name: "titleText",
  //       class: "titleText",
  //       tag: "h1",
  //     },
  //   ]
  // }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.selectedFile = file;
    this.formData.append("media_image", file);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.mediaSrc = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  capitalizeFirstLetter(event: any) {
    const inputValue = event.target.value;
    if (inputValue) {
      // Capitalize the first letter of the input value
      this.blog.title = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    }
  }
  
  validateContentNotEmpty(content: string): boolean {
    return content.trim().length > 0;
  }

  addBlogcategory(form: any) {
    form.submitted = true;
    
    if (!this.media_image) {
      // If no image selected, set an error message
      form.controls['media_image'].setErrors({ 'required': true });
      return; // Stop further execution
    }
    
    if (!this.validateContentNotEmpty(this.blog.content)) {
      form.controls['content'].setErrors({ 'required': true });
    }
    if (form.valid) {
      let mediaObjArr = Object.keys(this.blog)
      mediaObjArr.forEach((val: any, key) => {
        this.formData.append(mediaObjArr[key].toString(), this.blog[val]);
      })
      this.spinnerService.showSpinner();
      this.httpService.postRequest(this.formData, "blog/add-blog-category")
        .subscribe((res) => {
          this.spinnerService.hideSpinner();
          if (res.status == 200) {
            this.spinnerService.successSwal(res);
            this.router.navigateByUrl("/app/blog/all-blogs-category");
          }
        }, (error) => {
          this.formData = new FormData()
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        })
    }

  }

  getBlogCategoryInfo = () => {
    this.spinnerService.showSpinner();
    this.httpService.getRequestWithParameters("blog/blog-category-info", { id: this.blog.id })
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          this.blog = response.data;
          const baseUrl = environment.backend_url;
          if(this.blog.media.file_url){
            this.mediaSrc = baseUrl + this.blog.media.file_url;
          } else {
            this.mediaSrc = baseUrl + 'image_icon.jpg';
          }
          
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      });
  }

  updateBlogcategory() {
    this.spinnerService.showSpinner();

    const updatedBlogData = {
      title: this.blog.title,
      content: this.blog.content,
    };
    if (this.selectedFile) {
      const formDataWithImage = new FormData();
      formDataWithImage.append('media_image', this.selectedFile);
      formDataWithImage.append('title', this.blog.title);
      formDataWithImage.append('content', this.blog.content);

      this.httpService.putRequest(formDataWithImage, `blog/update-blog-category/${this.blog.id}`)
        .subscribe(
          (res) => {
            this.spinnerService.hideSpinner();
            if (res.status === 200) {
              this.spinnerService.successSwal(res);
              this.router.navigateByUrl('/app/blog/all-blogs-category');
            }
          },
          (error) => {
            this.spinnerService.hideSpinner();
            this.spinnerService.errorSwal(error.error);
          }
        );
    } else {
      this.httpService.putRequest(updatedBlogData, `blog/update-blog-category/${this.blog.id}`)
        .subscribe(
          (res) => {
            this.spinnerService.hideSpinner();
            if (res.status === 200) {
              this.spinnerService.successSwal(res);
              this.router.navigateByUrl('/app/blog/all-blogs-category');
            }
          },
          (error) => {
            this.spinnerService.hideSpinner();
            this.spinnerService.errorSwal(error.error);
          }
        );
    }
  }

}
