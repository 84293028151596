<div class="page-flex" style="padding-left: 264px" (click)="hideNavBar($event)">
  <div class="main-wrapper">
    <!-- ! Main nav -->
    <nav class="main-nav--bg">
      <div class="container main-nav mb0">

        <!-- when admin login to user account -->
        <div class="_u_id_container" *ngIf="localData.admin_id && localData.admin_id != 0">
          <label><b>Name:</b> </label> &nbsp;{{ localData.name }}
          <label class="ml10"><b>User id:</b> </label>
          &nbsp;{{ localData.user_id }}
          <button class="btn btn-primary ml15 mr15 log_out_btn" (click)="signOutAdmin()">
            Logout
          </button>
        </div>
        <div class="main-nav-end">
          <!-- <app-notification [notification]="notification" class="notification-block"
            [notificationPopup]=notificationPopup (click)="hideNavBar($event)"></app-notification> -->
            <div class="nav-user-wrapper" (click)="toggleSubmenu($event)">
              <button class="nav-user-btn" title="My profile" type="button">
                <span class="nav-user-img">
                  <i style="font-size: 17px" class="fa">&#xf007;</i>
                </span>
              </button>
              <div class="_dropdown_submenu" *ngIf="showSubmenu">
                <ul>
                  <span *ngIf="localData.role == 1 || localData.role == 2">
                    <li>
                      <a routerLink="/app/user/profile" title="Profile">
                        <span class="icon"><i class="fas fa-user"></i></span>
                        Profile
                      </a>
                    </li>
                    <li style="border-bottom: 1px solid #ddd;">
                      <a routerLink="/app/user/credits" title="Credits">
                        <span class="icon"><i class="fas fa-credit-card"></i></span>
                        Credits
                      </a>
                    </li>
                  </span>
                  <span *ngIf="localData.role == 1 || localData.role == 2">
                    <!-- <li>
                      <a routerLink="/app/number/numbers" title="Numbers">
                        <span class="icon"><i class="fas fa-regular fa-mobile"></i></span>
                        Numbers
                      </a>
                    </li> -->
                    <li style="border-bottom: 1px solid #ddd;">
                      <a routerLink="/app/user/billing" title="Billing">
                        <span class="icon"><i class="fas fa-money-bill-alt"></i></span>
                        Billing
                      </a>
                    </li>
                  </span>
                  <span *ngIf="localData.role == 1 || localData.role == 2">
                  <li style="border-bottom: 1px solid #ddd;">
                    <a title="Domain" routerLink="/app/domain/domains">
                      <span class="icon">
                        <i class="fa fa-globe"></i>
                      </span>
                      Blog Domain
                    </a>
                  </li>
                </span>                  
                <li *ngIf="localData.role == 1">
                    <a routerLink="/app/admin/plans" title="Plans">
                      <span class="icon"><i class="fas fa-calendar"></i></span>
                      Plans
                    </a>
                  </li>
                  <li *ngIf="localData.role == 1">
                    <a routerLink="/app/admin/all-users" title="All Users">
                      <span class="icon"><i class="fas fa-users"></i></span>
                      All Users
                    </a>
                  </li>
                </ul>
              </div>
            <!-- </button> -->
          </div>
        </div>
      </div>
    </nav>
    <app-steps-completed></app-steps-completed>
  </div>
  <app-sidebar style="position: fixed; top: 0; left: 0"> </app-sidebar>
  <router-outlet (activate)="subscribeToEmitter($event)"></router-outlet>
  <app-spinner></app-spinner>
</div>