import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {

  environment = environment
  showMessageForm: boolean = false;

  review = {
    id: 0,
    fullname: "",
    email: "",
    code: "US",
    number: "",
    message: "",
    rating: 5,
    type: "review"
  }
  store = {
    name: "",
    description: "",
    web_url: "",
    thankyou_url: "",
    logo_url: "",
    image: "",
    rating: 5,
    review_form_data:{
      form_heading: "",
      review_label: "",
      review_message_heading: "",
      item_review_label: "",
      btn_label:"Submit"
    }
  }
  review_type: string = "business";

  countries = [{ id: 0, name: "", iso: "" }];
  constructor(private route: ActivatedRoute, private httpService: HttpService, private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.review.id = parseInt(atob(this.route.snapshot.params['id']));
    this.review.type = this.route.snapshot.params['type'];
    this.getInfo()
    this.getCountries();
  }

  getUserLocation = () => {
    fetch(environment.ipInfo).then(response => response.json())
      .then(jsonData => {
        let { country } = jsonData;
        this.review.code = country
      })
  }

  getCountries = () => {

    this.spinnerService.showSpinner();
    this.httpService.getRequest("country/countries").
      subscribe((res) => {
        this.spinnerService.hideSpinner();
        if (res.status === 200) {
          this.countries = res.data;
          this.getUserLocation();
        } else {
          this.spinnerService.errorSwal(res);
        }
      }, error => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }

  getInfo = () => {

    this.spinnerService.showSpinner();
    this.httpService.getRequestWithParameters("business/info", { id: this.review.id, type: this.review.type }).
      subscribe((res) => {
        this.spinnerService.hideSpinner();
        if (res.status === 200) {
          this.store = res.data
          this.review_type = res.data.type == 1 ? "business" : "product";
        } else {
          this.spinnerService.errorSwal(res);
        }
      }, error => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }

  submitReview = () => {

    if (this.review.rating <= 3 && this.review_type != 'product' && !this.showMessageForm) {
      this.showMessageForm = true;
      return;
    }

    this.spinnerService.showSpinner();
    this.httpService.postRequest(this.review, "review/submitreview").
      subscribe(async (res) => {
        this.spinnerService.hideSpinner();
        if (res.status === 200) {
          let isRedirect: boolean = false;
          if (this.review_type == "product") {
            isRedirect = true;
          } else {
            if (res.data.rating <= 3) {
              isRedirect = true;
            } else {
              window.location.href = res.data.url
            }
          }
          if (isRedirect) {
            let redirectUrl = this.store.thankyou_url != '' ? this.store.thankyou_url : this.store.web_url

            let result = await this.spinnerService.successSwal({ message: res.message }, 5000)

            if (result.isConfirmed && redirectUrl != '') { // if user clicked OK and  redirect is not empty
              window.location.href = redirectUrl
            }
          }
        } else {
          this.spinnerService.errorSwal(res);
        }
      }, error => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }

}
