import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HttpService } from 'src/app/services/http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blog-settings',
  templateUrl: './blog-settings.component.html',
  styleUrls: ['./blog-settings.component.css']
})
export class BlogSettingsComponent implements OnInit {

  constructor(
    private httpService: HttpService,
    private spinnerService: SpinnerService,
    public commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  formData = new FormData();
  media_image: any = null;
  media_images: any = null;
  // baseUrl: string = ''; // Initialize baseUrl with an empty string

  selectedFile: File | null = null;
  mediaSrc: string | ArrayBuffer | null = null;
  mediaSrcs: string | ArrayBuffer | null = null;

  backend_url: string | ArrayBuffer | null = `${environment.backend_url}image_icon.jpg`;
  // baseUrl: string; // Declare baseUrl property

  blog: any = {}
  blogs: any = {}
  ngOnInit(): void {
    // let id = this.route.snapshot.params['id'];
    // if (id !== undefined) {
    //   this.blog.id = id;
    this.getBlogSettingInfo();
    // this.baseUrl = environment.backend_url; // Initialize baseUrl from environment

    // }
  }

  // onFileSelected(event: any): void {
  //   const file: File = event.target.files[0];
  //   this.selectedFile = file;
  //   this.formData.append("media_image", file);
  //   const reader = new FileReader();
  //   reader.onload = (e: any) => {
  //     this.mediaSrc = e.target.result;
  //   };
  //   reader.readAsDataURL(file);
  // }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
      this.formData.set("media_image", file); // Use set instead of append to replace existing file if any
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.mediaSrc = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  // onFile(event: any): void {
  //   const file: File = event.target.files[0];
  //   this.selectedFile = file;
  //   this.formData.append("media_images", file);
  //   const reader = new FileReader();
  //   reader.onload = (e: any) => {
  //     this.mediaSrcs = e.target.result;
  //   };
  //   reader.readAsDataURL(file);
  // }
  
  addBlogsettings(form: any) {
    form.submitted = true;
    if (form.valid) {
      const settingsObj = {
        businessSetting: {
          buttonTextColor: form.value.buttonTextColor || '#ffffff',
          buttonBackgroundColor: form.value.buttonBackgroundColor || '#00acd8',
          HoverTextColor: form.value.HoverTextColor || '#ffffff',
          HoverBackgroundColor: form.value.HoverBackgroundColor || '#00acd8',
          buttonText: form.value.buttonText || 'Read More'
        },
        sidebarSetting: {
          RecentPostCount: form.value.RecentPostCount || '5',
          FeaturedCount: form.value.FeaturedCount || '5',
          CategoryCount: form.value.CategoryCount || '5',
          ArchiveCount: form.value.ArchiveCount || '5'
        },
        headerSetting: {
          width: form.value.width || '100%',
          height: form.value.height || '100px',
          email: form.value.email || 'example@example.com',
          contact: form.value.contact || '1234567890',
          media_image: this.media_image || '', // Assuming this.media_image is updated through the file input
          headerTitle: form.value.headerTitle || 'Blog Title',
          backgroundcolor: form.value.backgroundcolor || '#00acd8',
          color: form.value.color || '#fff',
          // media_images: this.media_images || ''
        }
      };

      console.log(settingsObj);

      // Now you can send the settingsObj to the backend

      const settingsString = JSON.stringify(settingsObj);
      this.formData.set('settings', settingsString);

      this.spinnerService.showSpinner();
      this.httpService.postRequest(this.formData, "blog/add-blog-settings")
        .subscribe((res) => {
          this.spinnerService.hideSpinner();
          if (res.status == 200) {
            this.spinnerService.successSwal(res);

          }
        }, (error) => {
          this.formData = new FormData()
          this.spinnerService.hideSpinner();
          this.spinnerService.errorSwal(error.error);
        });
    }
  }
// Add a property to track whether blog settings are fetched
blogSettingsFetched: boolean = false;

// Modify getBlogSettingInfo function to set blogSettingsFetched
getBlogSettingInfo = () => {
  this.spinnerService.showSpinner();
  this.httpService.getRequest("blog/blogsettingsinfo")
    .subscribe((response) => {
      this.spinnerService.hideSpinner();
      if (response.status === 200) {
        // Initialize the blog object if it's null or undefined
        if (!this.blog) {
          this.blog = {};
        }
        // Use example blog_settings if response data is null
        if (response.data === null) {
          this.blog.blog_settings = {
            businessSetting: { // Corrected the key name from 'buttonSetting' to 'buttonSetting'
              buttonTextColor: '#ffffff',
              buttonBackgroundColor: '#00acd8',
              HoverTextColor: '#ffffff',
              HoverBackgroundColor: '#00acd8',
              buttonText: 'Read More'
            },
            sidebarSetting: {
              RecentPostCount: '5',
              FeaturedCount: '5',
              CategoryCount: '5',
              ArchiveCount: '5'
            },
            headerSetting: {
              width: '100%',
              height: '100px',
              email: 'example@example.com',
              contact: '1234567890',
              media_image: '',
              headerTitle: 'Blog Title',
              backgroundcolor: '#00acd8',
              color: '#ffffff'
            }
          };
          this.blogSettingsFetched = false;
        } else {
          // Use fetched blog_settings
          this.blog.blog_settings = response.data.blog_settings;
          // Parse JSON if blog_settings is a string
          if (typeof this.blog.blog_settings === 'string') {
            this.blog.blog_settings = JSON.parse(this.blog.blog_settings);
          }
           this.blogSettingsFetched = true;
        }
        // Set mediaSrc based on blog_settings
        const baseUrl = environment.backend_url;
        if (this.blog.blog_settings.headerSetting && this.blog.blog_settings.headerSetting.media_image) {
          this.mediaSrc = baseUrl + this.blog.blog_settings.headerSetting.media_image;
        } else {
          this.mediaSrc = baseUrl + 'image_icon.jpg';
        }

        this.blog.id=response.data.id
        // Set blogSettingsFetched to true
       console.log()
      }
    }, (error) => {
      this.spinnerService.hideSpinner();
      this.spinnerService.errorSwal(error.error);
    });
}

updateBlogSettings(form: any) {
  form.submitted = true;
  if (form.valid) {
      const settingsObj = {
          businessSetting: {
              buttonTextColor: form.value.buttonTextColor || '#ffffff',
              buttonBackgroundColor: form.value.buttonBackgroundColor || '#00acd8',
              HoverTextColor: form.value.HoverTextColor || '#ffffff',
              HoverBackgroundColor: form.value.HoverBackgroundColor || '#00acd8',
              buttonText: form.value.buttonText || 'Read more'
          },
          sidebarSetting: {
              RecentPostCount: form.value.RecentPostCount ,
              FeaturedCount: form.value.FeaturedCount ,
              CategoryCount: form.value.CategoryCount ,
              ArchiveCount: form.value.ArchiveCount 
          },
          headerSetting: {
              width: form.value.width || '100%',
              height: form.value.height || '100px',
              email: form.value.email || 'example@example.com',
              contact: form.value.contact || '1234567890',
              media_image: this.media_image ? this.media_image : this.blog?.blog_settings?.headerSetting?.media_image || '', // Use form value if available, otherwise use existing media_image
              // media_image: this.media_image || '',
              headerTitle: form.value.headerTitle || 'Blog Title',
              backgroundcolor: form.value.backgroundcolor || '#00acd8',
              color: form.value.color || '#ffffff'
          }
      };

      console.log(settingsObj);

      // Now you can send the settingsObj to the backend for update

      const settingsString = JSON.stringify(settingsObj);
      this.formData.set('settings', settingsString);

      this.spinnerService.showSpinner();
      this.httpService.putRequest(this.formData, "blog/update-blog-settings")
          .subscribe((res) => {
              this.spinnerService.hideSpinner();
              if (res.status == 200) {
                  this.spinnerService.successSwal(res);
                  window.location.reload();
              }
          }, (error) => {
              this.formData = new FormData()
              this.spinnerService.hideSpinner();
              this.spinnerService.errorSwal(error.error);
          });
  }
}

async deleteMedia(id: number) {
  let result = await this.spinnerService.confirmSwal(`You want to delete this file`);
  if (result.isConfirmed) {
    const request = { id: id};
    console.log(id)
    this.httpService.putRequest(request, "blog/delete-media")
      .subscribe((response) => {
        this.spinnerService.hideSpinner();
        if (response.status == 200) {
          this.spinnerService.successSwal(response);
          window.location.reload();
        }
      }, (error) => {
        this.spinnerService.hideSpinner();
        this.spinnerService.errorSwal(error.error);
      })
  }
}

}