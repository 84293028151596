<div class="main-wrapper">
  <main class="main users" id="skip-target">
    <div class="container-fluid">

      <div class="dsp-flx mb10">
        <div class="dsp-flx">
          <h2 class="main-title mr30">Content Schedule</h2>
        </div>
        <div class="_text_right">
          <a class="btn btn-primary" routerLink="/app/post/posts">Back</a>
        </div>
      </div>

      <div class="row mb10">
        <div class="col-sm-12">

          <div class="datepicker-container _white_bx dsp-flx pl5 pr5 pt10 pb10" style="border: none;">
            <!-- Start Datepicker -->
            <div class="col-sm-3">
              <div class="input-group position-relative">
                <input id="startDate" class="form-control" [(ngModel)]="formattedStartDate"
                  [ngModelOptions]="{ standalone: true }" (click)="onStartDateToggle()" placeholder="Start Date"
                  ngbInput />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="onStartDateToggle()" type="button">
                    <i class="fas fa-calendar"></i>
                  </button>
                </div>
                <ngb-datepicker *ngIf="startDatePickerVisible" [(ngModel)]="calanderinputdata.startdate"
                  [startDate]="{ year: currentYear, month: currentMonth }" class="position-absolute"
                  style="z-index: 1050;background-color: #fff;"></ngb-datepicker>

              </div>
            </div>

            <!-- End Datepicker -->
            <div class="col-sm-3">
              <div class="input-group position-relative">
                <input id="endDate" class="form-control" [(ngModel)]="formattedEndDate"
                  [ngModelOptions]="{ standalone: true }" (click)="toggleEndDatePicker()" placeholder="End Date"
                  ngbInput />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="toggleEndDatePicker()" type="button">
                    <i class="fas fa-calendar"></i>
                  </button>
                </div>
                <ngb-datepicker *ngIf="endDatePickerVisible" [(ngModel)]="calanderinputdata.enddate"
                  [startDate]="{ year: currentYear, month: currentMonth }" class="position-absolute"
                  style="z-index: 1050;background-color: #fff;"></ngb-datepicker>
              </div>
            </div>
            <div class="col-sm-2">
              <select name="daterange" id="daterange" (change)="onDateRangeChange($event)"
                style="background: #fff;border: 1px solid #dee2e6;">
                <option value="">---Show All---</option>
                <option value="Next30days">Next 30 days</option>
                <option value="NextMonth">Next Month</option>
                <option value="NextWeek">Next Week (Sun-Sat)</option>
                <option value="Next3Months">Next 3 Months</option>
                <option value="Today">Today</option>
                <option value="Yesterday">Yesterday</option>
                <option value="ThisWeek">This Week (Sun-Today)</option>
                <option value="LastWeek">Last Week (Sun-Sat)</option>
                <option value="ThisMonth">This Month</option>
                <option value="Last30days">Last 30 days</option>
                <option value="LastMonth">Last Month</option>
              </select>
            </div>
            <div class="col-sm-3" style="max-width: 23.66667%;padding-right: 0;">
              <div class="search-main">
                <div class="search-wrapper">
                  <input style="background: #fff;border-color: #dee2e6;" type="text" placeholder="Search" name="search"
                    [(ngModel)]="calanderinputdata.searchedItem">
                </div>
              </div>
            </div>
            <div class="col-sm-2" style="text-align: right;max-width: 9.66667%;padding-left: 0;">
              <button class="btn btn-primary" (click)="reset_filter()">X</button>
              <button class="btn btn-primary ml10" (click)="applyDateRangeFilter()">Search</button>
            </div>
          </div>

        </div>
      </div>


      <div class="row stat-cards mb15">
        <div class="col-md-6 col-xl-4" (click)="showTab(1, 'Posting Schedule')">
          <article class="stat-cards-item">
            <div class="stat-cards-icon primary">
              <i class="fas fa-calendar"></i>
            </div>
            <div class="stat-cards-info">
              <p class="stat-cards-info__num">Total: {{totalSecheduledItems}}</p>
              <p class="stat-cards-info__title">Found: {{ScheduledItemscount}}</p>
              <p class="stat-cards-info__title">Posting Schedule</p>
            </div>
          </article>
        </div>
        <div class="col-md-6 col-xl-4" (click)="showTab(2, 'Posted Log')">
          <article class="stat-cards-item">
            <div class="stat-cards-icon warning">
              <i class="fas fa-th-list"></i>
            </div>
            <div class="stat-cards-info">
              <p class="stat-cards-info__num">Total: {{totalPostedItems}}</p>
              <p class="stat-cards-info__title">Found: {{postedItemscount}}</p>
              <p class="stat-cards-info__title">Posted Log</p>
            </div>
          </article>
        </div>
        <div class="col-md-6 col-xl-4" (click)="showTab(3, 'Rejected Posts')">
          <article class="stat-cards-item">
            <div class="stat-cards-icon danger">
              <i class="fas fa-ban"></i>
            </div>
            <div class="stat-cards-info">
              <p class="stat-cards-info__num">Total: {{totalRejectededItems}}</p>
              <p class="stat-cards-info__title">Found: {{rejectedItemscount}}</p>
              <p class="stat-cards-info__title">Rejected Posts</p>
            </div>
          </article>
        </div>
      </div>

      <div class="dsp-flx mb10">
        <h2 class="main-title">{{tabTitle}}</h2>
      </div>

      <div class="white-section row pl10 pr10 mlnone mrnone" style="border-radius: 10px;">
        <!-- <pre>{{ scheduledpost | json }}</pre> -->
        <div class="row" *ngIf="selectedPostTab == 1">
          <div class="col-lg-12">
            <div class="users-table table-wrapper">
              <table class="dashboard-table">
                <thead>
                  <tr class="users-table-info">

                    <th width="30%">Post</th>
                    <th width="15%" style="text-align: center;">Post Image</th>
                    <th width="15%">Scheduled Date</th>
                    <th width="15%">Created Date</th>
                    <th width="10%" style="text-align: center;">Status</th>
                    <th width="35%" style="text-align: center;">Action</th>
                  </tr>
                </thead>


                <tbody *ngIf="ScheduledItems != null">
                  <ng-container *ngFor="let post of ScheduledItems | paginate: config let i = index">
                    <tr>
                      <td [innerHTML]="post.content"></td>
                      <td style="text-align: center;">
                        <div class="stat-cards-icon warning" style="margin: auto; width: 80px; height: 80px;">
                          <img *ngIf="post.file_url" [src]="backend_url+post.file_url" alt="Post Image" style="width: 100%; height: 100%; object-fit: cover;overflow: hidden;">
                          <i *ngIf="!post.file_url" class="fas fa fa-image"></i>
                      </div>
                      
                      
                      </td>                      
                      <td>{{ post.schedule_to | date:'yyyy-MM-dd hh:mm a':'UTC' }}</td>
                      <td>{{ post.created_at | date:'yyyy-MM-dd' }} {{ post.created_at | date:'hh:mm a' }}</td>
                      <td style="text-align: center;">
                        <div class="stat-cards-icon success"><i class="fas fa-calendar"></i></div>
                        <p style="font-size: 15px; color: #136416;">Scheduled</p>
                      </td>
                      <td style="text-align: center;">
                        <div class="btn-group ms-2 mt-2 mb-2">
                          <div ngbDropdown>
                            <button class="btn ripple btn-primary" ngbDropdownToggle id="dropdownMenuButton">
                              <i class="fa fa-gear"></i> Action
                            </button>
                            <div ngbDropdownMenu class="tx-20">
                              <a ngbDropdownItem (click)="adddatetime(post.id,i)">Reschedule</a>
                              <a ngbDropdownItem
                                (click)="editpackage(post.id,post.content,i,post.file_url,post.media_id)">Edit Post</a>
                              <a ngbDropdownItem (click)="deletePost(post.id)">Remove Post</a>
                              <a ngbDropdownItem (click)="delete_business_post(post.id)">Delete Post</a>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>

                <tbody *ngIf="ScheduledItemscount < 1">
                  <tr>
                    <td style="text-align: center;" colspan="6"> No Record Found</td>
                  </tr>
                </tbody>

              </table>
            </div>
          </div>

          <app-pagination class="1" *ngIf="ScheduledItemscount > 0"
            (pageChange)="pageChangeEvent($event)"></app-pagination>
          <app-spinner></app-spinner>
        </div>

        <div class="row" *ngIf="selectedPostTab == 2">
          <div class="col-lg-12">
            <div class="users-table table-wrapper">
              <table class="dashboard-table">
                <thead>
                  <tr class="users-table-info">

                    <th width="30%">Post</th>
                    <th width="15%" style="text-align: center;">Post Image</th>
                    <th width="15%">Scheduled Date</th>
                    <th width="15%">Created Date</th>
                    <th width="10%"> Posted Status</th>
                    <!-- <th width="35%" style="text-align: center;">Action</th> -->
                  </tr>
                </thead>




                <tbody *ngIf="postedItems != null">
                  <ng-container *ngFor="let post of postedItems | paginate: config">
                    <tr>

                      <td [innerHTML]="post.content"></td>
                      <td style="text-align: center;">
                        <div class="stat-cards-icon warning" style="margin: auto; width: 80px; height: 80px;">
                          <img *ngIf="post.file_url" [src]="backend_url+post.file_url" alt="Post Image" style="width: 100%; height: 100%; object-fit: cover;overflow: hidden;">
                          <i *ngIf="!post.file_url" class="fas fa fa-image"></i>
                      </div>
                      
                      </td>
                      <td>{{ post.schedule_to | date:'yyyy-MM-dd hh:mm a':'UTC' }}</td>
                      <td>{{ post.created_at | date:'yyyy-MM-dd' }} {{ post.created_at | date:'hh:mm a' }}</td>
                      <td>
                        <!-- <p style="font-size: 15px; color: #136416;">{{post.posted_log_message}}</p> -->
                        <ul class="social-icons mb0 pd0">
                          <li *ngFor="let network of networks; let i = index">

                            <!-- <a class="gmb not-connected" href="javascript:void(0);" *ngIf="network.id == 1"><i class="fab fa-google"></i></a>
                            <a class="fb not-connected" href="javascript:void(0);" *ngIf="network.id == 2"><i class="fab fa-facebook"></i></a>
                            <a class="twitter not-connected" href="javascript:void(0);" *ngIf="network.id == 4"><i class="fab fa-twitter"></i></a> -->

                            <a class="icon" href="javascript:void(0);"
                              *ngIf="network.id == 1 && post.posted_on_gbm == 1" title="{{post.gmb_log_message}}">
                              <i class="fa fa-check" style="font-size:16px;color:green"></i></a>
                            <a class="icon" href="javascript:void(0);"
                              *ngIf="network.id == 1 && post.posted_on_gbm == 0" title="{{post.gmb_log_message}}"><i
                                class="fa fa-exclamation-triangle" style="font-size:16px;color:red"></i></a>
                            <a class="gmb" href="javascript:void(0);" *ngIf="network.id == 1"><i
                                class="fab fa-google"></i></a>

                            <a class="icon" href="javascript:void(0);" *ngIf="network.id == 2 && post.posted_on_fb == 1"
                              title="{{post.fb_log_message}}"><i class="fa fa-check"
                                style="font-size:16px;color:green"></i></a>
                            <a class="icon" href="javascript:void(0);" *ngIf="network.id == 2 && post.posted_on_fb == 0"
                              title="{{post.fb_log_message}}"><i class="fa fa-exclamation-triangle"
                                style="font-size:16px;color:red"></i></a>
                            <a class="fb" href="javascript:void(0);" *ngIf="network.id == 2"><i
                                class="fab fa-facebook"></i></a>

                            <a class="icon" href="javascript:void(0);"
                              *ngIf="network.id == 4 && post.posted_on_twitter == 1"
                              title="{{post.twitter_log_message}}"><i class="fa fa-check"
                                style="font-size:16px;color:green"></i></a>
                            <a class="icon" href="javascript:void(0);"
                              *ngIf="network.id == 4 && post.posted_on_twitter == 0"
                              title="{{post.twitter_log_message}}"><i class="fa fa-exclamation-triangle"
                                style="font-size:16px;color:red"></i></a>
                            <a class="twitter" href="javascript:void(0);" *ngIf="network.id == 4"><i
                                class="fab fa-twitter"></i></a>
                          </li>
                          <!-- <li><a class="blog not-connected" href="javascript:void(0);" ><i class='fas fa-blog'></i></a></li> -->
                        </ul>

                      </td>
                    </tr>
                  </ng-container>
                </tbody>

                <tbody *ngIf="postedItemscount < 1">
                  <tr>
                    <td style="text-align: center;" colspan="6"> No Record Found</td>
                  </tr>
                </tbody>

              </table>
            </div>
          </div>

          <app-pagination class="2" *ngIf="postedItemscount > 0"
            (pageChange)="pageChangeEvent($event)"></app-pagination>

          <app-spinner></app-spinner>
        </div>



        <div class="row" *ngIf="selectedPostTab == 3">
          <div class="col-lg-12">
            <div class="users-table table-wrapper">
              <table class="dashboard-table">
                <thead>
                  <tr class="users-table-info">

                    <th width="30%">Post</th>
                    <th width="15%" style="text-align: center;">Post Image</th>
                    <th width="15%">Scheduled Date</th>
                    <th width="15%">Created Date</th>
                    <th width="10%">Rejection Reason</th>
                    <!-- <th width="35%" style="text-align: center;">Action</th> -->
                  </tr>
                </thead>

                <tbody *ngIf="rejectedItems != null">
                  <ng-container *ngFor="let post of rejectedItems | paginate: config">
                    <tr>

                      <td [innerHTML]="post.content"></td>
                      <td style="text-align: center;">
                        <div class="stat-cards-icon warning" style="margin: auto; width: 80px; height: 80px; ">
                          <img *ngIf="post.file_url" [src]="backend_url+post.file_url" alt="Post Image" style="width: 100%; height: 100%; object-fit: cover;overflow: hidden;">
                          <i *ngIf="!post.file_url" class="fas fa fa-image" ></i>
                      </div>
                      
                      </td>
                      <td>{{ post.schedule_to | date:'yyyy-MM-dd hh:mm a':'UTC' }}</td>
                      <td>{{post.schedule_to}}</td>
                      <td>
                        <p style="font-size: 15px; color: #831025;">{{post.posted_log_message}}</p>
                      </td>
                      <!--<td>
                            <a class="btn btn-primary" routerLink="/app/post/scheduled-posts/{{post.id}}">Reschedule</a>
                            <a class="btn btn-primary ml10" routerLink="/app/post/scheduled-posts/{{post.id}}">View Post</a>
                            <a class="btn btn-primary ml10" routerLink="/app/post/scheduled-posts/{{post.id}}">Edit Post</a>
                            <a class="btn btn-primary ml10" routerLink="/app/post/scheduled-posts/{{post.id}}">Delete Post</a>
                            </td>-->
                    </tr>
                  </ng-container>
                </tbody>

                <tbody *ngIf="rejectedItemscount < 1">
                  <tr>
                    <td style="text-align: center;" colspan="6"> No Record Found</td>
                  </tr>
                </tbody>

              </table>
            </div>
          </div>

          <app-pagination class="3" *ngIf="rejectedItemscount > 0"
            (pageChange)="pageChangeEvent($event)"></app-pagination>

          <app-spinner></app-spinner>
        </div>

      </div>
    </div>
  </main>
</div>